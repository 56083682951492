import React from 'react';
import { useFormikContext } from 'formik';
import { deepGet } from 'helpers/deepObjUtils';
import { ErrorM } from './ErrorM';

export type SelectOption = { label: string; key: string | number };

interface FormDropdownFieldProps<T> {
    field: string;
    label?: string;
    options: SelectOption[];
    selectMultiple?: boolean;
    required: boolean;
    hidePlaceholderText?: boolean;
    onChange?: (value: React.Key) => void;
}

const FormDropdownField = function <T>({
    field,
    label,
    options,
    selectMultiple,
    required,
    hidePlaceholderText,
    onChange,
}: FormDropdownFieldProps<T>) {
    const formikContext = useFormikContext<T>();
    const orDefault = (val: any, def: any) => (val == null ? def : val);
    return (
        <>
            <div className={`FormBox ${hidePlaceholderText ? '' : 'FormSideBySide'}`}>
                {label && (
                    <div className="FormLabel">
                        <label htmlFor={field}>
                            {label} {!required && <span className="Optional">- Optional</span>}
                        </label>
                    </div>
                )}
                <div className={'FormField'}>
                    <select
                        name={field}
                        className={`DropDown ${
                            deepGet(formikContext.touched, field) &&
                            deepGet(formikContext.errors, field)
                                ? 'ErrorInput'
                                : ''
                        }`}
                        value={
                            selectMultiple
                                ? [...deepGet(formikContext.values, field)]
                                : deepGet(formikContext.values, field)
                        }
                        onChange={(e) => {
                            const fieldValue = parseInt(e.currentTarget.value, 10)
                                ? parseInt(e.currentTarget.value, 10)
                                : orDefault(e.currentTarget.value, null);
                            formikContext.setFieldValue(field as any, fieldValue);
                            onChange && onChange(fieldValue);
                        }}
                        //onChange={(evt) => formikContext.setFieldValue((field as any), `${[deepGet(formikContext.values, field)].find(obj => obj.key === evt.currentTarget.value) ? [deepGet(formikContext.values, field)].filter(obj => obj.value === evt.currentTarget.value) : [evt.currentTarget.value, deepGet(formikContext.values, field)]}`)}
                        multiple={selectMultiple ? true : false}
                    >
                        {!hidePlaceholderText && (
                            <option className="DropDownOption" value={''}>
                                {required ? 'Please select an option' : 'None'}
                            </option>
                        )}
                        {options.map((opt) => (
                            <option className="DropDownOption" key={opt.key} value={opt.key}>
                                {opt.label}
                            </option>
                        ))}
                    </select>
                    <ErrorM name={field} />
                </div>
            </div>
        </>
    );
};

export { FormDropdownField };
