import { isProduction } from 'endpoints.config';

export enum AppPath {
    FORGOTTEN_PASSWORD = '/forgotten-password',
    SIGN_IN = '/sign-in',
    PERSONAL = '/personal',
    BUSINESS = '/business',
    REGISTER = '/register',
    WAITLIST_BYPASSED_REGISTRATION = '/x3w75689qf27',
    RESET_PASSWORD = '/reset-password',
    INFO = '/info',
    CHECKING_DETAILS = '/checking-details',
    AUTO_LOGIN = '/autologin',
    DIRECT_LOGIN = '/access',
    REVIEW_ORDER = '/review-order',
    SIGNIFICANT_PARTY_JUMIO = '/idverification',
    MANAGEES_JUMIO = '/manageesidverification',
}

// All pages that include the navigation menu should exist under the Dashboard
// path. This way we avoid unncessarily rerendering the navigation components when
// changing between these pages.
export enum DashboardPath {
    HOME = '/home',
    ACCOUNTS = '/accounts',
    INFORMATION_REQUEST = '/informationRequest',
    CRYPTOACCOUNTS = '/cryptoaccounts',
    FUNDINGACCOUNTS = '/funding',
    PAYMENTS = '/payments',
    TRANSFERS = '/transfers',
    EXCHANGE = '/exchange',
    CARDS = '/cards',
    INFO = '/info',
    CRYPTO = '/crypto',
    PROFILE = '/view-profile',
    STABLECOIN = '/stablecoin',
    MANAGE = '/manage',
    VA_CUSTOMERS = '/vacustomers',
    CARDMANAGEMENT = '/managees-cards',
    CURRENCYEXCHANGE = '/foreignexchange',
    APPROVAL_HISTORY = '/approvalhistory',
    APPROVALS = '/approvals',
    APPROVAL_TYPES = '/approvaltypes',
    BULK_TRANSFER = '/bulk-transfer',
}

export enum ProfilePath {
    PROFILE = '/view-profile',
    SERVICES = '/services',
    AFFLIATE = '/affliate',
    MESSAGES = '/messages',
    NOTFICATIONS = '/notfications',
    SECURITY = '/security',
}

// Contains keys passed to the languageresources endpoint as query params to get
// page translations.
export enum PageResourceKey {
    SIGN_IN = 'sign_in',
}

export enum ComponentResourceKey {
    FOOTER_LINKS = 'footer_links',
    SIDE_MENU_LINKS = 'side_menu_links',
    CUSTOMER_MENU_LINKS = 'customer_menu_links',
    LANGUAGE_LIST = 'language_list',
    USER_INFORMATION = 'user_information',
}

export const MAINTENANCE_WARNING = {
    ACTIVE: false || !isProduction,
    HEADER_MESSAGE: `We're currently undergoing maintenance.`,
    SUBTEXT_MESSAGE: `Some features may be tempoarily unavailable.`,
};

export const DEFAULT_COLOR = '#1943dc';
