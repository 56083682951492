import { BaseApi } from 'api';
import { endpoints } from 'endpoints.config';
import {
    NotificationsStackResponse,
    NotificationsUserPreferences,
    NotificationUserPreference,
} from './models';

export class NotificationsApi extends BaseApi {
    public static getNotificationsConfig() {
        return this.makeRequest<NotificationsUserPreferences>({
            url: endpoints.notificationsmodule.getUserPreferences,
        });
    }

    public static updateSingleConfig(preference: NotificationUserPreference) {
        return this.makeRequest({
            url: endpoints.notificationsmodule.updateSinglePreference,
            method: 'POST',
            data: preference,
        });
    }

    public static getNotificationsStack() {
        const IANATimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        return this.makeRequest<NotificationsStackResponse>({
            url: endpoints.notificationsmodule.userStack,
            method: 'GET',
            params: { timeZone: IANATimezone },
        });
    }

    public static acknowledgeNotification(id: number) {
        return this.makeRequest<NotificationsStackResponse>({
            url: endpoints.notificationsmodule.acknowledgeSingle,
            method: 'POST',
            data: { notificationsId: id },
        });
    }
}
