// @ts-nocheck
import { useEffect, useRef, useState } from 'react';
import * as pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { Spinner } from 'components/circleSpinner/Spinner';
pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
const PDFViewer = ({
    fileUrl,
    setIsScrolledToEnd,
}: {
    fileUrl: string;
    setIsScrolledToEnd?: (isScrolledToEnd: boolean) => void;
}) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const pdfRef = useRef(null);
    const [zoomLevel, setZoomLevel] = useState<number>(100);
    const canvasRefs = useRef<HTMLCanvasElement[]>([]);
    const [loading, setLoading] = useState<boolean>(false); 
    useEffect(() => {
        const loadPDF = async () => {
            setLoading(true);
            pdfRef.current = await pdfjsLib.getDocument(fileUrl).promise;
            const numPages = pdfRef.current.numPages;
            for (let pageNum = 1; pageNum <= numPages; pageNum++) {
                const page = await pdfRef.current.getPage(pageNum);
                const viewport = page.getViewport({ scale: 1.5 });
                const canvas = document.createElement('canvas');
                canvas.width = viewport.width;
                canvas.height = viewport.height;
                canvas.style.margin = '0';
                canvas.style.padding = '0';
                canvas.style.display = 'block';
                const context = canvas.getContext('2d');
                const renderContext = {
                    canvasContext: context,
                    viewport,
                };
                await page.render(renderContext).promise;
                canvasRefs.current.push(canvas);
                containerRef.current?.appendChild(canvas);
            }
            setLoading(false);
        };
        loadPDF();
    }, [fileUrl]);

    const handleScroll = (event: { target: any }) => {
        if (setIsScrolledToEnd) {
            const target = event.target;
            const scrollY = target.scrollTop;
            const innerHeight = target.clientHeight;
            const scrollHeight = target.scrollHeight;
            if (scrollY + innerHeight + 10 >= scrollHeight) {
                setIsScrolledToEnd(true);
            }
        }
    };
    return (
        <div style={{ position: 'relative' }}>
              {loading && (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        zIndex: 10,
                        backgroundColor: 'rgba(255, 255, 255, 0.8)',
                        padding: '10px',
                        borderRadius: '5px',
                    }}
                >
                    <Spinner />
                </div>
            )}
            <div
                style={{
                    height: '350px',
                    overflowY: 'auto',
                    border: '1px solid #cccccc',
                    borderRadius: '10px',
                    padding: '0px',
                    fontSize: '0.9rem',
                    marginBottom: '20px',
                }}
                onScroll={handleScroll}
            >
                <div
                    ref={containerRef}
                    id="pdf-container"
                    style={{
                        width: `${zoomLevel}%`,
                        overflow: 'auto',
                        padding: '0',
                        margin: '0',
                        boxSizing: 'border-box',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                ></div>
            </div>
            <div
                style={{
                    position: 'absolute',
                    bottom: '20px',
                    right: '20px',
                    zIndex: 10,
                    padding: '5px',
                    borderRadius: '5px',
                    backgroundColor: 'rgba(255, 255, 255, 0.8)',
                    display: 'flex',
                    flexDirection: 'row',
                    gap: '5px',
                    pointerEvents: 'none',
                }}
            >
                <button
                    onClick={() => setZoomLevel((prev) => Math.min(prev + 10, 300))}
                    style={{
                        backgroundColor: 'rgba(0, 0, 255, 0.5)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '2px 10px',
                        cursor: 'pointer',
                        fontSize: '20px',
                        pointerEvents: 'auto',
                    }}
                >
                    +
                </button>
                <button
                    onClick={() => setZoomLevel((prev) => Math.max(prev - 10, 10))}
                    style={{
                        backgroundColor: 'rgba(0, 0, 255, 0.5)',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        padding: '2px 10px',
                        cursor: 'pointer',
                        fontSize: '20px',
                        pointerEvents: 'auto',
                    }}
                >
                    -
                </button>
            </div>
        </div>
    );
};
export default PDFViewer;
