import { TableInfo, useTable } from 'api';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import Button from 'components/button/Button';
import { FlexTable } from 'components/flexTable';
import Pagination from 'components/pagination/Pagination';
import { SubPageLoader } from 'components/subPageLoader/SubPageLoader';
import { endpoints } from 'endpoints.config';
import { SearchBox } from 'helpers/searchBox/SearchBox';
import { plaintext } from 'plaintext.config';
import React, { useEffect, useState } from 'react';
import styles from '../PayeePanel/PayeePanel.module.scss';
import {
    NotificationIdentifier,
    completeUIUpdate,
    selectUIUpdate,
} from 'components/notifications/notificationUIUpdateReducer';
import { useGetFiatAccountDetails } from 'helpers/useGetFiatAccountDetails';
import { useDispatch, useSelector } from 'react-redux';
import { selectComponentResources } from 'reducers/componentResources';
import { ModalTypes, openModal } from 'reducers/modal';
import { selectPayeeSubpage, updatePayeeSubpage } from '../../../reducers/payee';
import { MoveMoneySubPages, SavedPayee, payeeRowFormatter } from '../MoveMoney';
import { LaunchPlaid } from './LaunchPlaid';
import { PayeePanel } from '../PayeePanel/PayeePanel';
import { Recipient } from './MakePayment/Recipient';
import { MoveMoneySubPage } from '../MoveMoneySubPage';
import { EditPayee } from './EditPayee';
import { useAfterMountEffect } from '../../../helpers/useAfterMountEffect';
import { ProductType } from 'components/sideMenu/SideMenu';
import { useFetchPayeeCountries } from './MakePayment/MakePayment';
import { useHandleSidePanelInModal } from '../PayeePanel/helpers';

export type Props = {
    accountNumber: string;
};
export enum PayeesSubPages {
    PAYEES_TABLE,
    NEW_PAYEE,
}

const idColumn: keyof SavedPayee = 'payees__Id';

export const Payees: React.FC<Props> = ({ accountNumber }) => {
    const accountDetails = useGetFiatAccountDetails();

    const { payeeSubPage: currentPage, refreshTable } = useSelector(selectPayeeSubpage);

    const { availableCountries, errorCountries } = useFetchPayeeCountries();

    const payeeTable = useTable<SavedPayee, any>({
        url: endpoints.accounts.getPayeeList,
        filters: accountDetails?.currencyCode
            ? `assets__Code EQ (${accountDetails?.currencyCode})`
            : '',
        params: { customerAssetAccountsId: accountDetails?.id },
    });
    const { loading, data, reload } = payeeTable;
    const dispatch = useDispatch();

    const [selectedRow, setSelectedRow] = useState<number>();

    const { userInfo } = useSelector(selectComponentResources);
    const isPersonalAccount = userInfo?.accountType === 'Personal';

    const update = useSelector(selectUIUpdate);

    useAfterMountEffect(() => {
        reload();
    }, [refreshTable]);

    useEffect(() => {
        if (update?.pushType === NotificationIdentifier.RELOAD_PAYEES_TABLE) {
            payeeTable.reload();
            dispatch(completeUIUpdate());
        }
    }, [update, payeeTable, dispatch]);

    const setCurrentPage = (page: MoveMoneySubPages, payee?: SavedPayee) => {
        dispatch(
            updatePayeeSubpage({
                page,
                payee: payee ?? undefined,
            })
        );
    };

    const resetState = () => {
        setCurrentPage(MoveMoneySubPages.PAYEES_TABLE);
    };
    const onRowClick = (id: number) => {
        if (selectedRow === id) setSelectedRow(undefined);
        else setSelectedRow(id);
    };

    const selectedData = data?.details.listData.find((entry) => entry[idColumn] === selectedRow);
    useHandleSidePanelInModal(selectedRow, selectedData, setSelectedRow, true);

    const handleOpenPullFundsModal = (payeeData: SavedPayee) => {
        dispatch(
            openModal({
                modalType: ModalTypes.PULL_FUNDS,
                data: { payeeData, reloadTable: payeeTable.reload },
            })
        );
    };

    const handlePayeeChange = () => {
        setCurrentPage(MoveMoneySubPages.PAYEES_TABLE);
        reload();
    };

    if (loading) {
        return <SubPageLoader message={plaintext.accounts.loading} />;
    }

    const emptyTable = data?.details.listData.length === 0;

    if (currentPage === MoveMoneySubPages.PAYEES_TABLE)
        return (
            <div className="PayeesTable">
                <div className="TableHeader">
                    <div className="TransferButtons">
                        <Button
                            priority="primary"
                            onClick={() => setCurrentPage(MoveMoneySubPages.NEW_PAYEE)}
                        >
                            Add Payee
                        </Button>

                        {accountDetails?.productDisplayName === ProductType.CRB && (
                            <LaunchPlaid
                                onCompletion={payeeTable.reload}
                                isPersonalAccount={isPersonalAccount}
                            />
                        )}
                    </div>
                    <SearchBox
                        key={'searchBox'}
                        placeholder={plaintext.accounts.searchPayees}
                        initialSearchString={data?.details.searchString ?? ''}
                        changeSearch={data?.actions.changeSearch}
                        goToPage={data?.actions.goToPage}
                    />
                </div>
                <div className="TableWrapper">
                    <FlexTable
                        idColumn={idColumn}
                        table={payeeTable as TableInfo}
                        rowFormatter={payeeRowFormatter}
                        onRowClick={onRowClick}
                        rowCellOptions={{
                            _button: {
                                style: {
                                    minWidth: '150px',
                                    width: '150px',
                                    flex: '0',
                                    padding: '0 10 0 0',
                                },
                            },
                        }}
                        rowButton={(row: SavedPayee) => (
                            <div className="MoveMoneyButtons">
                                {row.payees__bPullAvailable && !row.payees__bSuppressed && (
                                    <Button
                                        priority="secondary"
                                        onClick={(e) => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            handleOpenPullFundsModal(row);
                                        }}
                                    >
                                        Pull Funds
                                    </Button>
                                )}
                            </div>
                        )}
                    />
                    {!emptyTable && (
                        <div className={styles.TableEntryInfoPanelWrapper}>
                            <PayeePanel
                                disablePayment
                                selectedRowData={selectedData}
                                closePanel={() => setSelectedRow(undefined)}
                            />
                        </div>
                    )}
                </div>
                {data?.details.listData.length === 0 && !loading && (
                    <div className="EmptyTable">
                        <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                        <h3 className="Message">{plaintext.tables.emptyPayeesTable}</h3>
                    </div>
                )}
                <Pagination table={payeeTable} />
            </div>
        );
    if (currentPage === MoveMoneySubPages.NEW_PAYEE)
        return (
            <MoveMoneySubPage back={resetState}>
                <Recipient
                    availableCountries={availableCountries}
                    errorCountries={errorCountries}
                    initialValues={{}}
                    hideSaveOption
                    nextStep={handlePayeeChange}
                    btntext="Save"
                />
            </MoveMoneySubPage>
        );
    if (currentPage === MoveMoneySubPages.EDIT_PAYEE)
        return (
            <MoveMoneySubPage back={resetState}>
                <EditPayee onBack={handlePayeeChange} />
            </MoveMoneySubPage>
        );

    return null;
};
