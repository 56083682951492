import React, { lazy } from 'react';
import { RouteComponentProps } from '@reach/router';
import { endpoints } from '../../endpoints.config';
import Category from '../../components/Category';
import { CrudItems } from 'components/Category/CrudMenu';
import { localizedFormatDate } from '../../helpers/categoryHelpers/userLocale';
import { useDefaultRoute } from 'helpers/useDefaultRoute';
import { AppPath, DashboardPath } from 'appConstants';
import { selectCultureCode } from 'reducers/language';
import { useSelector } from 'react-redux';

// Icons
import View from '../../assets/Cat-Entity/Icon_Action_01.png';
import Button from '../../components/button/Button';

const VACustomer = lazy(() => import('./ManageVACustomer'));

// Define which column on the table represents each row's ID.
const idColumn: keyof VACustomerData = 'customers__Id';

//Define the data structure for table
export type VACustomerData = {
    customers__Id: number;
    customerUsers__Id: number;
    customersAvailableAccounts__bNewAccountAvailable: boolean;
    customers__AddDate: string;
    customers__BusinessVerificationDate: null;
    customers__BusinessVerificationStatus: null;
    customers__CompanyEmailAddress: string;
    customers__CompanyName: string;
    customers__CustomersCode: string;
    customers__bAllDocumentsSubmitted: boolean;
    customers__bBusinessDocumentsVerified: boolean;
    customers__bFinancialInstitution: boolean;
    customers__bSubmittedForm: boolean;
    licenseesBrands__StatementDescriptor: string;
};

const crudItems: CrudItems<VACustomerData> = [
    {
        icon: View,
        title: 'Add Personal User',
        imgAltText: 'Create',
        menuCode: 'CREATE_PERSONAL',
        showInMultiSelect: false,
        permissionsKey: 'create',
        content: (onDone, selectedData, menu) => (
            <VACustomer
                selectedData={selectedData}
                onDone={onDone}
                idFromRowOption={menu.selectedId}
                actionType="create-personal"
                submitButtonText="Add"
            />
        ),
        inRightPanel: true,
        isDisabled: false,
        notInMenus: true,
    },
    {
        icon: View,
        title: 'Add Business User',
        imgAltText: 'Create',
        menuCode: 'CREATE_BUSINESS',
        showInMultiSelect: false,
        permissionsKey: 'create',
        content: (onDone, selectedData, menu) => (
            <VACustomer
                selectedData={selectedData}
                onDone={onDone}
                idFromRowOption={menu.selectedId}
                actionType="create-business"
                submitButtonText="Add"
            />
        ),
        inRightPanel: true,
        isDisabled: false,
        notInMenus: true,
    },
    {
        icon: View,
        title: 'View Customer',
        imgAltText: 'View',
        // permissionsKey: 'view',
        menuCode: 'VIEW',
        showInMultiSelect: false,
        content: (onDone, selectedData, menu) => (
            <VACustomer
                onDone={onDone}
                selectedData={selectedData}
                idFromRowOption={menu.selectedId}
                actionType="view"
            />
        ),
        inRightPanel: true,
        isDisabled: false,
    },
    {
        icon: View,
        title: 'Customer transactions',
        imgAltText: 'View',
        // permissionsKey: 'transactions',
        menuCode: 'TRANSACTIONS',
        showInMultiSelect: false,
        content: (onDone, selectedData, menu) => (
            <VACustomer
                onDone={onDone}
                selectedData={selectedData}
                idFromRowOption={menu.selectedId}
                actionType="transactions"
            />
        ),
        inRightPanel: true,
        isDisabled: false,
    },
];

// Optional - function for formatting a row by column name
const rowFormatter = (row: VACustomerData): { [K in keyof typeof row]: React.ReactNode } => {
    let formattedRow: any = { ...row };
    if (formattedRow.customers__AddDate) {
        formattedRow = {
            ...formattedRow,
            customers__AddDate: localizedFormatDate(new Date(row.customers__AddDate)),
        };
    }
    return formattedRow;
};

type Props = { subPage?: string } & RouteComponentProps;

export const ManageVACustomers = (props: Props) => {
    const cultureCode = useSelector(selectCultureCode);
    useDefaultRoute(`/${cultureCode}${AppPath.BUSINESS}${DashboardPath.VA_CUSTOMERS}`);
    return (
        <Category<VACustomerData>
            location={props.location}
            contentTitle="Virtual Customers"
            noun="VA Customer"
            idColumn={idColumn}
            listEndpointUrl={endpoints.vaCustomers.list}
            rowFormatter={rowFormatter}
            crudComponents={crudItems}
            hiddenColumns={[idColumn]}
            noAddBtn={false}
            customAddButtons={(menuActions) => (
                <div className="CustomAddButtons">
                    <Button onClick={() => menuActions.openMenu('CREATE_PERSONAL', null)}>
                        Add personal customer
                    </Button>
                    <Button onClick={() => menuActions.openMenu('CREATE_BUSINESS', null)}>
                        Create business customer
                    </Button>
                </div>
            )}
            classname="listsCatTable ManageVACustomers"
            emptyTableLabel="No customers found"
        />
    );
};
