import { useLocation, useNavigate } from '@reach/router';
import { useEffect, useMemo, useState } from 'react';
import { VA_ACCOUNT_PARAM } from './VACustomers';
import { useQueryFetch } from '../../helpers/useQueryFetch';
import { endpoints } from '../../endpoints.config';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';
import Select, { ValueType } from 'react-select';
import { ApprovalStatus } from '../../components/transactionsTable/Transactions';
import { TableInfo, useTable } from '../../api';
import { FlexTable } from '../../components/flexTable';
import { selectCultureCode } from '../../reducers/language';
import { useSelector } from 'react-redux';
import { useTheme } from '@emotion/react';
import { checkIsPending, rowFormatter, useHandleSidePanelInModal } from './helpers';
import { useAfterMountEffect } from '../../helpers/useAfterMountEffect';
import { AppPath, DashboardPath } from '../../appConstants';
import { DateRangePicker } from '../../components/dateRangePicker/DateRangePicker';
import { SearchBox } from '../../helpers/searchBox/SearchBox';
import { TableEntryInfoPanel } from '../../components/TableEntryInfoPanel/TableEntryInfoPanel';
import styles from '../../components/TableEntryInfoPanel/TableEntryInfoPanel.module.scss';
import { FoldingCube } from 'better-react-spinkit';

import ArrowIcon from 'assets/Icon_Form_Arrow_02.png';
import EmptyList from 'assets/ibanera/Img_List_Empty.png';
import Pagination from '../../components/pagination/Pagination';
import { VACustomerViewResponse } from './models';

type Props = {
    id: number | string;
};
type Response = {
    listVACustomerAccounts: SelectOption[];
};
export type Data = {
    customerAssetAccountsTransactions__Id: number;
    customerAssetAccountsTransactions__Date: string;
    customerAssetAccountsExternalTransfers__Name: string;
    customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode: string;
    customerAssetAccountsTransactions__Amount: number;
    customerAssetAccountsTransactions__Description: string;
    transactionAmounts__Balance: number;
    customerAssetAccountsTransactions__bPending: boolean;
    customerAssetAccountsTransactions__bReserved: boolean;
    customerAssetAccountsTransactions__bFrozen: boolean;
    transactionAmounts__ApprovalStatus: ApprovalStatus | null;
    transactionAmounts__bInTransaction: boolean;
    assets__Code: string;
    assets__Symbol: string;
};

const idColumn = 'customerAssetAccountsTransactions__Id' as keyof Data;

export const VACustomerTransactions: React.FC<Props> = ({ id: customersId }) => {
    const { search } = useLocation();
    const navigate = useNavigate();
    const cultureCode = useSelector(selectCultureCode);
    const { colors } = useTheme();

    const { data: customerData } = useQueryFetch<VACustomerViewResponse>(
        endpoints.vaCustomers.view,
        ['va-customer-view', customersId],
        { customersId }
    );

    const account = useMemo(() => new URLSearchParams(search).get(VA_ACCOUNT_PARAM), [search]);

    const [currentDates, setCurrentDates] = useState({ fromDate: '', toDate: '' });

    const { data } = useQueryFetch<Response>(
        endpoints.vaCustomers.accounts,
        [endpoints.vaCustomers.accounts],
        {
            customersId,
        },
        undefined,
        { withCredentials: false }
    );
    const options = data ? data.details.listVACustomerAccounts ?? [] : [];
    const selectedAccount = options.find((acc) => acc.value.toString() === account?.toString());
    useEffect(() => {
        if (!!selectedAccount || !options || options.length === 0) return;
        handleAccountChange(options[0]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [account, options]);

    const transactionsTable = useTable<Data, any>({
        url: endpoints.vaCustomers.transactions,
        bClearFilters: true,
        params: { vaCustomerId: customersId, customerAssetAccountsId: account },
        withCredentials: false,
    });
    useAfterMountEffect(() => {
        transactionsTable.reload();
    }, [account]);

    const [selectedRow, setSelectedRow] = useState<number>();
    const selectedData = transactionsTable.data?.details.listData.find(
        (entry) => entry[idColumn] === selectedRow
    );

    useHandleSidePanelInModal(selectedRow, selectedData, setSelectedRow);

    const handleAccountChange = (val: ValueType<SelectOption, false>) => {
        if (!val) return;
        navigate(`${window.location.pathname}?${VA_ACCOUNT_PARAM}=${val?.value}`, {
            replace: true,
        });
    };
    const onRowClick = (id: number) => {
        if (selectedRow === id) setSelectedRow(undefined);
        else setSelectedRow(id);
    };
    const handleGoBack = () => {
        navigate(`/${cultureCode}${AppPath.BUSINESS}${DashboardPath.VA_CUSTOMERS}`);
    };
    const setDateFilter = (startDate: Date, endDate: Date) => {
        setCurrentDates({ fromDate: startDate.toISOString(), toDate: endDate.toISOString() });
        transactionsTable.data?.actions.changeFilter('customerAssetAccountsTransactions__Date', [
            {
                columnKey: 'customerAssetAccountsTransactions__Date' as keyof Data,
                operator: 'BETWEEN',
                value: `${startDate.toISOString()}TO${endDate.toISOString()}`,
            },
        ]);
    };
    const emptyTable = transactionsTable?.data?.details.listData.length === 0;
    const hasName = customerData?.details?.firstName || customerData?.details?.lastName;
    return (
        <div className="VaCustomerTransactions">
            <div className="TableHeader VATransactionsHeader">
                <div className="Title">
                    <button onClick={handleGoBack} type="button" className="BackButton">
                        <img alt="Back" src={ArrowIcon} />
                    </button>
                    <h1>Transactions</h1>
                </div>
                {hasName && (
                    <h2>
                        Customer: {customerData?.details.firstName} {customerData?.details.lastName}
                    </h2>
                )}
                <div className="Controls">
                    <DateRangePicker
                        currentDates={currentDates}
                        setFilter={setDateFilter}
                        cultureCode={cultureCode}
                    />
                    <SearchBox
                        key={'searchBox'}
                        placeholder="Search all transactions"
                        initialSearchString={transactionsTable.data?.details.searchString ?? ''}
                        changeSearch={transactionsTable.data?.actions.changeSearch}
                        goToPage={transactionsTable.data?.actions.goToPage}
                    />
                    <label className="AccountLabel" htmlFor="Account" title="Account">
                        <Select
                            id="Account"
                            name="Account"
                            inputId={customersId?.toString()}
                            className="CustomSelect RoundedRect AccountSelect"
                            classNamePrefix="CustomSelect"
                            options={options}
                            value={options.find(
                                (opt) => opt.value.toString() === account?.toString()
                            )}
                            onChange={handleAccountChange}
                            isDisabled={options.length === 0 && !!account}
                            menuPlacement="auto"
                        />
                    </label>
                </div>
            </div>
            <div className="VATransactionsTable">
                <FlexTable
                    idColumn={idColumn}
                    selectedIds={selectedRow ? [selectedRow] : []}
                    table={transactionsTable as TableInfo}
                    rowFormatter={(row) => rowFormatter(row, colors, cultureCode)}
                    onRowClick={onRowClick}
                    rowCellOptions={{
                        transactionTypes__Type: {
                            style: {
                                minWidth: '120px',
                                width: '120px',
                                flex: '0',
                            },
                        },
                        _button: {
                            style: {
                                minWidth: '250px',
                                width: '250px',
                                flex: '0',
                                padding: '0 10 0 0',
                            },
                        },
                    }}
                    buttonInMiddleTable
                />

                {!emptyTable && (
                    <div className={styles.TableEntryInfoPanelWrapper}>
                        <TableEntryInfoPanel
                            disableEdit
                            selectedRowData={
                                selectedData
                                    ? {
                                          id: selectedData.customerAssetAccountsTransactions__Id,
                                          amount: selectedData.customerAssetAccountsTransactions__Amount,
                                          moneyIn: selectedData.transactionAmounts__bInTransaction,
                                          reference:
                                              selectedData.customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode,
                                          isPending: checkIsPending(selectedData),
                                          approvalStatus:
                                              selectedData.transactionAmounts__ApprovalStatus,
                                          bCancellable: false,
                                          bEligibleForReverse: false,
                                          currencyCode: selectedData.assets__Code,
                                      }
                                    : undefined
                            }
                            closePanel={() => setSelectedRow(undefined)}
                            currencySymbol={selectedData?.assets__Symbol}
                            endpoint={endpoints.vaCustomers.transactionDetails}
                        />
                    </div>
                )}
                {transactionsTable.loading && (
                    <div className="Loading">
                        <FoldingCube color={colors.first} size={80} />
                    </div>
                )}
            </div>

            {emptyTable && !transactionsTable?.loading && (
                <div className="EmptyTable">
                    <img className="EmptyTableImage" src={EmptyList} alt="MT" />
                    <h3 className="Message">No transactions found</h3>
                </div>
            )}
            <Pagination table={transactionsTable} />
        </div>
    );
};
