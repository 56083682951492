import { useTheme } from '@emotion/react';
import { insertSymbol } from '../transactionsTable/Transactions';
import styles from './TableEntryInfoPanel.module.scss';
import { ReactComponent as IconMoneyIn } from 'assets/ui-update/arrow-02.svg';
import Icon_Close from 'assets/Cat-Entity/Icon_Form_Search_02.png';
import EmptyPanel from 'assets/ui-update/empty-side-01.svg';
import Button from '../button/Button';
import { useMemo, useState } from 'react';
import api, { isAxiosErrorHandled, useFetch } from '../../api';
import { endpoints } from '../../endpoints.config';
import { FoldingCube } from 'better-react-spinkit';
import { Form, Formik } from 'formik';
import { FileUpload } from '../form/FileUpload/FileUpload';
import FormTextField from '../form/FormTextField';
import { ERROR_CODES, getErrorMessage } from '../../errors';
import { GeneralError } from '../GeneralError/GeneralError';
import { selectCultureCode } from '../../reducers/language';
import { useDispatch, useSelector } from 'react-redux';
import { UploadedFilesList } from '../form/FileUpload/UploadedFilesList';
import { ModalTypes, closeModal, selectModalState } from '../../reducers/modal';
import { Collapse } from '../Collapse/Collapse';
import { toCamelCase } from '../../helpers/formatFormFieldNames';
import { format, parseISO } from 'date-fns';
import { partition } from 'lodash';
import { ReverseTransferModal } from './ReverseTransferModal';
import { refreshTransactions } from '../../reducers/utility';
import { useQueryFetch } from '../../helpers/useQueryFetch';
import { CancelTransferModal } from './CancelTransferModal';
import { selectComponentResources } from '../../reducers/componentResources';

const PAYEE_LABELS = {
    fullName: 'Full Name',
    accountNumber: 'Account Number',
    routingNumber: 'Routing Number',
    swiftBic: 'SWIFT/BIC Code',
    intermediaryBic: 'Intermediary Swift',
    iban: 'IBAN',
    bankName: 'Bank Name',
    addressLine1: 'Address Line 1',
    addressLine2: 'Address Line 2',
    townCity: 'Town / City',
    state: 'State',
    postcode: 'Zip Code',
    country: 'Country',
};
type PayeeKey = keyof typeof PAYEE_LABELS;

const CRYPTO_TRANSFER_LABELS = {
    sourceWalletAddress: 'Source Wallet Address',
    destinationWalletAddress: 'Destination Wallet Address',
    transactionHash: 'Transaction Hash',
};
type CryptoKey = keyof typeof CRYPTO_TRANSFER_LABELS;

type TransactionInfoResponse = {
    notes: string | null;
    files:
        | {
              fileName: string;
              url: string;
          }[]
        | null;
    approvalStatus: string | null;
    transactionId: number;
    transactionStatus: string;
    transactionType: string;
    paymentType: string;
    assetClass: string;
    fullName: string;
    routingNumber: string;
    swiftNumber: string;
    accountNumber: string;
    imadNumber: number | null;
    paymentReference: string;
    status: string;
    approvalDetails: {
        bApproved: boolean | null;
        approvalsRemaining: number;
        processedBy?: { name: string; processedDate: string; bApproved: boolean }[];
        approversRemaining?: { name: string; email: string }[];
    };
    payeeDetails: {
        fullName: string;
        routingNumber: string;
        swiftNumber: string;
        accountNumber: string;
        imadNumber: string;
        bankName: string;
        paymentReference: string;
        addressLine1: string;
        addressLine2: string;
        townCity: string;
        state: string;
        postcode: string;
        country: string;
    } | null;
    cryptoTransferDetails: {
        sourceWalletAddress: string;
        destinationWalletAddress: string;
        transactionHash: string;
    };

    bShowSwiftNumber: boolean;
    bShowRoutingNumber: boolean;
    bShowImadNumber: boolean;
    bShowPayeeDetails: boolean;
    bShowCryptoTransferDetails: boolean;
};

type ApiResponse = {
    id: number;
    details: TransactionInfoResponse;
    status: '1' | '0';
    metadata: {
        name: string; // Pascal case of fieldname
        type: 'String' | 'Custom' | 'Unknown';
        customType: null;
        label: string;
        bRequired: false;
        bRemoteDataSource: false;
        dataSource: null;
    }[];
};

type FormState = {
    notes: string;
    uploadFiles: File[];
    files: { fileName: string; url: string; bDeleted?: boolean }[];
};

export type SidePanelData = {
    id: number;
    moneyIn: boolean;
    amount: number;
    reference: string;
    currencyCode?: string;
    isPending: boolean;
    approvalStatus: string | null;
    bEligibleForReverse: boolean;
    bCancellable: boolean;
};

type Props = {
    selectedRowData?: SidePanelData;
    currencySymbol?: string;
    closePanel: () => void;
    disableEdit?: boolean;
    endpoint?: string;
};

export const TableEntryInfoPanel: React.FC<Props> = ({
    selectedRowData: rowData,
    currencySymbol,
    closePanel,
    disableEdit = false,
    endpoint = endpoints.accounts.transactionInfo,
}) => {
    const [errorMessage, setErrorMessage] = useState<string>();
    const cultureCode = useSelector(selectCultureCode);
    const modalState = useSelector(selectModalState);
    const { userInfo } = useSelector(selectComponentResources);

    const [showReverseTransfer, setShowReverseTransfer] = useState(false);
    const [showCancelTransfer, setShowCancelTransfer] = useState(false);

    const dispatch = useDispatch();
    const { colors } = useTheme();

    const { data: cancellableResponse, removeQuery } = useQueryFetch<{ bCancellable: true }>(
        endpoints.accounts.cancelTransaction,
        [endpoints.accounts.cancelTransaction, rowData?.id],
        { customerAssetAccountsTransactionsId: rowData?.id },
        { enabled: !!rowData?.id && rowData.bCancellable }
    );
    const bCancellable = !!cancellableResponse?.details.bCancellable;

    const { data, loading, error, reload } = useFetch<ApiResponse>(
        endpoint,
        {
            params: { transactionId: rowData?.id },
        },
        undefined,
        !rowData
    );

    if (!rowData) return <EmptySelection />;

    const isMoneyIn = rowData.moneyIn;
    const amount = rowData.amount;

    const showFieldsConfig = (data?: TransactionInfoResponse) => {
        return {
            bShowSwiftNumber: data?.bShowSwiftNumber ?? false,
            bShowRoutingNumber: data?.bShowRoutingNumber ?? false,
            bShowImadNumber: data?.bShowImadNumber ?? false,
            bShowPayeeDetails: data?.bShowPayeeDetails ?? false,
            bShowCryptoTransferDetails: data?.bShowCryptoTransferDetails ?? false,
        };
    };

    const {
        bShowCryptoTransferDetails,
        bShowImadNumber,
        bShowPayeeDetails,
        bShowRoutingNumber,
        bShowSwiftNumber,
    } = showFieldsConfig(data?.details);

    const shouldShowField = (name: string) => {
        if (name.toLowerCase() === 'imadnumber') return bShowImadNumber;
        if (name.toLowerCase() === 'routingnumber') return bShowRoutingNumber;
        if (name.toLowerCase() === 'swiftbic') return bShowSwiftNumber;
        if (name.toLowerCase() === 'intermediarybic') return bShowSwiftNumber;
        if (name.toLowerCase() === 'iban') return bShowSwiftNumber;
        // Notes is displayed as an input elsewhere
        if (name === 'Notes') return false;

        return true;
    };
    const metadataMapped = (data?.metadata ?? []).map((item) => ({
        ...item,
        visible: shouldShowField(item.name),
    }));

    const handleSuccessfulUpload = () => {
        if (modalState.modalType === ModalTypes.TRANSACTION_DETAILS) {
            dispatch(closeModal());
        }
        reload();
    };

    const onSubmit = async (values: FormState) => {
        try {
            const formData = new FormData();
            formData.append('TransactionId', rowData.id + '');
            values.uploadFiles.forEach((file, i) => {
                formData.append(`Files`, file);
            });

            const deletedFiles = values.files.filter((file) => file.bDeleted);
            deletedFiles.forEach((file, i) => {
                formData.append(`FilesToDelete[${i}]`, file.fileName);
            });

            formData.append('Notes', values.notes);

            const res = await api.post<ApiResponse>(endpoints.accounts.transactionInfo, formData, {
                headers: {
                    'content-type': 'multipart/form-data',
                },
            });
            if (res.data.status === '1') {
                handleSuccessfulUpload();
            } else {
                throw new Error();
            }
        } catch (err) {
            let translatedErrorMessage = getErrorMessage('Generic');

            if (isAxiosErrorHandled(err) && err.response.data.errors) {
                err.response.data.errors.forEach((error) => {
                    if (error.messageCode in ERROR_CODES)
                        translatedErrorMessage = getErrorMessage(error.messageCode);
                });
            }
            if (isAxiosErrorHandled(err) && err.response.status === 413) {
                translatedErrorMessage = 'File too large';
            }

            setErrorMessage(translatedErrorMessage);
        }
    };

    const customRenderer = (field: string, index: number) => {
        if (!data) return null;

        switch (field) {
            case 'PayeeDetails':
                return bShowPayeeDetails && data.details.payeeDetails ? (
                    <Collapse key={index} header="Payee details">
                        {Object.entries(data.details.payeeDetails)
                            .filter(([key]) => shouldShowField(key))
                            .map(([key, value], i) => (
                                <TransactionInfoItem
                                    key={i}
                                    label={PAYEE_LABELS[key as PayeeKey] ?? ''}
                                    value={value}
                                />
                            ))}
                    </Collapse>
                ) : null;
            case 'ApprovalDetails':
                return data.details.approvalDetails ? (
                    <ApprovalDetailsSection key={index} details={data.details.approvalDetails} />
                ) : null;
            case 'CryptoTransferDetails':
                return bShowCryptoTransferDetails && data.details.cryptoTransferDetails ? (
                    <Collapse key={index} header="Crypto transfer details">
                        {Object.entries(data.details.cryptoTransferDetails).map(
                            ([key, value], i) => (
                                <TransactionInfoItem
                                    key={i}
                                    label={CRYPTO_TRANSFER_LABELS[key as CryptoKey] ?? ''}
                                    value={value}
                                />
                            )
                        )}
                    </Collapse>
                ) : null;
            case 'Files':
            case 'Notes':
                return null;
            default:
                return <p key={index}>No renderer found for {field}</p>;
        }
    };

    const closeReverseModal = () => setShowReverseTransfer(false);
    const onReverseSuccess = () => {
        closeReverseModal();
        if (modalState.modalType === ModalTypes.TRANSACTION_DETAILS) dispatch(closeModal());
        reload();
        dispatch(refreshTransactions());
    };
    const closeCancelModal = () => setShowCancelTransfer(false);
    const onCancelSuccess = () => {
        closeCancelModal();
        if (modalState.modalType === ModalTypes.TRANSACTION_DETAILS) dispatch(closeModal());
        reload();
        dispatch(refreshTransactions());
        removeQuery();
    };
    return (
        <>
            <ReverseTransferModal
                transactionId={rowData.id}
                showModal={showReverseTransfer}
                closeModal={closeReverseModal}
                onSuccess={onReverseSuccess}
            />
            <CancelTransferModal
                transactionId={rowData.id}
                showModal={showCancelTransfer}
                closeModal={closeCancelModal}
                onSuccess={onCancelSuccess}
            />
            <div className={styles.Panel}>
                <div className={`${styles.Header} ${isMoneyIn ? styles.MoneyIn : ''}`}>
                    <IconMoneyIn
                        className={styles.ArrowIcon}
                        fill={isMoneyIn ? colors.second : colors.third}
                    />
                    <h2 className={styles.Title}>
                        Detail of your {isMoneyIn ? 'deposit' : 'withdrawal'}
                    </h2>
                    <img
                        onClick={closePanel}
                        className={styles.CloseIcon}
                        src={Icon_Close}
                        alt="Close modal"
                    />
                </div>

                <div className={styles.Summary}>
                    <div className={styles.Amount}>
                        {insertSymbol(amount, currencySymbol, cultureCode ?? 'en-GB', 2, 12)}
                        {!currencySymbol ? <span> {rowData.currencyCode}</span> : null}
                    </div>
                    <div>
                        {data?.details.transactionStatus && (
                            <div
                                className={`${styles.Pending} ${
                                    rowData.moneyIn ? styles.PendingIn : styles.PendingOut
                                } `}
                            >
                                {data.details.transactionStatus}
                            </div>
                        )}
                        <div className={styles.Reference}>{rowData.reference}</div>
                    </div>
                </div>

                {loading && (
                    <div className={styles.Loading}>
                        <FoldingCube color={colors.first} size={80} />
                    </div>
                )}
                {!loading && error && (
                    <div className={styles.Error}>
                        <GeneralError message="Unable to load transaction details" />
                    </div>
                )}
                {data && !loading && (
                    <>
                        <div className={`${styles.Details} ${disableEdit ? styles.DisableEdit : {}}`}>
                            {metadataMapped
                                .filter((x) => x.visible)
                                .map((item, i) =>
                                    item.type === 'Unknown' ? (
                                        customRenderer(item.name, i)
                                    ) : (
                                        <TransactionInfoItem
                                            key={i}
                                            label={item.label}
                                            value={
                                                (data.details[
                                                    toCamelCase(
                                                        item.name
                                                    ) as keyof TransactionInfoResponse
                                                ] as string) || '-'
                                            }
                                        />
                                    )
                                )}
                            {userInfo?.bCanReverseTransfer && rowData.bEligibleForReverse && (
                                <Button
                                    onClick={() => setShowReverseTransfer(true)}
                                    type="button"
                                    priority="secondary"
                                >
                                    Reverse transfer
                                </Button>
                            )}
                            {bCancellable && (
                                <Button
                                    onClick={() => setShowCancelTransfer(true)}
                                    type="button"
                                    priority="secondary"
                                >
                                    Cancel
                                </Button>
                            )}
                        </div>

                        <Formik
                            initialValues={{
                                notes: data.details.notes ?? '',
                                uploadFiles: [],
                                files: data.details.files ?? [],
                            }}
                            onSubmit={onSubmit}
                        >
                            {({ values, isSubmitting, dirty: formHasChanged }) => (
                                <Form>
                                    {!disableEdit && (
                                    <FileUpload
                                            label="Supporting documents"
                                            fieldName="uploadFiles"
                                            emptyUploadTitle="Drag and drop here or click to upload"
                                            emptyUploadMessage="You may upload PDF, PNG or JPEG files"
                                            multi
                                            small
                                        />
                                    )}
                                <UploadedFilesList disableDelete={disableEdit} fieldName="files" />
                                    {!disableEdit && (
                                    <FormTextField
                                            label="Add a note"
                                            field="notes"
                                            type="textarea"
                                                placeholder="Type your text here"
                                            maxLength={1000}
                                            inputProps={{ rows: undefined }}
                                            hint="Maximum text length is 1000 characters"
                                        />
                                )}
                                {disableEdit && values.notes && (
                                    <div className={styles.NoteDisabled}>
                                        <p className={styles.NotesLabel}>Notes:</p>
                                        <p>{values.notes || '-'}</p>
                                    </div>
                                )}

                                    {errorMessage && (
                                        <p className="ErrorText NoMargin">{errorMessage}</p>
                                    )}

                                    {!disableEdit && (
                                    <Button
                                            disabled={isSubmitting || !formHasChanged}
                                            type="submit"
                                            color="third"
                                        >
                                            Save
                                        </Button>
                                    )}
                            </Form>
                            )}
                        </Formik>
                    </>
                )}
            </div>
        </>
    );
};

type InfoItemProps = {
    label: string;
    value?: string | number;
};

const TransactionInfoItem: React.FC<InfoItemProps> = ({ label, value }) => (
    <div className={styles.InfoItem}>
        <p className={styles.Label}>{label}:</p>
        <p className={styles.Value}>{value || '-'}</p>
    </div>
);

const EmptySelection = () => (
    <div className={`${styles.Panel} ${styles.Empty}`}>
        <img alt="Empty" src={EmptyPanel} />
        <p>You have not selected a transaction to view information, select one.</p>
    </div>
);

const ApprovalDetailsSection = ({
    details,
}: {
    details: TransactionInfoResponse['approvalDetails'];
}) => {
    const [approvedBy, rejectedBy] = useMemo(
        () =>
            details?.processedBy
                ? partition(details.processedBy, (processor) => processor.bApproved)
                : [null, null],
        [details]
    );
    return (
        <div className={styles.ApprovalDetails}>
            <h3>
                {details.bApproved === null
                    ? `Requires ${details?.approvalsRemaining} more approval${
                          details?.approvalsRemaining > 1 ? 's' : ''
                      }`
                    : 'Approval details'}
            </h3>
            {approvedBy && approvedBy.length > 0 && (
                <>
                    <p>Approved By:</p>
                    {approvedBy.map((approver, i) => (
                        <div key={i} className={styles.ApprovalRow}>
                            <p>{approver.name}</p>
                            <p>{format(parseISO(approver.processedDate), 'MMM d @ h:mm a')} </p>
                        </div>
                    ))}
                </>
            )}
            {rejectedBy && rejectedBy.length > 0 && (
                <>
                    <p>Rejected By:</p>
                    <div>
                        <p>{rejectedBy[0].name}</p>
                        {<p>{format(parseISO(rejectedBy[0].processedDate), 'MMM d @ h:mm a')}</p>}
                    </div>
                </>
            )}
            {details.bApproved === null && (
                <Collapse header="Users awaiting approval">
                    {details.approversRemaining?.map((approver, i) => (
                        <div key={i}>
                            <p>{approver.name}</p>
                            <p>{approver.email}</p>
                        </div>
                    ))}
                </Collapse>
            )}
        </div>
    );
};
