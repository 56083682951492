export const convertLocaleDateToUtcDate = (date?: string | Date | null) => {
    const isString = typeof date === 'string';
    if (!date || (isString && !Date.parse(date as string))) return null;

    const dateObj: Date = isString ? new Date(date) : (date as Date);

    const utcDate = new Date(
        Date.UTC(dateObj.getFullYear(), dateObj.getMonth(), dateObj.getDate(), 0, 0, 0, 0)
    );

    return utcDate;
};
