import { CMSContent } from 'components/cmsContent/CMSContent';
import { RouteComponentProps } from '@reach/router';
import React from 'react';

interface DashboardInformationPageProps extends RouteComponentProps {
    page?: string;
}

export const DashboardInformationPage: React.FC<DashboardInformationPageProps> = ({ page }) => {
    return <div className="DashboardInformation">{page && <CMSContent page={page} />}</div>;
};
