import { Form, Formik } from 'formik';
import { Modal } from '../../../../components/modal/Modal';
import { useTFAField } from '../../../../helpers/useTFAField';
import { TFAField } from '../../../../components/form/TFAField';
import * as Yup from 'yup';
import Button from '../../../../components/button/Button';
import { TFAType } from '../../../register/models';

type Props = {
    open: boolean;
    handleClose: () => void;
    handleSubmit: (mfa: string, tfaType: TFAType) => void;
};

const validationSchema = Yup.object({
    tfaCode: Yup.string()
        .required('Please enter your MFA code')
        .min(6, 'Please enter your MFA code'),
});

export const MakePaymentMfaModal: React.FC<Props> = ({ open, handleClose, handleSubmit }) => {
    const [tfaType, toggleTfaType] = useTFAField();

    const createModalButtons = () => (
        <div className="ModalNavigation">
            <Button onClick={handleClose} priority="secondary">
                Close
            </Button>
            <Button type="submit" priority="primary" color="third">
                Make Payment
            </Button>
        </div>
    );

    if (!open) return null;

    return (
        <Formik
            initialValues={{ tfaCode: '', tfaType }}
            onSubmit={({ tfaCode, tfaType }) => handleSubmit(tfaCode, tfaType)}
            validationSchema={validationSchema}
        >
            {({ values, setFieldValue }) => (
                <Form>
                    <Modal
                        className="MakePaymentMfaModal"
                        title="Make Payment"
                        customButtons={createModalButtons()}
                        onCloseModal={handleClose}
                        closeOnClickOutside={false}
                    >
                        <TFAField
                            field="tfaCode"
                            label="TFA Code"
                            toggleTFAType={
                                toggleTfaType
                                    ? () => {
                                          toggleTfaType();
                                          setFieldValue(
                                              'tfaType',
                                              values.tfaType === 'SMS' ? 'AuthenticatorApp' : 'SMS'
                                          );
                                      }
                                    : null
                            }
                            tfaType={tfaType}
                            holdFocus
                            autoFocus
                        />
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
