import { store } from '.';

export const ERROR_CODES = {
    TfaCode_Invalid: 'TFA code not valid',
    Generic: 'There was a problem. Please try again later.',
    Required: 'Required',
    Invalid: 'Invalid',
    Already_In_Use: 'Already in use',
    Already_In_Use_For_Personal_Account: 'Already in use',
    Already_In_Use_For_Business_Account: 'Already in use',
    Password_No_Match: 'Passwords do not match',
    Password_Policy_No_Match: 'Password is not strong enough',
    Invalid_Password_Length: 'Minimum password length is 8 characters',
    Expired: 'Expired',
    Autologin_Failed: 'Automatic login failed, please login manually',
    Username_Or_Password_Incorrect: 'Email address or password incorrect',
    Account_Locked:
        'Your account has been locked for 15 minutes due to too many failed login attempts, please try again later',
    Account_Suppressed: 'Unable to access account',
    EmailAddress_Already_Verified: 'Email address is already verified',
    Insufficient_Funds: 'Insufficient funds',
    StartDate_Invalid: 'Invalid start date',
    Crypto_Price_Updated: 'Price outdated. Please try again.',
    //crypto buy/sell
    Insufficient_Customer_Balance: 'Insufficient Funds',
    Amount_Less_Than_Min_Amount: 'Transaction too small',
    Amount_More_Than_Max_Amount: 'Transaction too large',
    Amount_Invalid: 'Amount invalid',
    Price_Updated: 'The price has been updated, please refresh to get a new price',
    Screening_Failed: 'This transaction does not pass our screening criteria',
    //withdraw:
    Insufficient_Balance: 'Insufficient Funds',
    Address_Risks_Too_High:
        'Withdrawal forbidden due to security risks. Please try a different address.',
    //changePassword
    Password_Incorrect: 'Password incorrect',
    //cards:
    Max_Attempt_Number_Exceeded:
        'You have exceeded the maximum number of attempts, please contact support',
    Invalid_PaymentCard: 'Payment card is not valid',
    Failed: 'There may be something incorrect with card details. Please check your entries',
    Invalid_Asset: 'This cryptocurrency is not available',
    Invalid_Currency: 'This currency is not available',
    Asset_Pair_Unavailable: 'The selected currency/cryptocurrency pair is unavailable',

    Price_Too_Low: 'The crypto price is too low',
    Fees_Unavailable: 'Fees are unavailable',
    Exchange_Void: 'Void exchange',
    Request_Mismatch: 'Mismatched request',
    Terms_Not_Accepted: 'You have not accepted terms and conditions',
    Already_Submitted: 'Already submitted',
    Not_Supported: 'This (Form) flow is not supported. Please use IFrame instead',
    Session_Expired: 'Your session has expired',
    Invalid_State: 'Something went wrong. Please try again later', //calling api(s) in wrong order
    Password_Reset_Token_Invalid:
        'Your password reset link is either invalid or has expired - please go to forgotten password to try again', //this gets parsed in the resetpassword page to insert <a>, careful chaning it
    Password_Reset_Token_Expired:
        'Your password reset link is either invalid or has expired - please go to forgotten password to try again', //this gets parsed in the resetpassword page to insert <a>, careful chaning it
    Amout_Less_Than_Fee: 'Amount less than fee',
    Amount_Less_Than_Fee: 'Amount less than fee',
    Username_Not_Available: 'Username not available',

    Card_Limit_Reached: 'You have already requested the maximum number of cards.',
    No_Card_Available:
        'Your batch card program has run out of cards. Please request more and try again.',
    KYC_Failure: 'Your application was rejected. Please contact us.',
    Document_Images_Missing:
        'There was a problem retrieving proof of identity documents. Please re-upload or contact us.',
    Previous_KYC_Failure: 'You cannot request any new cards right now. Please contact us.',
    KYC_Images_Not_Found:
        'There was a problem retrieving proof of identity documents. Please re-upload or contact us.',
    Documents_Not_Verified:
        'User manager relations documents not verified. Please wait and try again, or contact us.',
    Amount_Too_Low: 'Amount too low',
    Amount_Too_High: 'Transaction amount above our maximum value',
    Price_Too_High: 'Transaction price above our maximum value',
    Price_Less_Than_Min_Amount: 'Transaction volume too low',
    MIN_NOT_REACHED_WITH_FEES: 'Minimum amount not reached with fees',
    Too_Many_Requests: 'Too many attempts, please try again tomorrow',
    CustomerBalance_too_low_to_afford_fee: 'Balance is too low to afford fee',
    Cardholder_Name_Invalid: 'Card holder name invalid',
    Awaiting_Customer_Approval: 'Transaction awaiting customer approval',
    Invalid_Format: 'The file provided is not an accepted file type and could not be saved',
    Invalid_Extension: 'The file provided is using an invalid extension and could not be saved',
    Upload_Failed: 'Failed to upload your file',
    No_supporting_documents_included: 'Please include supporting documents',
    'PostCode Format Invalid': 'PostCode Format Invalid',
    Invalid_TaxNumber: 'Invalid Tax Number',
    Invalid_Length: 'Field max length exceeded',
    Missing: 'Required',
    MISSING_TRANSMITTER_INFO: 'Missing payee info. Please update this payee before transferring',
    Rate_Limit_Exceeded: 'Exceeded allowed top up count. Please try again later.',
    Maximum_Amount_Exceeded: 'Maximum top up amount exceeded',
    Below_Minumum_Amount: 'Minimum top up amount not reached',
    Program_Does_Not_Allow_Top_Up: 'This program does not allow for top ups',
};

export const getErrorMessage = (
    code: string | keyof typeof ERROR_CODES,
    defaultErrorMessage?: string
) => {
    if (regexReplacements(code) !== code) return regexReplacements(code);
    const codes = store.getState().errorCodes.codes ?? ERROR_CODES;

    return codes[code as keyof typeof codes]
        ? codes[code as keyof typeof codes]
        : defaultErrorMessage ?? codes['Generic'];
};

const regexReplacements = (errorMessage: string) => {
    return errorMessage.replace(/^ach_limit_exceed_(.*?)/, (match, group1) => {
        return `The maximum transfer amount for ACH is ${group1}`;
    });
};
