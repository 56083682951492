import React from 'react';
import { ColumnDetail } from '@avamae/table';
import { makeRow, Checkbox, SingleCheckbox } from './FlexTable';
import { Cell, CellOptions } from './Cell';
import { toCamelCase } from 'helpers/formatFormFieldNames';

type LeftTableProps<T = any> = {
    idColumn: string;
    checkbox?: Checkbox;
    columns: ColumnDetail<any>[];
    columnData: any[];
    rowHovered: number | null;
    handleMouseEnterRow(e: any, i: number): void;
    onRowClick?(id: number): void;
    hiddenColumns?: string[];
    formatPrimitives(value: unknown): any;
    rowFormatter?(r: T): { [K in keyof T]: React.ReactNode };
    noRowHover?: boolean;
    columnCount: number;
    rowCellOptions?: { [K in keyof T]?: CellOptions };
    toggleColumnSort?: (k: string) => void;
    sortBy: { [k: string]: 'ASC' | 'DESC' };
    singleCheckbox?: SingleCheckbox;
};

const LeftTable: React.FC<LeftTableProps> = ({
    idColumn,
    checkbox,
    columns,
    columnData,
    rowHovered,
    handleMouseEnterRow,
    onRowClick,
    hiddenColumns,
    formatPrimitives,
    rowFormatter,
    noRowHover,
    columnCount = 0,
    rowCellOptions = {},
    toggleColumnSort,
    sortBy,
    singleCheckbox,
}) => {
    if ((columnCount <= 0 && !checkbox) || columns.length === 0) {
        return null;
    }

    const sliceBy = checkbox ? (columnCount <= 1 ? 1 : columnCount) : columnCount;
    const selectedColumns = columns
        .filter((c) => !hiddenColumns?.includes(c.columnKey.toString().toLowerCase()))
        .slice(0, sliceBy);

    const processedRowData = columnData.map((data, i) => {
        let rowData = {} as any;
        selectedColumns.forEach((col) => {
            rowData = {
                ...rowData,
                [col.columnKey]: data[toCamelCase(col.columnKey as string)] ?? data[col.columnKey],
            };
        });
        return { id: data[idColumn].toString(), ...rowData };
    });

    const allSelected = checkbox?.selectedIds.length === columnData.length;

    const toggleSelectAll = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (checkbox) {
            if (allSelected) {
                checkbox.setSelectedIds([]);
            } else {
                checkbox.setSelectedIds(columnData.map((data) => data[idColumn].toString()));
            }
        }
    };

    return (
        <div className="LeftSideTable">
            <div className={`Head ${!!checkbox?.singleRowSelect ? 'SingleRowSelect' : ''}`}>
                {checkbox && !checkbox.singleRowSelect && (
                    <Cell options={{ cellType: 'checkboxCell' }}>
                        <div>
                            <input
                                id="toggleSelectAll"
                                type="checkbox"
                                checked={allSelected}
                                onChange={toggleSelectAll}
                            />
                            <label className="CustomInput" htmlFor="toggleSelectAll" />
                        </div>
                    </Cell>
                )}
                {selectedColumns.map((c, i) => {
                    const sortByValue = sortBy[String(c.columnKey)] ?? 'NONE';
                    const isSorting = sortByValue === 'ASC' || sortByValue === 'DESC';
                    const { bSortable } = c;

                    return (
                        <Cell
                            key={i}
                            options={rowCellOptions[toCamelCase(c.columnKey as string)]}
                            sortable={bSortable}
                            onClick={() =>
                                bSortable &&
                                toggleColumnSort &&
                                toggleColumnSort(String(c.columnKey))
                            }
                        >
                            <span className={bSortable && isSorting ? 'Sorting' : ''}>
                                {c.labelValue}
                            </span>
                            {bSortable && (
                                <i
                                    className={
                                        sortByValue === 'ASC'
                                            ? 'SortIcon ASC'
                                            : sortByValue === 'DESC'
                                            ? 'SortIcon DESC'
                                            : 'SortIcon'
                                    }
                                />
                            )}
                        </Cell>
                    );
                })}
            </div>
            <div className="TableBody">
                {processedRowData.map((d, i) =>
                    makeRow({
                        id: d.id,
                        r: d,
                        index: i,
                        columns,
                        formatPrimitives,
                        hiddenColumns: hiddenColumns ?? [],
                        rowHovered,
                        handleMouseEnterRow,
                        onRowClick: onRowClick
                            ? () => onRowClick(columnData.find((_, j) => j === i)[idColumn])
                            : null,
                        rowButton: undefined,
                        formatRow: rowFormatter,
                        noRowHover,
                        checkbox,
                        rowSelected: checkbox?.selectedIds.includes(d.id),
                        hideRowOptionComponent: checkbox ? checkbox.selectedIds.length > 0 : false,
                        rowCellOptions,
                        includeCheckbox: true,
                        singleCheckbox,
                    })
                )}
            </div>
        </div>
    );
};

export { LeftTable };
