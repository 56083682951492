import { ManageVACustomers } from './ManageVACustomers';
import { VACustomerTransactions } from './VACustomerTransactions';

type Props = { id?: string; path?: string };

export enum VACustomerSubpage {
    Transactions = 'transactions',
}
export const VA_ACCOUNT_PARAM = 'account';

export const VACustomers: React.FC<Props> = ({ id, path }) => {
    if (!id) return <ManageVACustomers />;

    switch (path) {
        case VACustomerSubpage.Transactions:
            return <VACustomerTransactions id={id} />;
        default:
            return <ManageVACustomers />;
    }
};
