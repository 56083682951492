export const formatCurrency = (
    amount: number,
    currency = 'USD',
    locale = 'en-US',
    options?: Intl.NumberFormatOptions
) => {
    try {
        const formatter = new Intl.NumberFormat(locale, {
            ...options,
            style: 'currency',
            currency,
        });
        return formatter.format(amount);
    } catch (error) {
        return new Intl.NumberFormat(locale, options).format(amount) + ' ' + currency;
    }
};
