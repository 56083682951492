import React from 'react';
import { FormDropdownField } from './Dropdown';
import { Input } from './Input';
import { Formik, Form, FormikHelpers } from 'formik';
import { IPagination } from './models';

export interface IPaginationConfig {
    pageNumber: number;
    pageSize: number;
    lastPage: number;
    resultsCount: number;
    changePageSize: (size: number) => void;
    goToPage: (page: number) => void;
}

export const PaginationConfig: React.FC<IPaginationConfig> = ({
    pageNumber,
    pageSize,
    lastPage,
    resultsCount,
    changePageSize,
    goToPage,
}) => {
    const handleSubmit = (values: IPagination, { setFieldValue }: FormikHelpers<IPagination>) => {
        let parsedNumber = Number(values.pageNumber);
        if (
            parsedNumber &&
            parsedNumber !== pageNumber &&
            Number.isInteger(parsedNumber) &&
            parsedNumber <= lastPage
        ) {
            goToPage(parsedNumber);
        } else {
            setFieldValue('pageNumber', pageNumber);
        }
        if (values.pageSize !== pageSize) changePageSize(values.pageSize);
    };

    const initialValues: IPagination = {
        pageNumber,
        pageSize,
    };

    return (
        <div className="PageNumber">
            <Formik enableReinitialize initialValues={initialValues} onSubmit={handleSubmit}>
                {(formikProps) => (
                    <Form className="PageNumberInput">
                        <FormDropdownField
                            field={'pageSize'}
                            options={[
                                { label: '10', key: 10 },
                                { label: '20', key: 20 },
                                { label: '50', key: 50 },
                            ]}
                            required={true}
                            onChange={formikProps.submitForm}
                            hidePlaceholderText
                        />
                        Page
                        <Input
                            name={'pageNumber'}
                            className={'EditBox'}
                            type={'text'}
                            onBlur={formikProps.submitForm}
                        />
                    </Form>
                )}
            </Formik>
            of {resultsCount ? lastPage : 1}
        </div>
    );
};
