import instance, { ApiResponse, isErrorHandled, useFetch } from 'api';
import Button from 'components/button/Button';
import FormTextField from 'components/form/FormTextField';
import { TFAField } from 'components/form/TFAField';
import { Modal } from 'components/modal/Modal';
import { endpoints } from 'endpoints.config';
import { getErrorMessage } from 'errors';
import { Form, Formik, FormikHelpers } from 'formik';
import { toCamelCase } from 'helpers/formatFormFieldNames';
import { useTFAField } from 'helpers/useTFAField';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, selectModalState, ModalTypes } from 'reducers/modal';
import * as Yup from 'yup';
import WarningIcon from 'assets/Icon_Warning.png';
import { SelectOption } from '../form/FormSingleSelectField';
import { RadioButtons } from '../radiobuttons/radiobuttons';
import { Spinner } from '../spinner/Spinner';
import { useGetAccountDetailsForCrypto } from '../../helpers/useGetAccountDetails';
import { getCustomerComponentResources } from '../../reducers/componentResources';
import { completeUIUpdate } from '../notifications/notificationUIUpdateReducer';
import { SingleSelectRemoveOption } from '../form/SingleSelectRemoveOption';
import { DeleteNickname } from './DeleteNickname';

enum ADDRESS_OPTIONS {
    NEW_ADDRESS = 'New Wallet',
    SAVED_ADDRESS = 'Saved Wallet',
}

type FormState = {
    asset: string;
    amount: string;
    tfaCode: string;
    showTfa: boolean;
    addressChoice: ADDRESS_OPTIONS;

    existingAddressId: number | null;
    address?: string;
    nickname?: string;
};

type WalletOptions = {
    id: number;
    alias: string;
    walletAddress: string;
};

type Response = {
    walletOptions: WalletOptions[];
    balance: number;
};

export const WithdrawCryptoModal = ({ asset }: { asset: string }) => {
    const dispatch = useDispatch();
    const [errorMessage, setErrorMessage] = useState('');
    const modalState = useSelector(selectModalState);

    const [showTfa, setShowTfa] = useState(false);
    const [TFAType, toggleTFAType] = useTFAField(showTfa);

    const cryptoAccount = useGetAccountDetailsForCrypto(
        modalState.modalType === ModalTypes.WITHDRAW_CRYPTO ? modalState.data.crypto : ''
    );

    const { data, loading, reload } = useFetch<ApiResponse<Response>>(
        endpoints.cryptosmodule.withdraw,
        {
            params: { customerAssetAccountsId: cryptoAccount?.id ?? '' },
        }
    );

    const [deleteNickname, setDeleteNickname] = useState<null | SelectOption>(null);
    const onDeleteNickname = (value: SelectOption) => {
        setDeleteNickname(value);
    };

    if (modalState.modalType !== ModalTypes.WITHDRAW_CRYPTO) {
        dispatch(closeModal());
        return null;
    }

    const handleWithdraw = (values: FormState, formikHelpers: FormikHelpers<FormState>) => {
        if (!values.showTfa) {
            formikHelpers.setFieldValue('showTfa', true);
            setShowTfa(true);
            formikHelpers.setSubmitting(false);
            formikHelpers.setFieldTouched('tfaCode', false);
            return;
        }

        const isSavedWallet = values.addressChoice === ADDRESS_OPTIONS.SAVED_ADDRESS;
        const addressPayload = isSavedWallet
            ? {
                  assetWalletAliasId: values.existingAddressId,
              }
            : {
                  address: values.address,
                  walletNickname: values.nickname,
              };

        const payload = {
            tfaCode: values.tfaCode,
            tfaType: TFAType,
            asset: values.asset,
            amount: values.amount,
            ...addressPayload,
        };

        const url = isSavedWallet
            ? endpoints.cryptosmodule.withdrawSavedWallet
            : endpoints.cryptosmodule.withdrawNewWallet;

        instance
            .post(url, payload)
            .then((res) => {
                dispatch(getCustomerComponentResources());
                dispatch(completeUIUpdate());
                dispatch(closeModal());
            })
            .catch((err) => {
                formikHelpers.setSubmitting(false);
                if (isErrorHandled(err)) {
                    const errors = err.response.data.errors;
                    if (
                        errors.some((error) =>
                            ['Amount', 'Address', 'TfaCode'].includes(error.fieldName)
                        )
                    ) {
                        errors.forEach((error) =>
                            formikHelpers.setFieldError(
                                toCamelCase(error.fieldName) as string,
                                getErrorMessage(error.messageCode)
                            )
                        );
                    } else setErrorMessage(getErrorMessage(errors[0].messageCode));
                } else {
                    setErrorMessage('Something went wrong');
                }
            });
    };

    const createModalButtons = (submitfn: () => void, submitting: boolean) => (
        <div className="ModalNavigation">
            <Button type="button" onClick={() => dispatch(closeModal())} priority="secondary">
                Close
            </Button>
            <Button type="submit" onClick={submitfn} priority="primary" disabled={submitting}>
                Withdraw
            </Button>
        </div>
    );

    const destinationLabel =
        asset === 'USDC' ? (
            <label htmlFor="address">
                Destination wallet address{' '}
                <div className="UsdcWarning">
                    <img src={WarningIcon} alt="Attention" />
                    USDC withdrawals use the Ethereum blockchain. Do not use a non-Ethereum USDC
                    wallet, as funds may be lost.
                </div>
            </label>
        ) : (
            <label htmlFor="address">Destination wallet address</label>
        );

    const destinationOptions = data?.details.walletOptions
        ? data?.details.walletOptions.map((opt) => ({
              label: opt.alias,
              value: opt.id,
              subtitle: opt.walletAddress
          }))
        : [];
    const hasOptions = destinationOptions.length > 0;

    const initialValues: FormState = {
        address: '',
        asset: modalState.data.crypto,
        amount: '',
        tfaCode: '',
        showTfa: false,
        addressChoice: hasOptions ? ADDRESS_OPTIONS.SAVED_ADDRESS : ADDRESS_OPTIONS.NEW_ADDRESS,
        nickname: '',
        existingAddressId: null,
    };

    const validationSchema = Yup.object({
        showTfa: Yup.boolean(),
        amount: Yup.number()
            .required('Please enter an amount')
            .moreThan(0, 'Please enter an amount')
            .test('maxCheck', 'Insufficient funds', function (val) {
                if (!val || !data || typeof data.details.balance === undefined) return true;
                return val <= data.details.balance;
            }),
        tfaCode: Yup.string().when('showTfa', {
            is: true,
            then: Yup.string().required('Please enter your TFA code'),
        }),
        addressChoice: Yup.mixed().oneOf([
            ADDRESS_OPTIONS.SAVED_ADDRESS,
            ADDRESS_OPTIONS.NEW_ADDRESS,
        ]),
        address: Yup.string().when('addressChoice', {
            is: ADDRESS_OPTIONS.NEW_ADDRESS,
            then: Yup.string().required('Required'),
        }),
        nickname: Yup.string().when('addressChoice', {
            is: ADDRESS_OPTIONS.NEW_ADDRESS,
            then: Yup.string().required('Required'),
        }),
        existingAddressId: Yup.number()
            .when('addressChoice', {
                is: ADDRESS_OPTIONS.SAVED_ADDRESS,
                then: Yup.number().required('Required').nullable(),
            })
            .nullable(),
    });

    const addressOptions = [ADDRESS_OPTIONS.NEW_ADDRESS];
    if (hasOptions) addressOptions.push(ADDRESS_OPTIONS.SAVED_ADDRESS);

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={handleWithdraw}
            validationSchema={validationSchema}
            enableReinitialize
        >
            {({ submitForm, isSubmitting, values }) => (
                <Form>
                    {deleteNickname && (
                        <DeleteNickname
                            nicknameId={deleteNickname.value}
                            nickname={deleteNickname.label}
                            onClose={() => setDeleteNickname(null)}
                            onSuccess={() => {
                                reload();
                                setDeleteNickname(null);
                            }}
                        />
                    )}
                    <Modal
                        title={`Withdraw ${modalState.data.crypto}`}
                        className="WithdrawCrypto"
                        customButtons={createModalButtons(submitForm, isSubmitting)}
                    >
                        {loading && (
                            <div className="Loading">
                                <Spinner />
                            </div>
                        )}
                        {!loading && (
                            <>
                                <div className="WithdrawCryptoModal">
                                    <RadioButtons
                                        label="Address destination"
                                        fieldname="addressChoice"
                                        options={addressOptions}
                                    />
                                    {values.addressChoice === ADDRESS_OPTIONS.SAVED_ADDRESS && (
                                        <SingleSelectRemoveOption
                                            onDeleteItem={onDeleteNickname}
                                            fieldName={'existingAddressId'}
                                            options={destinationOptions ?? []}
                                            label="Select an existing wallet address"
                                            dropdownProps={{
                                                placeholder: 'Please select',
                                                maxMenuHeight: 130,
                                            }}
                                        />
                                    )}
                                    {values.addressChoice === ADDRESS_OPTIONS.NEW_ADDRESS && (
                                        <>
                                            <FormTextField
                                                field="address"
                                                label=""
                                                required={true}
                                                labelChild={destinationLabel}
                                            />
                                            <FormTextField
                                                field="nickname"
                                                label="Wallet nickname"
                                                required={true}
                                            />
                                        </>
                                    )}
                                    <FormTextField
                                        field="amount"
                                        label={`Amount of ${modalState.data.crypto}`}
                                        required={true}
                                        inputMode="decimal"
                                        type="number"
                                    />
                                    {values.showTfa && (
                                        <TFAField
                                            field={'tfaCode'}
                                            label={'TFA Code'}
                                            required={true}
                                            toggleTFAType={toggleTFAType}
                                            tfaType={TFAType}
                                            autoFocus
                                        />
                                    )}
                                </div>
                                {errorMessage && <div className="ErrorText">{errorMessage}</div>}
                            </>
                        )}
                    </Modal>
                </Form>
            )}
        </Formik>
    );
};
