import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'components/modal/Modal';
import Button from 'components/button/Button';
import { Form, Formik } from 'formik';
import styles from './DBSPaymentAgrModal.module.scss';
import PDFViewer from 'components/pdf/PDFViewer';
type Props = {
    close(): void;
    onConcentAccepted: () => void;
};

function DBSPaymentAgreementModal(props: Props) {
    const [isScrolledToEnd, setIsScrolledToEnd] = useState(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const initialValues = {
        acceptConsent: false,
        acceptCHA: false,
    };

    return (
        <Modal
            title={`Information Regarding your Payment`}
            onCloseModal={props.close}
            className={styles.modal_content}
            customButtons={
                <div className="ModalNavigation">
                    <Button priority="secondary" type="button" onClick={props.close}>
                        Cancel
                    </Button>
                    <Button
                        priority="primary"
                        type="submit"
                        disabled={!isScrolledToEnd}
                        className="Btn BtnRed"
                        onClick={props.onConcentAccepted}
                    >
                        I Understand
                    </Button>
                </div>
            }
        >
            <Formik
                initialValues={initialValues}
                // validationSchema={validationSchema}
                onSubmit={(values) => {}}
            >
                {() => (
                    <Form>
                        <PDFViewer
                            fileUrl={'/pdfs/DBSAgreement.pdf'}
                            setIsScrolledToEnd={setIsScrolledToEnd}
                        />

                        <p style={{ color: 'red', fontSize: 12, minHeight: 20 }}>
                            {!isScrolledToEnd ? (
                                'Please read through the entire document to continue.'
                            ) : (
                                <></>
                            )}
                            <br />
                            {errorMessage && errorMessage}
                        </p>
                    </Form>
                )}
            </Formik>
        </Modal>
    );
}
export default DBSPaymentAgreementModal;
