import { useField } from 'formik';
import React from 'react';

import CrossIcon from 'assets/ibanera/Icon_RedCross.png';

type Props = {
    fieldName: string;
    disableDelete?: boolean;
};

type UploadedFile = {
    fileName: string;
    url: string;
    bDeleted?: boolean;
};

export const UploadedFilesList: React.FC<Props> = ({ fieldName, disableDelete = false }) => {
    const [{ value }, , { setValue }] = useField<UploadedFile[] | null>(fieldName);

    const handleDelete = (filename: string) => {
        setValue(
            (value ?? []).map((file) =>
                file.fileName !== filename ? file : { ...file, bDeleted: true }
            )
        );
    };

    if (!value || value.filter((file) => !file.bDeleted).length === 0) return null;

    return (
        <>
            <div className="FormLabel">Uploaded documents</div>
            <div className="UploadedFilesList">
                {value
                    .filter((file) => !file.bDeleted)
                    .map((file, i) => (
                        <a
                            key={i}
                            href={file.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="File"
                        >
                            <div className="FileName">{file.fileName}</div>
                            {!disableDelete && (
                                <button
                                    type="button"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        e.preventDefault();
                                        handleDelete(file.fileName);
                                    }}
                                    aria-label="Delete file"
                                >
                                    <img src={CrossIcon} alt="Delete file icon" />
                                </button>
                            )}
                        </a>
                    ))}
            </div>
        </>
    );
};
