import { useDispatch, useSelector } from 'react-redux';
import { ModalTypes, closeModal, selectModalState } from '../../reducers/modal';
import { Modal } from '../modal/Modal';
import { PayeePanel } from '../../pages/account/PayeePanel/PayeePanel';
import styles from '../../pages/account/PayeePanel/PayeePanel.module.scss';

export const PayeeDetailsModal = () => {
    const modalState = useSelector(selectModalState);
    const dispatch = useDispatch();

    if (modalState.modalType !== ModalTypes.PAYEE_DETAILS) return null;

    const { rowData, disablePayment } = modalState.data;

    const handleCloseModal = () => {
        dispatch(closeModal());
    };

    return (
        <Modal className={styles.Modal}>
            <PayeePanel
                disablePayment={disablePayment}
                selectedRowData={rowData}
                closePanel={handleCloseModal}
            />
        </Modal>
    );
};
