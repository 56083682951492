import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { checkSignedIn, selectAuthStatus } from "reducers/auth";

export function useCheckSignedIn(): boolean {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);

  useEffect(() => {
    dispatch(checkSignedIn());
  }, [dispatch]);

  return authStatus === "signed_in";
}
