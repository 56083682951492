import { AxiosError, AxiosRequestConfig } from 'axios';
import { addMinutes } from 'date-fns';
import { produce } from 'immer';
import { CreateBusinessProfileSubmission } from 'pages/profile/CreateBusinessProfile';
import { Toast, ToastMessageReason } from 'helpers/toast';
import { ActionsObservable, combineEpics, ofType } from 'redux-observable';
import { createSelector } from 'reselect';
import { concat, empty, from, merge, Observable, of } from 'rxjs';
import { catchError, filter, map, mergeMap, switchMap, takeUntil, tap } from 'rxjs/operators';
import api, { ApiResponse, isAxiosErrorHandled } from '../api';
import { endpoints } from '../endpoints.config';
import {
    deleteTokenResource,
    LoadedTokenResource,
    loadTokenResource,
    saveTokenResource,
    Scopes,
} from '../helpers/auth';
import { Errors } from '../helpers/forms';
import {
    deleteCustomerComponentResources,
    getCustomerComponentResources,
} from './componentResources';
import { closeModal } from './modal';
import { Store } from './rootReducer';
import { deleteVerificationStatus, fetchVerificationStatus } from './verification';
import { navigate } from '@reach/router';
import { getInitialCryptoPrices } from './cryptoPrices';

export type SignInAttempt = {
    username: string;
    password: string;
    rememberMe: boolean;
};

export type AutoLoginAttempt = {
    autoLoginToken: string;
};

export type TFASignInAttempt = {
    username: string;
    password: string;
    rememberMe: boolean;
    tfaCode: string;
    tfaType: string;
};

type SignInError = {
    error: null | string;
    error_description: Errors;
    error_uri: null;
};
type SignInSuccess = {
    details: {
        accessToken: string;
        bEmailVerified: true;
        bTwoFactorAppAuthEnabled: false;
        bTwoFactorSMSAuthEnabled: false;
        expiresIn: string;
        refreshToken: string;
        refreshTokenExpiresIn: number;
        scopes?: Scopes;
    };
    status: string;
};

type SignInNeedsTFA = {
    details: {
        accessToken: null;
        bEmailVerified: boolean;
        bTwoFactorAppAuthEnabled: boolean;
        bTwoFactorSMSAuthEnabled: boolean;
        expiresIn: 0;
        refreshToken: null;
        scopes?: Scopes;
    };
    status: string;
};
type SignInIsSuppressed = {
    details: {
        bSuppressed: true;
        accessToken: null;
        refreshToken: null;
        accountType: string;
        bEmailVerified: boolean;
        bTwoFactorAppAuthEnabled: boolean;
        bTwoFactorSMSAuthEnabled: boolean;
        expiresIn: 0;
        refreshTokenExpiresIn: 0;
    };
    status: string;
};
type SignInNeedsEmailVerified = {
    details: {
        accessToken: null;
        bEmailVerified: false;
        bTwoFactorAppAuthEnabled: boolean;
        bTwoFactorSMSAuthEnabled: boolean;
        expiresIn: 0;
        refreshToken: null;
        refreshTokenExpiresIn: 0;
        scopes?: Scopes;
    };
    status: string;
};
type SignInNeedsToAddTFAToAccount = {
    details: {
        accessToken: null;
        bEmailVerified: true;
        bTwoFactorAppAuthEnabled: false;
        bTwoFactorSMSAuthEnabled: false;
        expiresIn: 0;
        refreshToken: null;
        refreshTokenExpiresIn: 0;
        scopes?: Scopes;
    };
    status: string;
};

type SwitchAccountRequest = {
    targetCustomerUsersCustomersId: number;
    // refreshToken: string;
    // accessToken: string;
};

type SignInResponse =
    | SignInSuccess
    | SignInError
    | SignInNeedsTFA
    | SignInNeedsEmailVerified
    | SignInIsSuppressed;

type TFATypesResponse = { bTwoFactorAppAuthEnabled: boolean; bTwoFactorSMSAuthEnabled: boolean };

// Type guards to check response type.
const isSignInResponseSuccess = (r: SignInResponse): r is SignInSuccess =>
    (r as SignInSuccess).status === '1' && !!(r as SignInSuccess).details.accessToken;

const isResponseNeedsTFA = (r: SignInResponse): r is SignInNeedsTFA => {
    const response = r as SignInNeedsTFA;
    return response.details.bTwoFactorAppAuthEnabled || response.details.bTwoFactorSMSAuthEnabled;
};
const isUserSuppresed = (r: SignInResponse): r is SignInIsSuppressed => {
    const response = r as SignInIsSuppressed;
    return response.details.bSuppressed;
};
const isResponseEmailUnverified = (r: SignInResponse): r is SignInNeedsEmailVerified => {
    return !(r as SignInNeedsEmailVerified).details.bEmailVerified;
};
const isResponseNeedsToAddTFAToAccount = (
    response: SignInResponse
): response is SignInNeedsToAddTFAToAccount => {
    const r = response as SignInNeedsToAddTFAToAccount;
    return (
        r.details.bEmailVerified &&
        !r.details.bTwoFactorAppAuthEnabled &&
        !r.details.bTwoFactorSMSAuthEnabled
    );
};
/* STATE */
type AuthStatus =
    | 'signed_out'
    | 'signed_in'
    | 'need_tfa'
    | 'need_email_verified'
    | 'pending'
    | 'tfa_pending'
    | 'suppressed'
    | 'needs_to_add_account_tfa';
export type TFA = 'sms' | 'app' | 'both';

type State = {
    appVisible: boolean;
    authStatus: AuthStatus;
    TFAType: TFA | null;
    accessTokenNeedsRefresh: boolean;
    message: Errors;
    accessTokenRefreshing: boolean;
    appLoading: boolean;
    accountSwitchInProgress: boolean;
};

const initialState: State = {
    authStatus: 'signed_out',
    appVisible: false,
    TFAType: null,
    accessTokenNeedsRefresh: false,
    message: [],
    accessTokenRefreshing: false,
    appLoading: false,
    accountSwitchInProgress: false,
};

const getTFAType = (payload: SignInNeedsTFA): TFA | null => {
    if (payload.details.bTwoFactorAppAuthEnabled && payload.details.bTwoFactorSMSAuthEnabled) {
        return 'both';
    }
    if (payload.details.bTwoFactorAppAuthEnabled) {
        return 'app';
    }
    if (payload.details.bTwoFactorSMSAuthEnabled) {
        return 'sms';
    }
    return null;
};

/* ACTIONS */

export const SIGN_OUT = 'app/auth/SIGN_OUT';
const SIGN_OUT_WITH_CALL_EPIC_ACTION = 'app/auth/SIGN_OUT_EPIC_ACTION';
const SIGN_OUT_WITHOUT_CALL_EPIC_ACTION = 'app/auth/SIGN_OUT_WITHOUT_CALL_EPIC_ACTION';
const SIGN_IN = 'app/auth/SIGN_IN';
const AUTO_LOGIN = 'app/auth/AUTO_LOGIN';
const TFA_SIGN_IN = 'app/auth/TFA_SIGN_IN';
export const SIGN_IN_SUCCESS = 'app/auth/SIGN_IN_OKAY';
const SIGN_IN_NEEDS_TFA = 'app/auth/SIGN_IN_NEED_TFA';
const SIGN_IN_USER_SUPPRESSED = 'app/auth/SIGN_IN_USER_SUPPRESSED';
const SIGN_IN_NEEDS_EMAIL_VERIFIED = 'app/auth/SIGN_IN_NEED_EMAIL_VERIFIED';
const SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT = 'SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT';
const SIGN_IN_ERROR = 'app/auth/SIGN_IN_ERROR';
const TFA_SIGN_IN_ERROR = 'app/auth/TFA_SIGN_IN_ERROR';
const SHOW_APP = 'app/auth/SHOW_APP';
const CHECK_SIGNED_IN = 'app/auth/CHECK_SIGNED_IN';
const SWITCH_ACCOUNT_TYPE = 'app/auth/SWITCH_ACCOUNT_TYPE';
const CREATE_LINKED_ACCOUNT = 'app/auth/CREATE_LINKED_ACCOUNT';
const CREATE_LINKED_ACCOUNT_ERROR = 'app/auth/CREATE_LINKED_ACCOUNT_ERROR';
const REFRESH_ACCESS_TOKEN = 'app/auth/REFRESH_ACCESS_TOKEN';
const SET_ACCESS_TOKEN_REFRESHED = 'app/auth/SET_ACCESS_TOKEN_REFRESHED';
const GET_USER_TFA_SETTING = 'app/auth/GET_USER_TFA_SETTINGS';
const SET_USER_TFA_SETTING = 'app/auth/SET_USER_TFA_SETTINGS';
const SET_AUTH_STATUS = 'app/auth/SET_AUTH_STATE';
const SET_ACCESS_TOKEN_REFRESHING = 'app/auth/SET_ACCESS_TOKEN_REFRESHING';
const CLEAR_MESSAGE = 'app/auth/CLEAR_AUTH_MESSAGE';
const SET_ACCOUNT_SWITCH_COMPLETE = 'app/auth/SET_ACCOUNT_SWITCH_COMPLETE';

type Action =
    | { type: typeof SIGN_IN; payload: SignInAttempt }
    | { type: typeof AUTO_LOGIN; payload: AutoLoginAttempt }
    | { type: typeof TFA_SIGN_IN; payload: TFASignInAttempt }
    | { type: typeof SIGN_OUT; message: []; signOutMarker: boolean }
    | { type: typeof SIGN_OUT_WITHOUT_CALL_EPIC_ACTION }
    | { type: typeof SIGN_OUT_WITH_CALL_EPIC_ACTION }
    | { type: typeof CHECK_SIGNED_IN }
    | { type: typeof SHOW_APP; payload: boolean }
    | { type: typeof SIGN_IN_SUCCESS; payload: SignInSuccess }
    | { type: typeof SIGN_IN_NEEDS_TFA; payload: SignInNeedsTFA }
    | { type: typeof SIGN_IN_USER_SUPPRESSED; payload: SignInIsSuppressed }
    | { type: typeof SIGN_IN_NEEDS_EMAIL_VERIFIED; payload: SignInNeedsEmailVerified }
    | { type: typeof SIGN_IN_ERROR; payload: SignInError }
    | { type: typeof SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT }
    | { type: typeof TFA_SIGN_IN_ERROR; payload: SignInError }
    | { type: typeof CREATE_LINKED_ACCOUNT_ERROR; payload: SignInError }
    | SwitchAccountAction
    | { type: typeof REFRESH_ACCESS_TOKEN }
    | { type: typeof GET_USER_TFA_SETTING }
    | { type: typeof SET_USER_TFA_SETTING; payload: TFATypesResponse }
    | { type: typeof SET_AUTH_STATUS; payload: AuthStatus }
    | { type: typeof SET_ACCESS_TOKEN_REFRESHED }
    | { type: typeof SET_ACCESS_TOKEN_REFRESHING; payload: boolean }
    | { type: typeof CLEAR_MESSAGE }
    | { type: typeof SET_ACCOUNT_SWITCH_COMPLETE };

/* REDUCER */

export default function reducer(state = initialState, action: Action): State {
    return produce(state, (draft) => {
        switch (action.type) {
            case SHOW_APP:
                draft.appVisible = true;
                // Is the user already signed in?
                if (action.payload === true) {
                    draft.authStatus = 'signed_in';
                }
                break;
            case SIGN_OUT:
                draft.authStatus = 'signed_out';
                draft.message = action.message;
                break;
            case SIGN_IN:
                draft.authStatus = 'pending';
                draft.message = [];
                break;
            case TFA_SIGN_IN:
                draft.authStatus = 'tfa_pending';
                draft.message = [];
                break;
            case AUTO_LOGIN:
                draft.authStatus = 'pending';
                draft.message = [];
                draft.appLoading = true;
                break;
            case SIGN_IN_SUCCESS:
                draft.authStatus = 'signed_in';
                draft.appLoading = false;
                break;
            case SIGN_IN_NEEDS_TFA:
                draft.authStatus = 'need_tfa';
                draft.TFAType = getTFAType(action.payload);
                break;
            case SIGN_IN_USER_SUPPRESSED:
                draft.authStatus = 'suppressed';
                draft.TFAType = getTFAType(action.payload);
                break;
            case SIGN_IN_NEEDS_EMAIL_VERIFIED:
                draft.authStatus = 'need_email_verified';
                break;
            case SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT:
                draft.authStatus = 'needs_to_add_account_tfa';
                break;
            case SIGN_IN_ERROR:
                draft.authStatus = 'signed_out';
                draft.message = action.payload.error_description;
                draft.appLoading = false;
                break;
            case TFA_SIGN_IN_ERROR:
                draft.authStatus = 'need_tfa';
                draft.message = action.payload.error_description;
                break;
            case CREATE_LINKED_ACCOUNT_ERROR:
                draft.message = action.payload.error_description;
                break;
            case REFRESH_ACCESS_TOKEN:
                draft.accessTokenNeedsRefresh = true;
                break;
            case SET_AUTH_STATUS:
                draft.authStatus = action.payload;
                break;
            case SET_ACCESS_TOKEN_REFRESHED:
                draft.accessTokenNeedsRefresh = false;
                break;
            case SET_USER_TFA_SETTING:
                const { bTwoFactorAppAuthEnabled, bTwoFactorSMSAuthEnabled } = action.payload;
                if (bTwoFactorAppAuthEnabled && bTwoFactorSMSAuthEnabled) {
                    draft.TFAType = 'both';
                } else if (bTwoFactorAppAuthEnabled) {
                    draft.TFAType = 'app';
                } else if (bTwoFactorSMSAuthEnabled) {
                    draft.TFAType = 'sms';
                }
                break;
            case CLEAR_MESSAGE:
                draft.message = [];
                break;
            case SET_ACCESS_TOKEN_REFRESHING:
                draft.accessTokenRefreshing = action.payload;
                break;
            case SWITCH_ACCOUNT_TYPE:
                draft.accountSwitchInProgress = true;
                break;
            case SET_ACCOUNT_SWITCH_COMPLETE:
                draft.accountSwitchInProgress = false;
                break;
        }
    });
}

/* EPICS */

function makeRequest<T>(config: AxiosRequestConfig) {
    return from(api.request<T>(config));
}

export function saveResponse(r: SignInResponse) {
    if (isSignInResponseSuccess(r)) {
        const { accessToken, refreshToken, refreshTokenExpiresIn, scopes } = r.details;
        saveTokenResource({
            accessToken,
            refreshToken,
            refreshTokenExpiresIn,
            scopes: scopes ?? [],
        });
    }
}

export const checkResponse = (r: SignInResponse) => {
    if (isResponseEmailUnverified(r)) {
        return signInNeedsEmailVerified(r);
    }
    if (isResponseNeedsToAddTFAToAccount(r)) {
        return signInNeedsToAddTFAToAccount(r);
    }
    if (isSignInResponseSuccess(r)) {
        return signInSuccess(r);
    }
    if (isUserSuppresed(r)) {
        return signInUserSuppressed(r);
    }
    if (isResponseNeedsTFA(r)) {
        return signInNeedsTFA(r);
    }
    return signInError(r);
};

// const parseApiErrorMessageCode = (code: string): string => {
//   switch (code) {
//     case "Invalid_User":
//       return "Invalid";
//     default:
//       return "Oops, something went wrong. Please try again later.";
//   }
// };

/** Attempt to sign in and handle response, cancel if we sign out while a request pending. */
const signInEpic = (action$: ActionsObservable<any>): Observable<Action> =>
    action$.pipe(
        ofType(SIGN_IN),
        switchMap((attempt) =>
            makeRequest<SignInResponse>({
                url: endpoints.auth.login,
                data: attempt.payload,
                method: 'post',
            }).pipe(
                tap((res) => saveResponse(res.data)),
                map((res) => checkResponse(res.data)),
                catchError((err: AxiosError) => {
                    const errorMessage = err?.response?.data.errors ?? 'Something went wrong';

                    return of(
                        signInError({
                            error: null,
                            error_description: errorMessage,
                            error_uri: null,
                        })
                    );
                })
                //takeUntil(action$.pipe(ofType(SIGN_OUT, SIGN_IN_ERROR)))
            )
        )
    );
const autoLoginEpic = (action$: ActionsObservable<any>): Observable<Action> =>
    action$.pipe(
        ofType(AUTO_LOGIN),
        switchMap((attempt) =>
            makeRequest<SignInResponse>({
                url: endpoints.auth.autoLogin,
                data: attempt.payload,
                method: 'post',
            }).pipe(
                tap((res) => saveResponse(res.data)),
                map((res) => checkResponse(res.data)),
                catchError((err: AxiosError) => {
                    return of(
                        signInError({
                            error: null,
                            error_description: [
                                { messageCode: 'Autologin_Failed', fieldName: 'any' },
                            ],
                            error_uri: null,
                        })
                    );
                })
                //takeUntil(action$.pipe(ofType(SIGN_OUT, SIGN_IN_ERROR)))
            )
        )
    );
/** Attempt to tfa sign in and handle response, cancel if we sign out while a request pending. */
const tfaSignInEpic = (action$: ActionsObservable<any>): Observable<Action> =>
    action$.pipe(
        ofType(TFA_SIGN_IN),
        switchMap((attempt) =>
            makeRequest<SignInResponse>({
                url: endpoints.auth.tfaLogin,
                data: attempt.payload,
                method: 'post',
            }).pipe(
                tap((res) => saveResponse(res.data)),
                map((res) => checkResponse(res.data)),
                catchError((err: AxiosError) => {
                    const errorMessage = err?.response?.data.errors;

                    return of(
                        tfaSignInError({
                            error: null,
                            error_description: errorMessage,
                            error_uri: null,
                        })
                    );
                })
                //takeUntil(action$.pipe(ofType(SIGN_OUT, SIGN_IN_ERROR)))
            )
        )
    );

/** Clear the session storage on sign out, so we don't appear logged in on refresh. */
const signOutWithBackendCallEpic = (action$: ActionsObservable<any>) =>
    action$.pipe(
        ofType(SIGN_OUT_WITH_CALL_EPIC_ACTION),
        tap(() => {
            try {
                const tokenResource = loadTokenResource();
                if (tokenResource) {
                    makeRequest({
                        url: endpoints.profilemodule.signout,
                        method: 'POST',
                        // Manually include token in sign-out request as deleteing TokenResource
                        // from session storage causes request to fail.
                        headers: { Authorization: `Bearer ${tokenResource.accessToken}` },
                    });
                }
            } finally {
                deleteTokenResource();
            }
        }),
        mergeMap(() => [
            {
                type: SIGN_OUT,
                message: [],
                signOutMarker: true,
            },
            closeModal(),
            deleteCustomerComponentResources(),
            deleteVerificationStatus(),
        ])
    );

const signOutWithoutBackendCallEpic = (action$: ActionsObservable<any>) =>
    action$.pipe(
        ofType(SIGN_OUT_WITHOUT_CALL_EPIC_ACTION),
        tap(() => deleteTokenResource()),
        mergeMap(() => [
            {
                type: SIGN_OUT,
                message: [],
                signOutMarker: true,
            },
            closeModal(),
            deleteCustomerComponentResources(),
            deleteVerificationStatus(),
        ])
    );

const checkSignedInEpic = (action$: ActionsObservable<any>) =>
    action$.pipe(
        ofType(CHECK_SIGNED_IN),
        map(loadTokenResource),
        map((r) => r !== null),
        map(showApp)
    );

const saveFetchedTokenResource = (r: any) => {
    const { accessToken, refreshToken, scopes, refreshTokenExpiresIn } = r;
    saveTokenResource({
        accessToken,
        refreshToken,
        refreshTokenExpiresIn,
        scopes: scopes ?? [],
    });
};

type SwitchAccountAction = {
    type: typeof SWITCH_ACCOUNT_TYPE;
    payload: SwitchAccountRequest;
};
const addTokenInfo = (payload: SwitchAccountRequest) => {
    const token = loadTokenResource();
    return {
        ...payload,
        accessToken: token?.accessToken,
        refreshToken: token?.refreshToken,
    };
};

const switchAccountTypeEpic = (action$: ActionsObservable<SwitchAccountAction>) =>
    action$.pipe(
        ofType(SWITCH_ACCOUNT_TYPE),
        map((action) => addTokenInfo(action.payload)),
        mergeMap((payload) => {
            return merge(
                makeRequest<ApiResponse<SignInResponse>>({
                    url: endpoints.profilemodule.switchToLinkedAccount,
                    method: 'post',
                    data: payload,
                }).pipe(
                    filter((response) => response.data.status === '1'),
                    mergeMap((response) => {
                        const r = response.data.details as SignInSuccess;
                        deleteTokenResource();
                        saveFetchedTokenResource(r);

                        return concat(
                            of(deleteCustomerComponentResources()).pipe(tap(() => navigate('/'))),
                            from([
                                getCustomerComponentResources([fetchVerificationStatus() as any]),
                                setAccountSwitchComplete(),
                                getInitialCryptoPrices(),
                            ])
                        );
                    }),
                    catchError((err) => {
                        if (
                            isAxiosErrorHandled(err) &&
                            err.response.data.errors.some(
                                (error) => error.messageCode === 'Customer_Suppressed'
                            )
                        ) {
                            Toast.openToastMessage(
                                'Unable to access account',
                                ToastMessageReason.ERROR
                            );
                            return of(setAccountSwitchComplete());
                        }
                        Toast.openToastMessage(
                            'There was an error switching your account, you may need to login again',
                            ToastMessageReason.ERROR
                        );
                        return of(setAccountSwitchComplete());
                    })
                )
            );
        })
    );
const getUserTFASettingsEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(GET_USER_TFA_SETTING),
        switchMap(() => {
            return makeRequest<ApiResponse<TFATypesResponse>>({
                url: endpoints.profilemodule.usersecurityinfo,
            }).pipe(
                filter((response) => {
                    return response.data.status === '1';
                }),
                map((response) => {
                    return setUserTFASetting(response.data.details);
                }),
                catchError((error) => empty()),
                takeUntil(action$.pipe(ofType(SIGN_OUT)))
            );
        })
    );

type CreateLinkedAccountAction = {
    type: typeof CREATE_LINKED_ACCOUNT;
    payload?: CreateBusinessProfileSubmission;
};

const createLinkedAccountEpic = (action$: ActionsObservable<CreateLinkedAccountAction>) =>
    action$.pipe(
        ofType(CREATE_LINKED_ACCOUNT),
        switchMap((action) => {
            return makeRequest<
                ApiResponse<{ customerUsersCustomersID: number; customersId: number }>
            >({
                url: endpoints.profilemodule.registerlinkedcustomer,
                method: 'post',
                data: action.payload ? action.payload : {},
            }).pipe(
                filter((response) => response.data.status === '1'),
                mergeMap((response) => {
                    return from([
                        switchAccountType({
                            targetCustomerUsersCustomersId:
                                response.data.details.customerUsersCustomersID,
                        }),
                        clearAuthMessage(),
                    ]);
                }),

                catchError((error) => {
                    if (isAxiosErrorHandled(error)) {
                        const errorMessage = error?.response?.data.errors;
                        return of(
                            createLinkedAccountError({
                                error: null,
                                error_description: errorMessage,
                                error_uri: null,
                            })
                        );
                    } else {
                        Toast.openToastMessage(
                            'There was an error. Please refresh.',
                            ToastMessageReason.ERROR
                        );
                    }
                    return empty();
                })
            );
        }),
        catchError((err) => {
            Toast.openToastMessage('There was an error. Please refresh.', ToastMessageReason.ERROR);
            return empty();
        })
    );

const refreshAccessTokenEpic = (action$: ActionsObservable<Action>) =>
    action$.pipe(
        ofType(REFRESH_ACCESS_TOKEN),
        switchMap((action) => {
            const resource = loadTokenResource() as LoadedTokenResource;
            return makeRequest<ApiResponse<SignInResponse>>({
                url: endpoints.auth.refreshAccessToken,
                method: 'POST',
                data: {
                    accessToken: resource.accessToken,
                    refreshToken: resource.refreshToken,
                },
            }).pipe(
                filter((response) => response.data.status === '1'),
                map((response) => response.data.details),
                mergeMap((response) => {
                    saveFetchedTokenResource(response);
                    const outputActions = [
                        { type: SET_ACCESS_TOKEN_REFRESHED },
                        getCustomerComponentResources(),
                    ];
                    return outputActions.concat(
                        (action as ReturnType<typeof refreshAccessToken>).meta.onComplete
                    );
                }),
                catchError((error) => {
                    if (isAxiosErrorHandled(error)) {
                        return of(
                            signOut(false),
                            signInError({
                                error: null,
                                error_description: [
                                    {
                                        fieldName: 'Generic',
                                        messageCode: 'There was an error, please sign in again',
                                    },
                                ],
                                error_uri: null,
                            }),
                            { type: SET_ACCESS_TOKEN_REFRESHED }
                        );
                    }
                    return empty();
                })
            );
        })
    );

export const authEpic = combineEpics(
    signInEpic,
    tfaSignInEpic,
    autoLoginEpic,
    checkSignedInEpic,
    switchAccountTypeEpic,
    createLinkedAccountEpic,
    refreshAccessTokenEpic,
    signOutWithBackendCallEpic,
    signOutWithoutBackendCallEpic,
    getUserTFASettingsEpic
);

/* ACTION CREATORS */

export const signIn = (payload: SignInAttempt): Action => {
    return {
        type: SIGN_IN,
        payload,
    };
};

export const tfaSignIn = (payload: TFASignInAttempt): Action => ({
    type: TFA_SIGN_IN,
    payload,
});

export const autoLogin = (payload: AutoLoginAttempt): Action => {
    return {
        type: AUTO_LOGIN,
        payload,
    };
};

// We have two separate sign out actions. signOut is just used to kick the user out
// if trying to access parts of the app without the proper tokens, signOutWithBackendCall
// is used when the user manually signs out.
export const signOut = (withBackendCall: boolean = true): Action => {
    if (withBackendCall) {
        return { type: SIGN_OUT_WITH_CALL_EPIC_ACTION };
    } else {
        return { type: SIGN_OUT_WITHOUT_CALL_EPIC_ACTION };
    }
};

export const signOutWithBackendCall = () => {
    return {
        type: SIGN_OUT_WITH_CALL_EPIC_ACTION,
    };
};

export const checkSignedIn = (): Action => ({
    type: CHECK_SIGNED_IN,
});

const signInSuccess = (payload: SignInSuccess): Action => ({
    type: SIGN_IN_SUCCESS,
    payload,
});

export const signInNeedsTFA = (payload: SignInNeedsTFA): Action => ({
    type: SIGN_IN_NEEDS_TFA,
    payload,
});
export const signInUserSuppressed = (payload: SignInIsSuppressed): Action => ({
    type: SIGN_IN_USER_SUPPRESSED,
    payload,
});
export const signInNeedsToAddTFAToAccount = (payload: SignInNeedsToAddTFAToAccount): Action => ({
    type: SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT,
});
export const signInNeedsEmailVerified = (payload: SignInNeedsEmailVerified): Action => ({
    type: SIGN_IN_NEEDS_EMAIL_VERIFIED,
    payload,
});

export const switchAccountType = (payload: SwitchAccountRequest): Action => ({
    type: SWITCH_ACCOUNT_TYPE,
    payload: payload,
});
const setAccountSwitchComplete = (): Action => ({
    type: SET_ACCOUNT_SWITCH_COMPLETE,
});

export const createLinkedAccount = (payload: CreateBusinessProfileSubmission | null) => ({
    type: CREATE_LINKED_ACCOUNT,
    payload,
});

export const refreshAccessToken = (
    onComplete: { type: string; payload?: any; meta?: any }[] = []
) => ({
    type: REFRESH_ACCESS_TOKEN,
    meta: { onComplete },
});

export const getUserTFASettings = () => ({ type: GET_USER_TFA_SETTING });
export const setUserTFASetting = (payload: TFATypesResponse) => ({
    type: SET_USER_TFA_SETTING,
    payload,
});

export const setAuthStatus = (payload: AuthStatus) => ({ type: SET_AUTH_STATUS, payload });

const signInError = (payload: SignInError): Action => ({
    type: SIGN_IN_ERROR,
    payload,
});
const tfaSignInError = (payload: SignInError): Action => ({
    type: TFA_SIGN_IN_ERROR,
    payload,
});
const createLinkedAccountError = (payload: SignInError): Action => ({
    type: CREATE_LINKED_ACCOUNT_ERROR,
    payload,
});

const showApp = (payload: boolean): Action => ({
    type: SHOW_APP,
    payload,
});

export const clearAuthMessage = (): Action => ({
    type: CLEAR_MESSAGE,
});

export const setAccessTokenRefreshing = (payload: boolean): Action => ({
    type: SET_ACCESS_TOKEN_REFRESHING,
    payload,
});
/* SELECTORS */

export const selectAuthStatus = (app: Store) => app.auth.authStatus;
export const selectSignedIn = createSelector(selectAuthStatus, (status) => status === 'signed_in');
export const selectAuthMessage = (app: Store) => app.auth.message;
export const selectTFAType = (app: Store) => app.auth.TFAType;
export const selectAppVisible = (app: Store) => app.auth.appVisible;
export const selectAccessTokenRefreshing = (app: Store) => app.auth.accessTokenRefreshing;
export const selectAppLoading = (app: Store) => app.auth.appLoading;
export const selectAccountSwitchInProgress = (app: Store) => app.auth.accountSwitchInProgress;
