import { useField, useFormikContext } from 'formik';
import React, { useEffect } from 'react';
import { Dropdown, DropdownSelectProps } from './Dropdown';
import { ErrorM } from './ErrorM';
import { FormFieldTooltip } from './FormFieldTooltip';

type additionalProps = {
    onChange?: (values: any, setFieldValue: (field: string, value: any) => void) => void;
    containerStyle?: React.CSSProperties;
    hideWhenSingleValue?: boolean;
};
export const WrappedFormSingleSelectField = function <T = any>({
    onChange,
    containerStyle,
    hideWhenSingleValue,
    ...props
}: FormSingleSelectFieldProps<T> & additionalProps) {
    const [, formikMeta, formikHelpers] = useField(props.fieldName);
    const { setFieldValue } = useFormikContext();
    const hasError = formikMeta.error;

    useEffect(() => {
        if (
            hideWhenSingleValue &&
            props?.options?.length === 1 &&
            props.options[0].value !== formikMeta.value
        ) {
            formikHelpers.setValue(props.options[0].value);
        }
    }, [formikMeta.value, hideWhenSingleValue, props.options, formikHelpers]);

    useEffect(() => {
        if (onChange) {
            onChange(formikMeta.value, setFieldValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formikMeta.value, setFieldValue]);

    if (hideWhenSingleValue && props?.options?.length === 1) return null;
    return (
        <div
            className={`DropdownContainer ${hasError && formikMeta.touched ? 'ErrorField' : ''}`}
            style={containerStyle}
        >
            <FormSingleSelectField<T> {...props} />
        </div>
    );
};

export type SelectOption = { value: any; label: string };

export interface FormSingleSelectFieldProps<T> {
    fieldName: keyof T & string;
    options: SelectOption[];
    label: string;
    readOnly?: boolean;
    dropdownProps?: DropdownSelectProps;
    tooltip?: string | null;
    labelExtraInfo?: React.ReactNode | null;
    sideBySide?: boolean;
    forceTouchOnChange?: boolean;
    handleOptionSelected?: (
        option: SelectOption | null,
        setFieldValue: (field: string, value: any) => void
    ) => void;
}

const FormSingleSelectField = function <T>(props: FormSingleSelectFieldProps<T>) {
    const { fieldName, label, options, readOnly, dropdownProps, forceTouchOnChange } = props;
    const formik = useFormikContext<T>();
    const [{ value }, { touched, error }, { setTouched }] = useField(fieldName);

    const hasError = !!(touched && error);

    const handleChange = (e: SelectOption | null) => {
        props.handleOptionSelected && props.handleOptionSelected(e, formik.setFieldValue);
        forceTouchOnChange && !touched && !!e && setTouched(true);
        if (!e) formik.setFieldValue(fieldName, e);
        else formik.setFieldValue(fieldName, e.value);
    };

    return (
        <div className={`FormBox  ${props.sideBySide ? 'SideBySide' : ''}`}>
            <div className="FormLabel">
                <label htmlFor={fieldName.toString()}>
                    {label}
                    {props.labelExtraInfo && (
                        <span className="Optional"> - {props.labelExtraInfo}</span>
                    )}
                </label>
            </div>
            <div className="FormField">
                <div
                    className={`SingleSelectDropdownWrapper ${!!props.tooltip ? 'HasToolTip' : ''}`}
                >
                    <Dropdown
                        id={fieldName.toString()}
                        onChange={handleChange}
                        options={options}
                        value={options.find((o) => o.value == value)}
                        readOnly={readOnly}
                        roundedRectangle
                        dropdownProps={dropdownProps}
                        hasError={hasError}
                    />
                    {props.tooltip && <FormFieldTooltip tooltip={<>{props.tooltip}</>} />}
                </div>
                <ErrorM name={fieldName.toString()} />
            </div>
        </div>
    );
};

export { FormSingleSelectField };
