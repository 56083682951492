import { FormField, FormSingleSelectField } from '@avamae/formbuilder';
import { useField } from 'formik';
import { useQueryFetch } from '../../../helpers/useQueryFetch';
import { endpoints } from '../../../endpoints.config';
import { SelectOption } from '@avamae/formbuilder/dist/FormSingleSelectField';

type Props = {
    field: FormField<any>;
};

type OptionsListItem = {
    label: string;
    value: {
        shippingType: string;
        feeAmount: number;
        feeCurrencyCode: string;
    };
};

export const ShippingMethod: React.FC<Props> = ({ field }) => {
    const [{ value: shippingOptions }] = useField<OptionsListItem[]>(field.dataSource as string);
    const [{ value }] = useField<string>(field.name as string);

    const { data } = useQueryFetch<SelectOption[]>(endpoints.cards.accounts, [
        'pay-card-fee-accounts',
    ]);

    const buildLabel = (opt: OptionsListItem) => {
        if (!opt.value.feeAmount) return opt.label;
        else return `${opt.label} - ${opt.value.feeAmount} ${opt.value.feeCurrencyCode}`;
    };

    const options = (shippingOptions ?? []).map((opt) => ({
        label: buildLabel(opt),
        value: opt.value.shippingType,
    }));

    const selectedValue = shippingOptions.find((opt) => opt.value.shippingType === value);
    const hasFee =
        typeof selectedValue?.value.feeAmount === 'number' && selectedValue?.value.feeAmount > 0;

    return (
        <>
            <FormSingleSelectField
                fieldName={field.name as never}
                label={field.label}
                options={options}
            />
            {hasFee && (
                <FormSingleSelectField
                    fieldName="feesCustomerAssetAccountsId"
                    label="Select account to pay fee"
                    options={data?.details ?? []}
                />
            )}
        </>
    );
};
