/** @jsxImportSource @emotion/react */
import { css } from '@emotion/core';
import { useTheme } from '@emotion/react';
import { Link, useLocation } from '@reach/router';
import { DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER } from 'App';
import Button from 'components/button/Button';
import { plaintext } from 'plaintext.config';
import React, { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Icon,
    Icons,
    selectAccountTypePathItem,
    selectComponentResources,
} from 'reducers/componentResources';
import { DashboardPath } from '../../appConstants';
import { selectCultureCode } from '../../reducers/language';
import { AccountList } from './AccountList';
import { partition } from 'lodash';
import { useBasePath } from 'helpers/useBasePath';

//icons
import VisaIcon from 'assets/ibanera/Icon_Card_01.png';
import MCIcon from 'assets/ibanera/Icon_Card_02.png';
import { InfoRequestNavLink } from '../../pages/InfoRequest/InfoRequestNavLink';
import { TextCurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import { BulkTransferSideNavLink } from '../../pages/account/BulkTransfer/BulkTransferSideNavLink';
import { ModalTypes, openModal } from 'reducers/modal';
import { endpoints } from 'endpoints.config';

export type ElementType =
    | 'Accounts'
    | 'Cards'
    | 'Crypto'
    | 'Stablecoin'
    | 'Other'
    | 'QuickCoin'
    | 'FundingAccounts'
    | 'CardManagement'
    | 'CryptoAsAccounts'
    | 'VACustomers';
export interface SideMenuLink {
    id: number;
    displayName: string;
    path: string;
    apiPath?: string;
    productType: 'IFrame' | null;
    resourceKey: string;
    position: string;
    iconName: string;
    childElements: SideMenuLink[];
    elementType?: ElementType;
    accounts?: AccountLink[];
    crypto?: AccountLink[];
    stablecoin?: AccountLink[];
}

export type AccountLink = FiatAccountLink | CryptoAccountLink | CardAccountLink;

export type MergedAccountLink = AccountLink & { elementType?: ElementType };

export type FiatAccountLink = {
    id: number;
    addDate: string;
    balance: number;
    availableBalance: number;
    accountName: React.ReactNode;
    accountNumber: string;
    routingNumber: string;
    currencyName: string;
    currencyCode: string;
    currencySymbol: string;
    childElements: SideMenuLink[];
    reference?: string | null;
    swiftBic?: string;
    productDisplayName?: ProductType;
    productAddress?: string;
    virtualAccountNumber?: string;
    vaName?: string;
    vaAddress?: string;
};

export enum ProductType {
    DBS = 'DBS Bank',
    DBS_TMP = 'DBS',
    CRB = 'Cross River Bank',
    BC = 'Banking Circle',
    GLDB = 'Green Link Digital Bank Pte. Ltd',
}

export type CryptoAccountLink = {
    id: number;
    addDate: string;
    balance: number;
    availableBalance: number;
    code: string;
    symbol: string;
    childElements: SideMenuLink[];
};
export type CardAccountLink = {
    id: number;
    accountName: string;
    childElements: SideMenuLink[];
    cardNumber: string;
    nameOnCard: string;
};

type SideMenuProps = {
    links: SideMenuLink[] | null;
    icons: Icons | null;
};

const SideMenu: React.FC<SideMenuProps> = ({ links, icons }) => {
    const theme = useTheme();
    const cultureCode = useSelector(selectCultureCode);
    const { pathname } = useLocation();
    const SideMenuCSS = css`
        .DashboardLink {
            background: ${theme.colors.first};
            border-left: 5px solid ${theme.colors.third};
        }

        a.Active {
            border-left: 5px solid ${theme.colors.second};
            color: ${theme.colors.second};
        }
        .Link.Active {
            border-left: 5px solid ${theme.colors.second};
            color: ${theme.colors.second};
        }
        .BigAccountName {
            color: ${theme.colors.first};
        }
        ul li a {
            color: ${theme.colors.headingColor};
        }
        ul li .Link {
            color: ${theme.colors.headingColor};
        }
        ul li > h5 {
            color: ${theme.colors.headingColor};
        }
    `;
    //links = links?.filter((link) => link.elementType !== 'Accounts') ?? null; // TESTING ONLY, REMOVE LATER
    const isLinkActive = (link: string) => {
        if (pathname.split('/').length > 5) {
            return trimLastSegment(pathname) === trimLastSegment(link);
        }
        return pathname === link || pathname.startsWith(link + '/');
    };
    const { userInfo } = useSelector(selectComponentResources);
    const accountTypePathItem = useSelector(selectAccountTypePathItem);

    const [allAccountLinks, otherLinks] = useMemo(
        () =>
            partition(
                links,
                (link) =>
                    link.elementType === 'CryptoAsAccounts' ||
                    link.elementType === 'FundingAccounts' ||
                    link.elementType === 'Accounts'
            ),
        [links]
    );
    const [accountLinks, extraAccountLinks] = useMemo(
        () => partition(allAccountLinks, (link) => link.elementType === 'Accounts'),
        [allAccountLinks]
    );
    const canAddAccount = useMemo(
        () => false, //links?.some((link) => link.path === 'accounts/addaccount'), // Hardcode this out for now
        []
    );
    const canAddCard = useMemo(
        () => links?.some((link) => link.path === 'cards/add-card') ?? false, // Hardcode this out for now
        [links]
    );

    return (
        <div className="SideMenu" css={SideMenuCSS}>
            <ul>
                <li>
                    <Link
                        className="DashboardLink NoHover"
                        to={`/${cultureCode}${accountTypePathItem}${DashboardPath.HOME}/${DASHBOARD_MENU_PAGE_SUB_PAGE_PLACEHOLDER}`}
                    >
                        <h3>
                            {userInfo?.firstName} {userInfo?.lastName}
                        </h3>
                        <span>{userInfo?.accountType}</span>
                    </Link>
                </li>
                <li>
                    <InfoRequestNavLink isLinkActive={isLinkActive} />
                </li>
                <li>
                    <BulkTransferSideNavLink isLinkActive={isLinkActive} />
                </li>
                {!!(accountLinks.length || extraAccountLinks.length) && (
                    <li>
                        <AccountLinksSection
                            link={accountLinks[0]}
                            isLinkActive={isLinkActive}
                            cultureCode={cultureCode}
                            extraLinks={extraAccountLinks}
                            canAddAccount={canAddAccount}
                        />
                    </li>
                )}
                {
                    // links?.map(
                    otherLinks
                        ?.filter(
                            (ol) =>
                                ol.path !== 'accounts/addaccount' && ol.path !== 'cards/add-card'
                        )
                        ?.map(
                            (link, i) =>
                                link && (
                                    <React.Fragment key={i}>
                                        <li className="DividingLine" />
                                        <li>
                                            {link.elementType === 'Cards' ? (
                                                <CardLinksSection
                                                    link={link}
                                                    isLinkActive={isLinkActive}
                                                    cultureCode={cultureCode}
                                                    canAddCard={canAddCard}
                                                />
                                            ) : (
                                                <OtherLinkSection
                                                    link={link}
                                                    isLinkActive={isLinkActive}
                                                    cultureCode={cultureCode}
                                                    icon={
                                                        icons ? icons[link.iconName] ?? null : null
                                                    }
                                                />
                                            )}
                                        </li>
                                    </React.Fragment>
                                )
                        )
                    // <li>
                    //     <AccountLinksSection
                    //         accountLinks={accountLinks}
                    //         isLinkActive={isLinkActive}
                    //         cultureCode={cultureCode}
                    //     />
                    // </li>
                }
            </ul>
        </div>
    );
};

export default SideMenu;

export const AccountLinksSection = ({
    link,
    cultureCode,
    isLinkActive,
    extraLinks,
    canAddAccount,
    hideHeading,
}: {
    link: SideMenuLink | undefined;
    cultureCode: string | null;
    isLinkActive: (link: string) => boolean;
    extraLinks?: SideMenuLink[];
    canAddAccount?: boolean;
    hideHeading?: boolean;
}) => {
    const [fundingAccountLinks, otherExtraLinks] = useMemo(
        () => partition(extraLinks ?? [], (link) => link.elementType === 'FundingAccounts'),
        [extraLinks]
    );
    const basePath = useBasePath();
    const mergedLink = useMemo(() => {
        if (!link) return undefined;
        const fundingAccounts = fundingAccountLinks.reduce(
            (prev: MergedAccountLink[], current) =>
                current.accounts
                    ? [
                          ...prev,
                          ...current.accounts.map((account) => ({
                              ...account,
                              elementType: current.elementType,
                          })),
                      ]
                    : prev,
            []
        );
        const mergedAccounts: MergedAccountLink[] = [
            ...fundingAccounts,
            ...(link?.accounts?.map((account) => ({ ...account, elementType: link.elementType })) ??
                []),
        ];

        return { ...link, accounts: mergedAccounts };
    }, [link, fundingAccountLinks]);

    if (!mergedLink) return null;
    return (
        <>
            {!hideHeading && <h5>{mergedLink.displayName}</h5>}
            <AccountList link={mergedLink} cultureCode={cultureCode} isLinkActive={isLinkActive} />
            {otherExtraLinks &&
                otherExtraLinks.map((link) => (
                    <AccountList
                        key={link.id}
                        link={link}
                        cultureCode={cultureCode}
                        isLinkActive={isLinkActive}
                    />
                ))}
            {canAddAccount && (
                <Link to={`${basePath}/accounts/addaccount`} className="ButtonLink">
                    <Button className="SideMenuButton" onClick={() => {}}>
                        {plaintext.sidemenu.addAccount}
                    </Button>
                </Link>
            )}
        </>
    );
};
const CardLinksSection = ({
    link,
    cultureCode,
    isLinkActive,
    canAddCard,
}: {
    link: SideMenuLink | undefined;
    cultureCode: string | null;
    isLinkActive: (link: string) => boolean;
    canAddCard: boolean;
}) => {
    const basePath = useBasePath();
    if (!link) return null;

    return (
        <>
            <h5>{link.displayName}</h5>
            <AccountList
                link={link}
                cultureCode={cultureCode}
                isLinkActive={isLinkActive}
                elementType={link.elementType}
            />
            {canAddCard && (
                <Link to={`${basePath}/cards/addcard`} className="ButtonLink">
                    <Button className="SideMenuButton" onClick={() => {}}>
                        {plaintext.sidemenu.addCard}
                    </Button>
                </Link>
            )}
        </>
    );
};

const OtherLinkSection = ({
    link,
    cultureCode,
    isLinkActive,
    icon,
}: {
    link: SideMenuLink | undefined;
    cultureCode: string | null;
    isLinkActive: (link: string) => boolean;
    icon: Icon | null;
}) => {
    const dispatch = useDispatch();
    const openDepositCryptoModal = () =>
        dispatch(
            openModal({
                modalType: ModalTypes.DEPOSIT_CRYPTO,
                data: { crypto: null, depositEndpoint: endpoints.cryptoExchange.getDepositAddress },
            })
        );
    const basePath = useBasePath();
    if (!link) return null;

    const fullPath = `${basePath}/${link.path}`;
    return (
        <>
            <Link
                className={`SideMenuLink ${isLinkActive(fullPath) ? 'Active' : ''}`}
                to={fullPath}
            >
                {icon?.active ? (
                    <img src={icon[isLinkActive(fullPath) ? 'active' : 'inactive']} alt="" />
                ) : (
                    <TextCurrencyIcon
                        currencySymbol={link.displayName[0]}
                        active={isLinkActive(fullPath)}
                    />
                )}
                <div className="LinkDetails">
                    {link.displayName}
                    {link.elementType === 'QuickCoin' && (
                        <div className="CardIcons">
                            {[VisaIcon, MCIcon].map((icon, i) => (
                                <img key={i} src={icon} alt="Card icon" />
                            ))}
                        </div>
                    )}
                    {link.elementType === 'Crypto' && (
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                openDepositCryptoModal();
                            }}
                            type="button"
                            priority="secondary"
                            className="DepositCryptoButton"
                        >
                            Deposit crypto
                        </Button>
                    )}
                </div>
            </Link>
            <AccountList link={link} cultureCode={cultureCode} isLinkActive={isLinkActive} />
        </>
    );
};

export const trimLastSegment = (url: string): string => {
    const segments = url.split('/');
    return segments.slice(0, -1).join('/');
};
