/**
 * Implementation based on: https://medium.com/swlh/creating-a-simple-real-time-chat-with-net-core-reactjs-and-signalr-6367dcadd2c6
 */

import { HttpError, HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import { reqHandler } from 'api';
import { loadTokenResource } from 'helpers/auth';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectAccessTokenRefreshing } from 'reducers/auth';
import { SignalRNotificationMessage } from './models';

enum MessageType {
    NOTIFICATION = 'NotificationStackPush',
}

export type MessageAndHandler<T> = { messageCode: string; handler: (messageData: T) => void };

// Make T a union type if there are multiple additionalMessageHandlers with different
// message data shapes.
type Options<T> = {
    url: string;
    // By default hook listens for NotificationStackPush messages. Additional
    // message types and handlers can be passed in via this option.
    additionalMessageHandlers?: MessageAndHandler<T>[];
    // Optional flag that can be toggled to start and stop the connection.
    activate?: boolean;
};

type SignalRConnection = {
    hubConnection: HubConnection;
    isConnected: boolean;
    isConnecting: boolean;
};

export const useSignalRNotifications = <T = any>({
    url,
    activate = true,
    additionalMessageHandlers,
}: Options<T>) => {
    const [connection, setConnection] = useState<SignalRConnection | null>(null);
    const [latestMessage, setLatestMessage] = useState<SignalRNotificationMessage | null>(null);
    const accessTokenRefreshing = useSelector(selectAccessTokenRefreshing);

    const clearLatestMessage = useCallback(() => {
        setLatestMessage(null);
    }, []);

    const createConnection = useCallback(() => {
        const newConnection = new HubConnectionBuilder()
            .withUrl(url, {
                accessTokenFactory: () => {
                    const tokenResource = loadTokenResource();
                    return tokenResource?.accessToken ?? '';
                },
            })
            .withAutomaticReconnect()
            .build();
        setConnection({
            hubConnection: newConnection,
            isConnected: false,
            isConnecting: false,
        });
    }, [url]);

    const clearConnection = useCallback((connection: HubConnection) => {
        connection.stop();
        setConnection(null);
    }, []);

    const startConnection = useCallback(
        (connection: HubConnection) => {
            connection
                .start()
                .then(() => {
                    setConnection((prev) => ({ ...prev!, isConnecting: false, isConnected: true }));

                    connection.on(MessageType.NOTIFICATION, (data: SignalRNotificationMessage) => {
                        setLatestMessage(data);
                    });

                    additionalMessageHandlers?.forEach((type) => {
                        connection.on(type.messageCode, (data: T) => {
                            type.handler(data);
                        });
                    });
                })
                .catch((err: HttpError) => {
                    if (err.statusCode === 401) {
                        reqHandler.addToQueue((token: string) => {
                            clearConnection(connection);
                        });
                    }
                });
        },
        [additionalMessageHandlers, clearConnection]
    );

    useEffect(() => {
        if (!connection && activate) {
            createConnection();
        }
    }, [createConnection, connection, activate]);

    useEffect(() => {
        if (connection?.hubConnection && !connection?.isConnecting && !connection?.isConnected) {
            setConnection((prev) => ({ ...prev!, isConnecting: true }));
            startConnection(connection.hubConnection);
        }
    }, [startConnection, connection]);

    useEffect(() => {
        if ((!activate || accessTokenRefreshing) && connection?.isConnected) {
            clearConnection(connection.hubConnection);
        }
    }, [
        activate,
        accessTokenRefreshing,
        connection?.isConnected,
        connection?.hubConnection,
        clearConnection,
    ]);

    return [latestMessage, clearLatestMessage] as const;
};
