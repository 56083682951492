import React, { RefObject } from "react";

export const useDetectOverflow = (
  dependencies: any[] = [],
  overflowDirection: "width" | "height" = "width"
): { ref: (ref: HTMLElement | null) => void; hasOverflow: boolean } => {
  const [hasOverflow, setHasOverflow] = React.useState(false);
  const [ref, setRef] = React.useState<HTMLElement | null>(null);

  const currentOverflowState = (element: HTMLElement): boolean => {
    if (overflowDirection === "width") {
      if (element.scrollWidth > element.clientWidth) {
        return true;
      } else if (element.offsetWidth === 0) {
        return true;
      }
    }

    if (overflowDirection === "height") {
      if (element.scrollHeight > element.clientHeight) {
        return true;
      } else if (element.offsetHeight === 0) {
        return true;
      }
    }

    return false;
  };

  React.useEffect(() => {
    const handleResize = () => {
      const element = ref;
      if (element) {
        setHasOverflow(currentOverflowState(element));
      }
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ref, dependencies.map(x => String(x)).join()]);

  return { ref: setRef, hasOverflow };
};
