import { Theme } from '@emotion/react';
import { formatPriceWithLocale } from '../../helpers/formatPriceWithCommas';
import { localizedFormatDate } from '../../helpers/language';
import { NameWithInitial } from '../InfoRequest/InfoRequest';
import { Data } from './VACustomerTransactions';
import { NumberAndTickerCell } from '../../components/flexTable/CustomCells';
import { useWindowDimensions } from '../../helpers/useWindowDimensions';
import { Dispatch, SetStateAction, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalTypes, openModal, selectModalState } from '../../reducers/modal';
import { endpoints } from '../../endpoints.config';
import * as Yup from 'yup';

import { ReactComponent as IconMoneyIn } from 'assets/ui-update/arrow-01.svg';
import { ReactComponent as IconMoneyOut } from 'assets/ui-update/arrow-02.svg';
import { convertLocaleDateToUtcDate } from '../../helpers/convertLocaleDateToUtcDate';
import { VACustomerCreateBusiness, VACustomerCreatePersonal } from './models';

export const checkIsPending = (row?: Data) =>
    row?.transactionAmounts__ApprovalStatus !== 'Approval' &&
    !!(
        row?.customerAssetAccountsTransactions__bPending ||
        row?.customerAssetAccountsTransactions__bReserved ||
        row?.customerAssetAccountsTransactions__bFrozen
    );

export const rowFormatter = (row: Data, colors: Theme['colors'], cultureCode: string | null) => {
    let formattedRow: { [K in keyof typeof row]: React.ReactNode } = { ...row };
    if (formattedRow.customerAssetAccountsTransactions__Date) {
        formattedRow = {
            ...formattedRow,
            customerAssetAccountsTransactions__Date: localizedFormatDate(
                new Date(row.customerAssetAccountsTransactions__Date),
                cultureCode || undefined,
                'dd - MM - yyyy'
            ),
        };
    }
    if (formattedRow.transactionAmounts__Balance) {
        formattedRow = {
            ...formattedRow,
            transactionAmounts__Balance: row.assets__Symbol ? (
                row.assets__Symbol +
                formatPriceWithLocale(row.transactionAmounts__Balance, cultureCode ?? 'en-GB')
            ) : (
                <NumberAndTickerCell
                    number={row.transactionAmounts__Balance}
                    ticker={row.assets__Code}
                    plainColor
                />
            ),
        };
    }
    if (formattedRow.customerAssetAccountsTransactions__Amount) {
        formattedRow = {
            ...formattedRow,
            customerAssetAccountsTransactions__Amount: row.assets__Symbol ? (
                <span className={row.transactionAmounts__bInTransaction ? 'MoneyIn' : 'MoneyOut'}>
                    {row.assets__Symbol +
                        formatPriceWithLocale(
                            row.customerAssetAccountsTransactions__Amount,
                            cultureCode ?? 'en-GB'
                        )}
                </span>
            ) : (
                <NumberAndTickerCell
                    number={row.customerAssetAccountsTransactions__Amount}
                    ticker={row.assets__Code}
                    plainColor
                />
            ),
        };
    }
    if (formattedRow.customerAssetAccountsExternalTransfers__Name != null) {
        const desc = row.customerAssetAccountsExternalTransfers__Name;
        formattedRow = {
            ...formattedRow,
            customerAssetAccountsExternalTransfers__Name: (
                <NameWithInitial name={desc} moneyIn={row.transactionAmounts__bInTransaction} />
            ),
        };
    }
    if (typeof formattedRow.transactionAmounts__bInTransaction === 'boolean') {
        const showPending = checkIsPending(row);
        const showApprovalStatus =
            !showPending &&
            row.transactionAmounts__ApprovalStatus &&
            row.transactionAmounts__ApprovalStatus !== 'Approved';

        formattedRow = {
            ...formattedRow,
            transactionAmounts__bInTransaction: row.transactionAmounts__bInTransaction ? (
                <div className="OrderType">
                    <IconMoneyIn width={20} />
                    {showPending && <span className="Pending Pending--in">Pending</span>}
                    {showApprovalStatus && (
                        <span className="Pending Pending--in">
                            {row.transactionAmounts__ApprovalStatus}
                        </span>
                    )}
                </div>
            ) : (
                <div className="OrderType">
                    <IconMoneyOut width={20} fill={colors.third} />
                    {showPending && <span className="Pending Pending--out">Pending</span>}
                    {showApprovalStatus && (
                        <span className="Pending Pending--out">
                            {row.transactionAmounts__ApprovalStatus}
                        </span>
                    )}
                </div>
            ),
        };
    }

    return formattedRow;
};
export const useHandleSidePanelInModal = (
    selectedRow: number | undefined,
    selectedData: Data | undefined,
    setSelectedRow: Dispatch<SetStateAction<number | undefined>>
) => {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const { modalType } = useSelector(selectModalState);

    useEffect(() => {
        if (modalType === ModalTypes.TRANSACTION_DETAILS && width > 1400) {
            dispatch(closeModal());
            return;
        } else if (width >= 1400 || !selectedRow || !selectedData) return;
        else
            dispatch(
                openModal({
                    modalType: ModalTypes.TRANSACTION_DETAILS,
                    data: {
                        rowData: {
                            id: selectedData.customerAssetAccountsTransactions__Id,
                            amount: selectedData.customerAssetAccountsTransactions__Amount,
                            moneyIn: selectedData.transactionAmounts__bInTransaction,
                            reference:
                                selectedData.customerAssetAccountsTransactions__CustomerAssetAccountsTransactionsCode,
                            isPending: checkIsPending(selectedData),
                            approvalStatus: selectedData.transactionAmounts__ApprovalStatus,
                            bCancellable: false,
                            bEligibleForReverse: false,
                            currencyCode: selectedData.assets__Code,
                        },
                        currencySymbol: selectedData.assets__Symbol,
                        disableEdit: true,
                        endpoint: endpoints.vaCustomers.transactionDetails,
                    },
                })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow, width]);

    useEffect(() => {
        if (modalType) return;
        else setSelectedRow(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalType]);
};

export const validationSchemaPersonalCustomer = Yup.object({
    firstName: Yup.string().required('Please provide a first name').nullable(),
    lastName: Yup.string().required('Please provide a last name').nullable(),
    birthDate: Yup.string().required('Please provide a birthdate').nullable(),
    phoneNumber: Yup.string().required('Please provide a phone number').nullable(),
    emailAddress: Yup.string()
        .required('An email address is required')
        .email('Must be a valid email address')
        .nullable(),
    taxID: Yup.string().nullable(),
    addressLine1: Yup.string().required('Please provide the first line of your address').nullable(),
    addressLine2: Yup.string().nullable(),
    townCity: Yup.string().required('Please provide your town / city').nullable(),
    state: Yup.string().required('Please provide your state / province').nullable(),
    postcode: Yup.string().required('Please provide your post code').nullable(),
    addressCountryId: Yup.string().required('Please select your country').nullable(),

    idDocumentNumber: Yup.string().required('Please provide your document number').nullable(),
    idType: Yup.string().required('Please select the type of Id').nullable(),
    idCountryOfIssueId: Yup.string().required('Please select your id country of issue').nullable(),
    idExpiryDate: Yup.string().required('Please provide your id expiry').nullable(),
    idFile: Yup.string().required('Please upload your id').nullable(),

    nationalityId: Yup.string().required('Please select your nationality').nullable(),

    proofOfAddressType: Yup.string()
        .required('Please select your proof of address type')
        .nullable(),
    proofOfAddressFile: Yup.string().required('Please upload your proof of address').nullable(),
});
export const validationSchemaBusinessCustomer = Yup.object({
    lei: Yup.string().nullable(),
    companyName: Yup.string().required('Please provide a company name').nullable(),
    dateOfIncorporation: Yup.string().required('Please provide a date of incorporation').nullable(),
    firstName: Yup.string().required('Please provide a first name').nullable(),
    lastName: Yup.string().required('Please provide a last name').nullable(),
    phoneNumber: Yup.string().required('Please provide a phone number').nullable(),
    emailAddress: Yup.string()
        .required('An email address is required')
        .email('Must be a valid email address')
        .nullable(),
    taxID: Yup.string().nullable(),
    addressLine1: Yup.string().required('Please provide the first line of your address').nullable(),
    addressLine2: Yup.string().nullable(),
    townCity: Yup.string().required('Please provide your town / city').nullable(),
    state: Yup.string().required('Please provide your state / province').nullable(),
    postcode: Yup.string().required('Please provide your post code').nullable(),
    addressCountryId: Yup.string().required('Please select your country').nullable(),

    bRegisteredAddressIsPPB: Yup.boolean().required('Required'),
    operatingAddressLine1: Yup.string()
        .nullable()
        .when('bRegisteredAddressIsPPB', {
            is: false,
            then: (schema) => schema.required('Please provide the first line of your address'),
        }),
    operatingAddressLine2: Yup.string().nullable(),
    operatingTownCity: Yup.string()
        .nullable()
        .when('bRegisteredAddressIsPPB', {
            is: false,
            then: (schema) => schema.required('Please provide your town / city'),
        }),
    operatingState: Yup.string()
        .nullable()
        .when('bRegisteredAddressIsPPB', {
            is: false,
            then: (schema) => schema.required('Please provide your state / province'),
        }),
    operatingPostcode: Yup.string()
        .nullable()
        .when('bRegisteredAddressIsPPB', {
            is: false,
            then: (schema) => schema.required('Please provide your post code'),
        }),
    operatingCountryId: Yup.string()
        .nullable()
        .when('bRegisteredAddressIsPPB', {
            is: false,
            then: (schema) => schema.required('Please select your country'),
        }),

    entityTypeId: Yup.string().nullable(),

    certificateOfIncorporationFile: Yup.string()
        .required('Please upload your certificate')
        .nullable(),
})
    .test('at-least-one', 'error', function (value) {
        const { lei, taxID } = value;
        if (!lei && !taxID) {
            return this.createError({
                path: 'lei',
                message: 'At least one of LEI or Tax Id must be provided',
            });
        }
        return true;
    })
    .test('at-least-one', 'error', function (value) {
        const { lei, taxID } = value;
        if (!lei && !taxID) {
            return this.createError({
                path: 'taxID',
                message: 'At least one of LEI or Tax Id must be provided',
            });
        }
        return true;
    });
export const validationSchemaTransactions = Yup.object({
    vACustomerAccountId: Yup.number().required('Please select an account').nullable(),
});

export const formatPersonalFields = (values: VACustomerCreatePersonal) => {
    const newValues = { ...values };
    const { birthDate, idExpiryDate } = values;
    const formattedBirthDate = convertLocaleDateToUtcDate(birthDate);
    const formattedIdDate = convertLocaleDateToUtcDate(idExpiryDate);

    newValues.birthDate = formattedBirthDate?.toISOString() ?? '';
    newValues.idExpiryDate = formattedIdDate?.toISOString() ?? '';

    return newValues;
};
export const formatBusinessFields = (values: VACustomerCreateBusiness) => {
    const newValues = { ...values };
    const { dateOfIncorporation } = values;
    const formattedDateOfIncorporation = convertLocaleDateToUtcDate(dateOfIncorporation);

    newValues.dateOfIncorporation = formattedDateOfIncorporation?.toISOString() ?? '';

    return newValues;
};
