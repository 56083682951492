import { FormikTouched, setNestedObjectValues, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';

export const useTouchErrorFormikFields = (touchWithoutErrors = false, deps = [] as any[]) => {
    const { validateForm, setTouched } = useFormikContext();
    const [runOnce, setRunOnce] = useState(0);

    useEffect(() => {
        if (runOnce) return;
        (async () => {
            setRunOnce(1);
            const errors = await validateForm();
            if (Object.keys(errors).length === 0 && !touchWithoutErrors) {
                // Do nothing! No need to touch anything no errors to surface
            } else {
                setTouched(setNestedObjectValues<FormikTouched<any>>(errors, true), false);
            }
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};
