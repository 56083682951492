import { formatGeneralCurrency } from '../../../components/modals/CryptoExchangeModal/FromTo';
import { dashIfPresent } from './helpers';
import { ParsedCsv } from './models';
import { isDbsOrBc } from './validationSchema';

import { ReactComponent as DeleteIcon } from 'assets/ui-update/delete.svg';
import { ReactComponent as ExpandIcon } from 'assets/ui-update/search.svg';
import { ReactComponent as CollapsedIcon } from 'assets/ui-update/dash.svg';
import { ReactComponent as EditIcon } from 'assets/ui-update/edit-pen.svg';
import { ProductType } from '../../../components/sideMenu/SideMenu';

type HeaderProps = ParsedCsv & {
    currencySymbol?: string;
    currencyCode?: string;
    sourceAccountName?: string;
    handleDeleteTransfer(): void;
    handleEditTransfer(): void;
};
export const BulkTransferItemHeader: React.FC<HeaderProps> = ({
    name,
    purpose,
    paymentReference,
    productType,
    accountNumber,
    swiftNumber,
    countryCode,
    routingNumber,
    iban,
    transferType,
    currencySymbol,
    currencyCode,
    sourceAccountName,
    amount,
    handleDeleteTransfer,
    handleEditTransfer,
    status,
}) => {
    const bIsDbsOrBc = productType ? isDbsOrBc(productType) : false;
    const bIsCRB = productType === ProductType.CRB;
    const bIsGLDB = productType === ProductType.GLDB;

    return (
        <div className="BulkTransferItemHeader">
            <div className="HeaderInfo">
                <div className="LeftSection">
                    <div className="PayeeName">{name ?? '-'}</div>
                    <div className="HeaderInfo">{dashIfPresent(purpose, paymentReference)}</div>
                    <div className="HeaderInfo">
                        {bIsDbsOrBc && dashIfPresent(accountNumber, swiftNumber)}
                        {bIsCRB &&
                            countryCode === 'USA' &&
                            dashIfPresent(routingNumber, accountNumber)}
                        {((bIsCRB && countryCode !== 'USA') || bIsGLDB) &&
                            dashIfPresent(swiftNumber, iban)}
                    </div>
                </div>

                {status && <div className={'StatusPill ' + status}>{status}</div>}

                <div
                    className={`TransferType TransferType--${
                        transferType ? transferType.toUpperCase() : 'NONE'
                    }`}
                >
                    {transferType ?? '-'}
                </div>
                <div className="AmountWrapper">
                    <div className="Amount">
                        {currencySymbol}
                        {typeof amount === 'number' &&
                            formatGeneralCurrency(amount, currencyCode ?? '', true, {
                                minimumFractionDigits: 2,
                            })}
                        {typeof amount !== 'number' && '0.00'}
                    </div>
                    <div className="HeaderInfo SourceAccount">{sourceAccountName}</div>
                </div>
            </div>

            {(!status || status === 'Error') && (
                <div className="Icons">
                    <ExpandIcon className="ExpandIcon" />
                    <CollapsedIcon className="CollapsedIcon" />
                    <button
                        type="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleEditTransfer();
                        }}
                        className="EditIconButton"
                        title="Edit transfer"
                    >
                        <EditIcon className="EditIcon" />
                    </button>
                    <button
                        type="button"
                        onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteTransfer();
                        }}
                        className="DeleteIconButton"
                        title="Delete transfer"
                    >
                        <DeleteIcon className="DeleteIcon" />
                    </button>
                </div>
            )}

            <div className="MobileInfo">
                <div className="AmountWrapper">
                    <div className="Amount">
                        {currencySymbol}
                        {typeof amount === 'number' &&
                            formatGeneralCurrency(amount, currencyCode ?? '', true)}
                        {typeof amount !== 'number' && '0.00'}
                    </div>
                    <div className="HeaderInfo SourceAccount">{sourceAccountName}</div>
                </div>

                <div className={`TransferType TransferType--${transferType?.toUpperCase()}`}>
                    {transferType ?? '-'}
                </div>
            </div>
        </div>
    );
};
