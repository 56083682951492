import React, { useState, useEffect, useMemo } from 'react';
import PersonalImage from '../../assets/ibanera/IbaneraBounce-White.gif';
import BusinessImage from '../../assets/ibanera/IbaneraBounce.gif';
import Button from '../../components/button/Button';
import { AccountType, RegistrationAdditionalInfo, SelectAccountTypeProps } from './models';
import { EitherOrSelection } from 'components/eitherOrSelection/EitherOrSelection';
import { useRegistrationContext } from 'helpers/registration';
import { Form, Formik } from 'formik';
import { RadioButtons } from 'components/radiobuttons/radiobuttons';
import { SelectOption, WrappedFormSingleSelectField } from 'components/form/FormSingleSelectField';
import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { Toast } from 'helpers/toast';
import { FormMultiSelectField } from 'components/form/FormMultiSelectField';
import * as Yup from 'yup';
import { ErrorM } from '../../components/form/ErrorM';
import { useSelector } from 'react-redux';
import { selectErrorCodes } from '../../reducers/errorCodes';

type CountryItem = {
    name: string;
    isoCode: string;
    id: number;
    personalErrorCode?: string | null;
    businessErrorCode?: string | null;
};

type DropdownOptionsResponse = {
    countries?: CountryItem[];
    productServices?: {
        name: string;
        id: number;
    }[];
};

export const SelectAccountType: React.FC<SelectAccountTypeProps> = ({
    translations,
    onComplete,
    disablePersonalRegistration,
}) => {
    const { setRegistrationAdditionalInfo, registrationAdditionalInfo } = useRegistrationContext();
    const errorCodes = useSelector(selectErrorCodes);

    const initialValues: RegistrationAdditionalInfo = registrationAdditionalInfo || {
        accountType: disablePersonalRegistration ? AccountType.BUSINESS : null,
        bConductsThirdPartyPayments: null,
        requestedProducts: null,
        registeredCountryCode: null,
    };

    const [productServices, setProductServices] = useState<SelectOption[]>([]);
    const [countries, setCountries] = useState<CountryItem[]>([]);

    const countryOptions = useMemo(
        () =>
            countries.map((country) => ({
                label: country.name,
                value: country.isoCode,
            })),
        [countries]
    );

    useEffect(() => {
        instance
            .get<ApiResponse<DropdownOptionsResponse>>(
                endpoints.registration.businessRegistrationDetailsV2
            )
            .then((res) => {
                if (res.data.status === '1') {
                    setCountries(res.data.details.countries ?? []);
                    setProductServices(
                        res.data.details.productServices?.map((service) => ({
                            label: service.name,
                            value: service.id,
                        })) ?? []
                    );
                }
            })
            .catch(() => Toast.openGenericErrorToast());
    }, []);

    const handleSubmit = (values: RegistrationAdditionalInfo) => {
        setRegistrationAdditionalInfo(values);
        onComplete();
    };

    const validationSchema = Yup.object().shape({
        accountType: Yup.string().required('Required').nullable(),
        bConductsThirdPartyPayments: Yup.boolean().required('Required').nullable(),
        requestedProducts: Yup.array()
            .of(Yup.number())
            .min(1, 'Required')
            .required('Required')
            .nullable(),
        registeredCountryCode: Yup.string()
            .required('Required')
            .nullable()
            .test('hasCountryError', 'countryError', function (val) {
                if (!val) return true;

                const accountType: AccountType | null = this.parent.accountType;
                if (!accountType) return true;

                const isBusiness = accountType === AccountType.BUSINESS;

                const selectedCountry = countries.find((cou) => cou.isoCode === val)!;

                const personalErrorCode = selectedCountry.personalErrorCode;
                const businessErrorCode = selectedCountry.businessErrorCode;
                if (isBusiness && businessErrorCode) {
                    const msg = errorCodes[businessErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                } else if (!isBusiness && personalErrorCode) {
                    const msg = errorCodes[personalErrorCode] || null;
                    return msg
                        ? this.createError({
                              path: this.path,
                              message: msg,
                          })
                        : true;
                }

                return true;
            }),
    });

    return (
        <>
            {translations && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                >
                    {({ values, touched, setFieldValue, setFieldTouched }) => (
                        <Form>
                            <h1>Welcome to Registration</h1>
                            {!disablePersonalRegistration ? (
                                <>
                                    <p>Create an account for either personal or business use.</p>
                                    <div className="FormLabel">
                                        <label>What type of account are you requesting?</label>
                                    </div>
                                    <div className="SelectAccountType">
                                        <EitherOrSelection
                                            title="Business"
                                            selected={values.accountType === AccountType.BUSINESS}
                                            onClick={() =>
                                                setFieldValue('accountType', AccountType.BUSINESS)
                                            }
                                            image={BusinessImage}
                                            value={AccountType.BUSINESS}
                                            subText="Registered Entitites or Corporations"
                                        />

                                        <EitherOrSelection
                                            title="Personal"
                                            selected={values.accountType === AccountType.PERSONAL}
                                            onClick={() =>
                                                setFieldValue('accountType', AccountType.PERSONAL)
                                            }
                                            image={PersonalImage}
                                            value={AccountType.PERSONAL}
                                            subText="Individual Users and Personal Finances"
                                        />
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="FormLabel">
                                        <label>What type of account are you requesting?</label>
                                    </div>
                                    <div className="SelectAccountType">
                                        <EitherOrSelection
                                            title="Business"
                                            selected={values.accountType === AccountType.BUSINESS}
                                            onClick={() =>
                                                setFieldValue('accountType', AccountType.BUSINESS)
                                            }
                                            image={BusinessImage}
                                            value={AccountType.BUSINESS}
                                            subText="Registered Entitites or Corporations"
                                        />
                                    </div>
                                </>
                            )}
                            <ErrorM name="accountType" />
                            <div style={{ marginBottom: 20 }} />
                            <RadioButtons
                                label="Will you be conducting third party payments? (Money movement on behalf of others.)"
                                options={['Yes', 'No']}
                                booleanField
                                fieldname={'bConductsThirdPartyPayments'}
                            />
                            <FormMultiSelectField
                                fieldName={'requestedProducts'}
                                options={productServices}
                                label={
                                    'What product or service are you looking to open the account for?'
                                }
                            />
                            <WrappedFormSingleSelectField
                                fieldName={'registeredCountryCode'}
                                options={countryOptions}
                                onChange={(val) => {
                                    !!val &&
                                        !touched.registeredCountryCode &&
                                        setFieldTouched('registeredCountryCode');
                                }}
                                label={
                                    'What is the country of the residential address of the individual or the country of registration if this is for a business'
                                }
                            />
                            <Button
                                priority="primary"
                                variety="full"
                                className="RegisterFormButton"
                                type="submit"
                                // style={{ cursor: 'not-allowed' }}
                            >
                                {translations.continue_button}
                            </Button>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    );
};
