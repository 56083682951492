import { useMemo } from 'react';
import { ApiResponse, useFetch } from '../../../api';
import {
    AccountPayee,
    BulkTransferOptions,
    CsvCellConfig,
    CsvData,
    ParsedCsv,
    TransmitterFieldNames,
} from './models';
import { endpoints } from '../../../endpoints.config';
import {
    parseAccountType,
    parseAmount,
    parseCountry,
    parseDate,
    parseSourceAccount,
    parseTransferType,
    parseTransmitterType,
    parseYesNoField,
} from './csvDataParsers';
import { getFeeLabel } from '../Payees/MakePayment/AmountPurpose';
import {
    fetchPurposeOptions,
    fetchTransferOptions,
    PURPOSE_OPTIONS_KEY,
    TRANSFER_OPTIONS_KEY,
} from './parseCsvResults';
import { useIsFinancialInstitution } from '../Payees/helpers';
import { StateOptionResponse } from '../Payees/MakePayment/MakePayment';
import { useQuery } from '@tanstack/react-query';

const accountFields = ['sourceAccountId'];
export const transmitterFieldsKeep: TransmitterFieldNames[] = [
    'transmitter.firstPartyTransfer',
    'transmitter.type',
    'transmitter.name',
    'transmitter.dateOfBirth',
    'transmitter.accountNumber',
    'transmitter.addressLine1',
    'transmitter.addressLine2',
    'transmitter.townCity',
    'transmitter.state',
    'transmitter.postcode',
    'transmitter.countryCode',
];
export const transmitterFieldsHide = ['memo'];

export const useCreateEmptyTemplate = (
    hideAccountColumns?: boolean
): CsvData<CsvCellConfig['label'][]> => {
    const bFinancialInstitution = useIsFinancialInstitution();

    const financialInstitutionFieldCheck = (field: CsvCellConfig) => {
        if (bFinancialInstitution) return !transmitterFieldsHide.includes(field.fieldValue);

        return !transmitterFieldsKeep.includes(field.fieldValue as any);
    };

    /* If an account id is given it means they're on an account when choosing bulk transfer.
    Therefore we'll apply the account details afterwards and not let user choose the account
    in spreadsheet  */
    return {
        fields: csvCellConfig
            .filter(
                (field) =>
                    (!hideAccountColumns || !accountFields.includes(field.fieldValue)) &&
                    financialInstitutionFieldCheck(field)
            )
            .map((field) => field.label),
        data: [],
    };
};

export const useFetchBulkConfig = (accountId?: number) => {
    const { data, loading, error, reload } = useFetch<ApiResponse<BulkTransferOptions>>(
        endpoints.accounts.bulkTransfer
    );

    const countries = useMemo(() => data?.details.countries ?? [], [data]);
    const purposes = useMemo(() => data?.details.purposeCodes ?? [], [data]);
    const accounts = useMemo(
        () =>
            (data?.details.accounts ?? []).filter((acc) => !accountId || acc.id === accountId) ??
            [],
        [data, accountId]
    );

    return { countries, purposes, accounts, loading, hasError: !data && !loading && error, reload };
};

export const dashIfPresent = (str1?: string, str2?: string) =>
    `${str1 ?? ''}${str1 && str2 ? ' - ' : ''}${str2 ?? ''}`;

export const matchPayee = (
    payee?: number | string | symbol,
    account?: BulkTransferOptions['accounts'][0]
) => {
    if (!account || !payee || typeof payee === 'symbol') return null;
    const match = (account.payees ?? []).find((accountPayee) => {
        const idMatch = accountPayee.id.toString() === payee.toString();
        if (idMatch) return true;

        const nameMatch =
            accountPayee.name.toLowerCase().trim() === payee.toString().toLowerCase().trim();
        if (nameMatch) return true;

        return false;
    });

    return match || null;
};
export const matchTransferType = (
    transferTypeField: string | undefined,
    transferTypes: AccountPayee['transferTypes']
) => {
    const nameMatch = transferTypes.find(
        (opt) =>
            opt.transferType.toLowerCase().trim() ===
            transferTypeField?.toString().toLowerCase().trim()
    );

    return nameMatch || null;
};
export const matchFeeOption = (
    feeField: string,
    feeOptions: AccountPayee['transferTypes'][0]['feeOptions']
) => {
    if (!feeOptions || feeOptions.length === 0) return undefined;

    const formattedOpts = feeOptions.map((opt) => ({
        id: opt.bankFeeSplitTypesId,
        splitType: opt.bankFeeSplitType,
        label: getFeeLabel(opt.bankFeeSplitType).label,
    }));

    const match = formattedOpts.find((opt) => {
        const idMatch = opt.id?.toString() === feeField?.toString()?.trim();
        if (idMatch) return true;

        const splitTypeMatch = opt.splitType?.toString() === feeField?.toString()?.trim();
        if (splitTypeMatch) return true;

        const labelMatch = opt.label
            .toString()
            .toLowerCase()
            .includes(feeField.toString().toLowerCase().trim());
        if (labelMatch) return true;

        return false;
    });

    return match?.splitType || match?.splitType === null ? match?.splitType : undefined;
};
export const getFeeFromAccountLevel = (
    payeeId: number | null,
    transferType: string | null,
    feeId: string | null,
    account: BulkTransferOptions['accounts'][0]
): AccountPayee['transferTypes'][0]['feeOptions'][0] | null => {
    if (!account) return null;

    // Existing payee check
    if (!payeeId) return null;

    // Existing payee fee calculations
    const payee = matchPayee(payeeId, account);
    if (!payee) return null;

    return getFeeFromTransferType(transferType, feeId, payee.transferTypes);
};

export const getFeeFromTransferType = (
    transferType: string | null,
    feeId: string | null,
    transferTypes?: AccountPayee['transferTypes']
) => {
    if (!transferTypes) return null;
    const transferTypeObj = matchTransferType(transferType ?? '', transferTypes);

    if (!transferTypeObj) return null;

    const fee = transferTypeObj.feeOptions?.find((fee) => fee.bankFeeSplitType === feeId);

    return fee ?? null;
};
export const matchAccount = (
    accountField: string | number | undefined,
    accounts: BulkTransferOptions['accounts']
): BulkTransferOptions['accounts'][0] | null => {
    if (!accountField || !accounts) return null;
    const match = (accounts ?? []).find((acc) => {
        const idMatch = acc.id.toString() === accountField.toString();
        if (idMatch) return true;

        const nameMatch = acc.accountName
            .toLowerCase()
            .includes(accountField.toString().toLowerCase());
        if (nameMatch) return true;

        return false;
    });

    return match || null;
};
export const findState = (
    stateField: string | number | undefined,
    country: BulkTransferOptions['countries'][0]
): StateOptionResponse | null => {
    if (!stateField || !country || !country.states) return null;
    const match = (country.states ?? []).find((c) => {
        const codeMatch =
            c.isoCode.toString().toLowerCase() === stateField.toString().toLowerCase();
        if (codeMatch) return true;

        const nameMatch = c.name.toLowerCase().includes(stateField.toString().toLowerCase());
        if (nameMatch) return true;

        return false;
    });

    return match || null;
};
export const matchState = (
    stateField: string | number | undefined,
    country: BulkTransferOptions['countries'][0]
): string | null => {
    if (!stateField || !country || !country.states) return null;
    const match = findState(stateField, country);

    return match?.isoCode || null;
};
export const matchCountry = (
    countryField: string | number | undefined,
    countries: BulkTransferOptions['countries']
) => {
    if (!countryField) return null;
    const match = countries.find((country) => {
        const codeMatch =
            country.countryISO3.toUpperCase() === countryField.toString().trim().toUpperCase();
        if (codeMatch) return true;

        const nameMatch = country.name.toLowerCase() === countryField.toString().toLowerCase();
        if (nameMatch) return true;

        return false;
    });

    return match || null;
};
export const matchToPurpose = (
    purposeField: string | undefined,
    purposes: BulkTransferOptions['purposeCodes']
) => {
    if (!purposeField) return null;

    const match = (purposes ?? []).find((purpose) => {
        const codeMatch =
            purpose.code.toString().toLowerCase() === purposeField.toString().toLowerCase();
        if (codeMatch) return true;

        const stringMatch =
            purpose.description.toLowerCase() === purposeField.toString().toLowerCase().trim();
        if (stringMatch) return true;

        return false;
    });

    return match || null;
};
export const parseTransferInfoForDisplay = (
    transfer: ParsedCsv,
    accounts: BulkTransferOptions['accounts'],
    countries: BulkTransferOptions['countries'],
    bFinancialInstitution: boolean
): ParsedCsv => {
    const account = matchAccount(transfer.sourceAccountId, accounts);
    const hasAccountSelected = !!account;

    const existingPayee =
        transfer.payeeId && hasAccountSelected
            ? matchPayee(transfer.payeeId, account as BulkTransferOptions['accounts'][0])
            : null;

    const payeeInfo: Partial<ParsedCsv> = {};
    if (existingPayee) {
        const country = countries.find(
            (count) =>
                count.countryISO3 === existingPayee.countryName ||
                count.name === existingPayee.countryName
        )?.countryISO3;

        payeeInfo.payeeType = existingPayee.type;
        payeeInfo.bankName = existingPayee.bankName;
        payeeInfo.countryCode = country;
        payeeInfo.accountName = existingPayee.accountName;
        payeeInfo.name = existingPayee.name;
        payeeInfo.payeesReference = existingPayee.payeesReference;
        payeeInfo.routingNumber = existingPayee.achRoutingNumber;
        payeeInfo.accountNumber = existingPayee.achAccountNumber;
        payeeInfo.swiftNumber = existingPayee.swiftNumber;
        payeeInfo.intermediaryBic = existingPayee.intermediaryBic;
        payeeInfo.iban = existingPayee.iban;
        payeeInfo.addressLine1 = existingPayee.addressLine1;
        payeeInfo.townCity = existingPayee.townCity;
        payeeInfo.state = existingPayee.state;
        payeeInfo.postCode = existingPayee.postCode;
        payeeInfo.addressCountryCode = existingPayee.addressCountryCode;

        if (bFinancialInstitution) {
            payeeInfo.transmitter = {
                firstPartyTransfer: existingPayee.transmitterFirstPartyTransfer,
                type: existingPayee.transmitterType,
                name: existingPayee.transmitterName,
                dateOfBirth: existingPayee.transmitterDateOfBirth,
                accountNumber: existingPayee.transmitterAccountNumber,
                addressLine1: existingPayee.transmitterAddressLine1,
                addressLine2: existingPayee.transmitterAddressLine2,
                townCity: existingPayee.transmitterTownCity,
                state: existingPayee.transmitterState,
                postcode: existingPayee.transmitterPostcode,
                countryCode: existingPayee.transmitterCountryCode,
            };
        }
    }

    return {
        ...transfer,
        purpose: '', // We don't display purpose in the list item so don't need to work it out
        productType: account?.productDisplayName,
        ...payeeInfo,
    };
};

export const hasAddressValue = (value: ParsedCsv) => {
    return (
        !!value.addressLine1 ||
        !!value.addressLine2 ||
        !!value.townCity ||
        !!value.state ||
        !!value.postCode ||
        !!value.addressCountryCode
    );
};

export const useNewPayeeTransferTypes = (
    customerAssetAccountsId: number | undefined,
    bankCountryCode: string | undefined,
    preventRequest: boolean
) => {
    // This will used cached results from fetchPayeeTransferTypes in parseCsvResults.ts
    const {
        data,
        error,
        isFetching: loading,
    } = useQuery([TRANSFER_OPTIONS_KEY, customerAssetAccountsId + '', bankCountryCode], {
        queryFn: () => fetchTransferOptions(customerAssetAccountsId + '', bankCountryCode ?? null),
        enabled: !preventRequest,
        staleTime: 1000 * 60,
        cacheTime: 1000 * 60,
        refetchOnWindowFocus: false,
    });

    return { data: data?.transferOptions ?? [], error: !loading && error };
};
export const useGetTransferPurposeCodes = (
    customerAssetAccountsId: number | undefined,
    bankCountryCode: string | undefined,
    preventRequest: boolean
) => {
    // This will used cached results from fetchTransferPurposeCodes in parseCsvResults.ts
    const {
        data,
        error,
        isFetching: loading,
    } = useQuery([PURPOSE_OPTIONS_KEY, customerAssetAccountsId + '', bankCountryCode], {
        queryFn: () => fetchPurposeOptions(customerAssetAccountsId + '', bankCountryCode ?? null),
        enabled: !preventRequest,
        staleTime: 1000 * 60,
        cacheTime: 1000 * 60,
        refetchOnWindowFocus: false,
    });

    const formattedCodes = (data?.purposeCodes ?? []).map((purpose) => ({
        label: purpose.description,
        value: purpose.code,
    }));

    return { data: formattedCodes, error: !loading && error };
};

export const SOURCE_ACCOUNT_LABEL = 'Source account';
export const FEE_LABEL = 'Fee';
export const ADDRESS_COUNTRY_LABEL = 'Country';
export const TRANSMITTER_COUNTRY_LABEL = 'Transmitter Country';
/* These are ordered so that when parsing if the current value requires another field in order to
 be passed then that field should have already be parsed and available */
export const csvCellConfig: CsvCellConfig[] = [
    // Account details
    {
        label: SOURCE_ACCOUNT_LABEL,
        fieldValue: 'sourceAccountId',
        parser: parseSourceAccount,
    },
    // Payee details existing id or name
    {
        label: 'Existing Payee',
        fieldValue: 'payeeId',
    },
    // Payee details new
    {
        label: 'New Payee Name',
        fieldValue: 'name',
    },
    {
        label: 'Payee Reference',
        fieldValue: 'payeesReference',
    },
    {
        label: 'Account Type',
        fieldValue: 'payeeType',
        parser: parseAccountType,
    },
    {
        label: 'Bank Name',
        fieldValue: 'bankName',
    },
    {
        label: 'Bank Country',
        fieldValue: 'countryCode',
        parser: parseCountry,
    },
    {
        label: 'Account Name',
        fieldValue: 'accountName',
    },
    {
        label: 'Routing Number',
        fieldValue: 'routingNumber',
    },
    {
        label: 'Swift Number',
        fieldValue: 'swiftNumber',
    },
    {
        label: 'Intermediary Swift',
        fieldValue: 'intermediaryBic',
    },
    {
        label: 'Account Number',
        fieldValue: 'accountNumber',
    },
    {
        label: 'IBAN',
        fieldValue: 'iban',
    },
    {
        label: 'Address Line 1',
        fieldValue: 'addressLine1',
    },
    {
        label: 'Address Line 2',
        fieldValue: 'addressLine2',
    },
    {
        label: 'Town / City',
        fieldValue: 'townCity',
    },
    {
        label: 'State',
        fieldValue: 'state',
    },
    {
        label: 'ZIP code',
        fieldValue: 'postCode',
    },
    {
        label: ADDRESS_COUNTRY_LABEL,
        fieldValue: 'addressCountryCode',
        parser: parseCountry,
    },
    // Payee transmitter details
    {
        label: 'First Party Transfer (Y/N)',
        fieldValue: 'transmitter.firstPartyTransfer',
        parser: parseYesNoField,
    },
    {
        label: 'Transmitter Type',
        fieldValue: 'transmitter.type',
        parser: parseTransmitterType,
    },
    {
        label: 'Transmitter Name',
        fieldValue: 'transmitter.name',
    },
    {
        label: 'Date Of Birth (dd/mm/yyyy)',
        fieldValue: 'transmitter.dateOfBirth',
        parser: parseDate,
    },
    {
        label: 'Transmitter Account Number',
        fieldValue: 'transmitter.accountNumber',
    },
    {
        label: 'Transmitter Address Line 1',
        fieldValue: 'transmitter.addressLine1',
    },
    {
        label: 'Transmitter Address Line 2',
        fieldValue: 'transmitter.addressLine2',
    },
    {
        label: 'Transmitter Town / City',
        fieldValue: 'transmitter.townCity',
    },
    {
        label: 'Transmitter State',
        fieldValue: 'transmitter.state',
    },
    {
        label: 'Transmitter ZIP code',
        fieldValue: 'transmitter.postcode',
    },
    {
        label: TRANSMITTER_COUNTRY_LABEL,
        fieldValue: 'transmitter.countryCode',
        parser: parseCountry,
    },

    // Payment details
    {
        label: 'Transfer Type',
        fieldValue: 'transferType',
        parser: parseTransferType,
    },
    {
        label: 'Payment Reference',
        fieldValue: 'paymentReference',
    },
    // Payment amount
    {
        label: 'Amount',
        fieldValue: 'amount',
        parser: parseAmount,
    },
    {
        label: 'Purpose',
        fieldValue: 'purpose',
    },
    {
        label: FEE_LABEL,
        fieldValue: 'feeId',
    },
    // Additional payment info
    {
        label: 'Memo',
        fieldValue: 'memo',
    },
    {
        label: 'Notes',
        fieldValue: 'notes',
    },
];
