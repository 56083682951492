/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';
import { useField, useFormikContext } from 'formik';
import api, { ApiResponse } from '../../../../../api';
import { endpoints } from '../../../../../endpoints.config';
import { SingleSelectWithSearch } from '../../../../../components/form/SingleSelectWithSearch/SingleSelectWithSearch';
import styles from './AddressLookup.module.scss';
import FormTextField from '../../../../../components/form/FormTextField';
import { WrappedFormSingleSelectField as FormSingleSelectField } from '../../../../../components/form/FormSingleSelectField';
import { CountryOptions } from '../MakePayment';

type LatLng = {
    lat: number;
    lon: number;
};

type AddressResponseOption = {
    type: string;
    id: string;
    score: number;
    address: {
        streetName: string;
        streetNameAndNumber: string | null;
        streetNumber: string | null;
        municipality: string;
        municipalitySubdivision: string | null;
        neighbourhood: string | null;
        countrySecondarySubdivision: string;
        countrySubdivision: string;
        countrySubdivisionName: string;
        postalCode: string;
        extendedPostalCode: string;
        countryCode: string;
        country: string;
        countryCodeISO3: string;
        freeformAddress: string;
        localName: string;
    };
    position: LatLng;
    viewport: {
        topLeftPoint: LatLng;
        btmRightPoint: LatLng;
    };
};

type AddressOptionResponse = {
    summary: {
        query: string;
        queryType: string;
        queryTime: number;
        numResults: number;
        offset: number;
        totalResults: number;
        fuzzyLevel: number;
    };
    results: AddressResponseOption[];
};

type FieldNamesMap = {
    addressLine1: string;
    addressLine2: string;
    townCity: string;
    state: string;
    postCode: string;
    addressCountryCode: string;
};
type Props = {
    disabled?: boolean;
    countryOptions: CountryOptions[];
    defaultShowLookup?: boolean;
    fieldnamePrefix?: string;
    fieldNamesMap?: FieldNamesMap;
};

function debounce(fn: (...args: any) => void, delay = 250) {
    let timeout: any;

    return (...args: any) => {
        clearTimeout(timeout);
        timeout = setTimeout(() => {
            fn(...args);
        }, delay);
    };
}

export const AddressLookup: React.FC<Props> = ({
    disabled,
    countryOptions,
    defaultShowLookup = true,
    fieldnamePrefix,
    fieldNamesMap = {
        addressLine1: 'addressLine1',
        addressLine2: 'addressLine2',
        townCity: 'townCity',
        state: 'state',
        postCode: 'postCode',
        addressCountryCode: 'addressCountryCode',
    },
}) => {
    const [manual, setManual] = useState(!defaultShowLookup);

    const buildFieldname = (name: string) =>
        fieldnamePrefix ? `${fieldnamePrefix}.${name}` : name;
    const [, { error, touched }] = useField<AddressResponseOption | null>(
        buildFieldname(fieldNamesMap.addressLine1)
    );
    const [{ value: selectedCountry }] = useField<AddressResponseOption | null>(
        buildFieldname(fieldNamesMap.addressCountryCode)
    );
    const { values, setValues, setFieldValue } = useFormikContext<{ [key: string]: any }>();

    const buildAddressObject = (address: AddressOptionResponse['results'][0]['address']): any => ({
        [fieldNamesMap.addressLine1]:
            address.streetNameAndNumber ??
            `${address.streetNumber ? `${address.streetNumber} ` : ''}${address.streetName}`, // we want streetNameAndNumber
        [fieldNamesMap.addressLine2]: address.municipalitySubdivision ?? '',
        [fieldNamesMap.townCity]: address.municipality,
        [fieldNamesMap.state]:
            address.countryCodeISO3 === 'GBR'
                ? address.countrySecondarySubdivision
                : address.countrySubdivision,
        [fieldNamesMap.postCode]: address.extendedPostalCode?.split(',')[0] ?? address.postalCode,
        [fieldNamesMap.addressCountryCode]: address.countryCodeISO3,
    });
    const buildAddressString = (address: AddressOptionResponse['results'][0]['address']) => {
        return address.freeformAddress;
    };

    const loadOptions = debounce(async (inputValue: string, callback: any) => {
        try {
            const res = await api.get<ApiResponse<AddressOptionResponse>>(
                endpoints.accounts.lookupAddress,
                {
                    params: { term: inputValue },
                }
            );

            const options = res.data.details.results;
            const newOpts = options.map((item) => ({
                label: buildAddressString(item.address),
                value: item,
            }));

            callback(newOpts);
        } catch (error) {
            callback([]);
        }
    }, 500);

    const handleAddressChosen = (opt: { value: AddressResponseOption; label: string }) => {
        const address = buildAddressObject(opt.value.address);

        const addressField = fieldnamePrefix ? { [fieldnamePrefix]: address } : address;

        setValues({
            ...values,
            ...addressField,
        });
        setManual(true);
    };
    const resetAddressLookup = () => {
        const emptyAddress = {
            [fieldNamesMap.addressLine1]: '',
            [fieldNamesMap.addressLine2]: '',
            [fieldNamesMap.townCity]: '',
            [fieldNamesMap.state]: '',
            [fieldNamesMap.postCode]: '',
            [fieldNamesMap.addressCountryCode]: '',
        };
        const addressField = fieldnamePrefix ? { [fieldnamePrefix]: emptyAddress } : emptyAddress;

        setValues({
            ...values,
            ...addressField,
        });
        setManual(false);
    };

    const NoOptionsComponent = (inputValue: string) => {
        return !inputValue ? (
            <p>Start typing to search for an address</p>
        ) : (
            <>
                <p>No results found</p>
                <button
                    className={`${styles.Toggle} ${styles.ToggleInline}`}
                    type="button"
                    onClick={() => setManual(true)}
                >
                    Enter your address manually
                </button>
            </>
        );
    };

    const availableStates = useMemo(() => {
        return (
            countryOptions
                ?.find((country) => country.value === selectedCountry)
                ?.states?.map((state) => ({
                    label: state.label,
                    value: state.value,
                })) ?? null
        );
    }, [countryOptions, selectedCountry, fieldNamesMap.addressCountryCode]);

    const handleCountrySelected = () => {
        setFieldValue(buildFieldname(fieldNamesMap.state), '');
    };

    if (!manual)
        return (
            <div className={styles.Lookup}>
                <SingleSelectWithSearch
                    loadOptions={loadOptions}
                    onChange={handleAddressChosen}
                    noOptionsComponent={NoOptionsComponent}
                    label="Address"
                    defaultOptions={[]}
                    dropdownProps={{
                        instanceId: 'lookup',
                        isDisabled: disabled,
                        placeholder: '10 Example Street...',
                    }}
                    error={!!touched && !!error ? error : undefined}
                />
                <button className={styles.Toggle} type="button" onClick={() => setManual(true)}>
                    Enter your address manually
                </button>
            </div>
        );

    return (
        <div className={styles.Manual}>
            <button className={styles.Toggle} type="button" onClick={resetAddressLookup}>
                Search your address
            </button>
            <FormTextField
                disabled={disabled}
                field={buildFieldname(fieldNamesMap.addressLine1)}
                label={'Address Line 1'}
            />
            <FormTextField
                disabled={disabled}
                field={buildFieldname(fieldNamesMap.addressLine2)}
                label={'Address Line 2'}
                labelExtraInfo="Optional"
            />
            <div className="AddressLayout">
                <FormTextField
                    disabled={disabled}
                    field={buildFieldname(fieldNamesMap.townCity)}
                    label={'Town / City'}
                />
                {availableStates ? (
                    <FormSingleSelectField
                        fieldName={buildFieldname(fieldNamesMap.state)}
                        options={availableStates}
                        label={'State'}
                        dropdownProps={{ isDisabled: disabled }}
                    />
                ) : (
                    <FormTextField
                        disabled={disabled}
                        field={buildFieldname(fieldNamesMap.state)}
                        label={'State'}
                    />
                )}
            </div>
            <div className="AddressLayout">
                <FormTextField
                    disabled={disabled}
                    field={buildFieldname(fieldNamesMap.postCode)}
                    label={'ZIP code'}
                />
                <FormSingleSelectField
                    fieldName={buildFieldname(fieldNamesMap.addressCountryCode)}
                    options={countryOptions}
                    label="Country"
                    forceTouchOnChange
                    handleOptionSelected={handleCountrySelected}
                    dropdownProps={{ isDisabled: disabled }}
                />
            </div>
        </div>
    );
};
