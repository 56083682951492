import React from "react";

export interface IPaginationInfo {
  resultsCount: number;
  firstOnPage: number;
  lastOnPage: number;
}

export const PaginationInfo: React.FC<IPaginationInfo> = ({
  resultsCount,
  firstOnPage,
  lastOnPage,
}) => {
  return (
    <div className="PaginatorInfo">
      Displaying items {resultsCount ? firstOnPage : 0}-{lastOnPage} of{" "}
      {resultsCount}
    </div>
  );
};
