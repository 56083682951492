import { Dispatch, SetStateAction, useEffect } from 'react';
import { useWindowDimensions } from '../../../helpers/useWindowDimensions';
import { useDispatch, useSelector } from 'react-redux';
import { closeModal, ModalTypes, openModal, selectModalState } from '../../../reducers/modal';
import { SavedPayee } from '../MoveMoney';

export const useHandleSidePanelInModal = (
    selectedRow: number | undefined,
    selectedData: SavedPayee | undefined,
    setSelectedRow: Dispatch<SetStateAction<number | undefined>>,
    disablePayment = false
) => {
    const { width } = useWindowDimensions();
    const dispatch = useDispatch();
    const { modalType } = useSelector(selectModalState);

    useEffect(() => {
        if (modalType === ModalTypes.PAYEE_DETAILS && width > 1400) {
            dispatch(closeModal());
            return;
        } else if (width >= 1400 || !selectedRow || !selectedData) return;
        else
            dispatch(
                openModal({
                    modalType: ModalTypes.PAYEE_DETAILS,
                    data: { rowData: selectedData!, disablePayment: disablePayment },
                })
            );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRow, width]);

    useEffect(() => {
        if (modalType) return;
        else setSelectedRow(undefined);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalType]);
};
