import React, { SetStateAction, useEffect } from 'react';
import { RightPanel } from 'components/categoryComponents/rightPanel/RightPanel';
import {
    RightMenuItems,
    RightMenuItem,
    AlternateRightMenuItems,
} from 'components/categoryComponents/rightMenu/RightMenu';
import { RightMenu } from 'components/categoryComponents/rightMenu/RightMenu';
import { TableInfo } from 'api';

type Action =
    | {
          type: 'change_open_menu';
          payload: {
              crudOptions: CrudOptions;
              selectedId: React.ReactText | null;
              fromRowOption: boolean;
          };
      }
    | { type: 'close_menus' };

type State = {
    currentMenu: CrudOptions;
    selectedId: React.ReactText | null;
    fromRowOption: boolean;
};

const initialState: State = {
    currentMenu: null,
    selectedId: null,
    fromRowOption: false,
};

type CallbackOrBoolean<Data> = boolean | ((selectedItems: Data[]) => boolean);

type CallbackOrString<Data> = string | ((selectedItems: Data[]) => string);

function reducer(state: State, action: Action): State {
    switch (action.type) {
        case 'change_open_menu':
            return {
                ...state,
                currentMenu: action.payload.crudOptions,
                selectedId: action.payload.selectedId,
                fromRowOption: action.payload.fromRowOption,
            };
        case 'close_menus':
            return {
                ...state,
                currentMenu: null,
                selectedId: null,
                fromRowOption: false,
            };
    }
}

type Menu = {
    currentMenu: CrudOptions;
    menuItems: RightMenuItems;
    selectedId: React.ReactText | null;
    selectedData: any;
    fromRowOption: boolean;
};

export type MenuState = [
    Menu,
    {
        openMenu(menu: CrudOptions, id: React.ReactText | null, fromRowOption?: boolean): void;
        closeMenus(): void;
    }
];

export type MenuItem<Options = CrudOptions> = {
    icon: string;
    name: string;
    imgAlt: string;
    menuCode: Options;
    showOnMultiSelect: boolean;
    disabled?: boolean;
};

export type CrudItems<Data, Options = CrudOptions> = {
    icon: CallbackOrString<Data>;
    imgAltText: CallbackOrString<Data>;
    menuCode: Options;
    showInMultiSelect: CallbackOrBoolean<Data>;
    permissionsKey?: string;
    title: CallbackOrString<Data>;
    content(onDone: () => void, selectedData: Data[], menu: Menu): React.ReactNode;
    inRightPanel: CallbackOrBoolean<Data>;
    isDisabled: CallbackOrBoolean<Data>;
    notInMenus?: CallbackOrBoolean<Data>;
}[];
/**
 * It might be a function, it might just be a boolean.
 */
export function applyMaybeBoolean<Data>(
    input: boolean | ((selectedItems: Data[]) => boolean),
    selectedData: Data[]
): boolean {
    if (typeof input === 'boolean') return input;

    return input(selectedData);
}
/**
 * It might be a function, it might just be a string.
 */
function applyMaybeString<Data>(
    input: string | ((selectedItems: Data[]) => string),
    selectedData: Data[]
): string {
    if (typeof input === 'string') return input;

    return input(selectedData);
}

interface UseMenuStateParams<T> {
    menuItems: CrudItems<T>;
    selectedData: T[];
    permissions: Map<string, boolean>;
    table?: TableInfo;
    idColumn?: string;
}

export function useMenuState<Data>(props: UseMenuStateParams<Data>) {
    const { menuItems, selectedData: rawSelectedData, permissions, table, idColumn } = props;
    const [store, dispatch] = React.useReducer(reducer, initialState);
    const { currentMenu, selectedId, fromRowOption } = store;

    const getSelectedData = (table: TableInfo): any => {
        if (selectedId && idColumn) {
            const found = table.data?.details.listData.find(
                (data: any) => data[idColumn] === selectedId
            );
            return found ? [found] : [];
        }

        return [];
    };

    const selectedData = fromRowOption && table ? getSelectedData(table) : rawSelectedData;

    const openMenu = React.useCallback(
        (menu: CrudOptions, selectedId: React.ReactText | null, fromRowOption?: boolean) =>
            dispatch({
                type: 'change_open_menu',
                payload: {
                    crudOptions: menu,
                    selectedId: selectedId,
                    fromRowOption: fromRowOption ?? false,
                },
            }),
        [dispatch]
    );

    const closeMenus = React.useCallback(() => dispatch({ type: 'close_menus' }), [dispatch]);

    const generateMenuItems = (menuItems: CrudItems<Data>): RightMenuItems => {
        const singleItems: RightMenuItem[] = menuItems.map((item) => {
            const hasPermission =
                item.permissionsKey == null ? true : !!permissions.get(item.permissionsKey);

            const isDisabled = applyMaybeBoolean(item.isDisabled, selectedData) || !hasPermission;
            return {
                icon: applyMaybeString(item.icon, selectedData),
                name: applyMaybeString(item.title, selectedData),
                imgAlt: applyMaybeString(item.imgAltText, selectedData),
                disabled: isDisabled,
                onClick: isDisabled ? () => {} : () => openMenu(item.menuCode, null),
                notInMenus: applyMaybeBoolean(item.notInMenus ?? false, selectedData),
            };
        });

        const multiItems: AlternateRightMenuItems = {
            menuItems: menuItems
                .filter((x) => applyMaybeBoolean(x.showInMultiSelect, selectedData))
                .map((item) => {
                    const hasPermission =
                        item.permissionsKey == null ? true : !!permissions.get(item.permissionsKey);
                    const isDisabled =
                        applyMaybeBoolean(item.isDisabled, selectedData) || !hasPermission;
                    return {
                        icon: applyMaybeString(item.icon, selectedData),
                        name: applyMaybeString(item.title, selectedData),
                        imgAlt: applyMaybeString(item.imgAltText, selectedData),
                        onClick: isDisabled ? () => {} : () => openMenu(item.menuCode, null),
                        disabled: isDisabled,
                    };
                }),
        };

        return { menuItems: singleItems, alternateMenuItems: multiItems };
    };

    const finalMenuItems: RightMenuItems = generateMenuItems(menuItems);

    const output = React.useMemo(() => {
        const data = {
            currentMenu,
            menuItems: finalMenuItems,
            selectedId,
            selectedData,
            fromRowOption,
        };
        const actions = { openMenu, closeMenus };
        return [data, actions] as MenuState;
    }, [openMenu, closeMenus, currentMenu, finalMenuItems, selectedId, fromRowOption]);

    return output;
}

export type CrudOptions =
    | 'CREATE'
    | 'ADD'
    | 'VIEW'
    | 'UPDATE'
    | 'DELETE'
    | 'FILTERS'
    | 'PORTAL_CONFIGURATION'
    | 'DATE_SETTINGS'
    | 'INTEGRATIONS'
    | 'IMPORT_CLIENT_USERS'
    | 'RESEND_INVITATION_EMAILS'
    | 'VIEW_LIST'
    | 'TRANSFER'
    | 'TRANSFER_BATCH'
    | 'TRANSACTIONS'
    | 'CREATE_PERSONAL'
    | 'CREATE_BUSINESS'
    | null;

function CrudMenu<Data>(props: {
    menu: Menu;
    closePanel(): void;
    onDone(): void;
    noun?: string;
    selectedData: Data[];
    crudItems: CrudItems<Data>;
    menuItems: RightMenuItems;
    filters: React.ReactNode;
    setTransState?: React.Dispatch<SetStateAction<boolean | null>>;
    setSubCatShown?: React.Dispatch<React.SetStateAction<boolean>>;
}) {
    const { closePanel, onDone, menu, crudItems, filters, selectedData, setSubCatShown } = props;

    const { menuItems, currentMenu } = menu;

    const currentItem = !currentMenu
        ? null
        : crudItems.find((item) => item.menuCode?.toLowerCase() === currentMenu?.toLowerCase());

    const title = () => {
        if (!currentMenu) {
            return '';
        } else if (currentMenu === 'FILTERS') {
            return 'Filters';
        } else if (!currentItem) {
            return '';
        }
        return applyMaybeString(currentItem.title, selectedData);
    };

    const content = () => {
        if (currentMenu === 'FILTERS') return filters;
        else if (!currentMenu || !currentItem) {
            return null;
        }
        if (crudItems) return currentItem.content(onDone, selectedData, menu);
    };

    let rightPanelOpen =
        crudItems && currentMenu
            ? applyMaybeBoolean(currentItem?.inRightPanel ?? true, selectedData)
            : false;

    useEffect(() => {
        if (currentMenu === 'VIEW_LIST') {
            setSubCatShown && setSubCatShown(true);
        }
    }, [currentMenu, setSubCatShown]);

    return (
        <React.Fragment>
            <RightPanel title={title()} rightPanelContent={rightPanelOpen} closePanel={closePanel}>
                {content()}
            </RightPanel>
            {!rightPanelOpen && content()}
            <RightMenu
                toggleAltMenuItems={selectedData.length > 1}
                visible={!menu.fromRowOption && selectedData.length > 0}
                menuItems={menuItems.menuItems}
                alternateMenuItems={menuItems.alternateMenuItems}
                setTransState={props.setTransState}
            />
        </React.Fragment>
    );
}

export default CrudMenu;
