import { useTheme } from '@emotion/react';
import { CurrencyIcon } from 'components/currencyIcon/CurrencyIcon';
import { ProgressBar } from 'components/progressBar/ProgressBar';
import format from 'date-fns/format';
import { calculatePercentageBetween } from 'helpers/calculatePercentage';
import { formatPriceWithCommas, formatPriceWithLocale } from 'helpers/formatPriceWithCommas';
import { localizedFormatDate } from 'helpers/language';
import React from 'react';
import { useSelector } from 'react-redux';
import { selectCultureCode } from 'reducers/language';
import { InitialsIcon } from 'components/initialsIcon/InitialsIcon';
import { AssetCodeFormatter } from 'components/AssetCodeFormatter';

type NameCellProps = {
    name: string;
    ticker: string;
};

export const NameCell: React.FC<NameCellProps> = ({ name, ticker }) => {
    return (
        <div className="MarketNameCell">
            <div className="MarketImageContainer">
                <CurrencyIcon currency={ticker} />
            </div>
            <div className="MarketText">
                <span className="MarketName">{name}</span>
                <span className="MarketTicker">
                    <AssetCodeFormatter assetCode={ticker} />
                </span>
            </div>
        </div>
    );
};

type CryptoReferenceCellProps = {
    name: string;
    ticker: string;
    reference: string;
};

export const CryptoReferenceCell: React.FC<CryptoReferenceCellProps> = ({
    name,
    ticker,
    reference,
}) => {
    return (
        <div className="MarketReferenceCell">
            <div className="RowImage">
                <CurrencyIcon currency={ticker} />
            </div>
            <div>
                <div className="Names">
                    <h3 className="Name">{name}</h3>
                    <p className="Ticker">
                        <AssetCodeFormatter assetCode={ticker} />
                    </p>
                </div>
                <span className="Reference">{reference}</span>
            </div>
        </div>
    );
};

type ExchangeReferenceCellProps = {
    reference: string;
    ticker: string;
};

export const ExchangeReferenceCell: React.FC<ExchangeReferenceCellProps> = ({
    reference,
    ticker,
}) => (
    <div className="MarketReferenceCell Exchange">
        <div className="RowImage">
            <CurrencyIcon currency={ticker} />
        </div>
        <div>
            <span className="Reference">{reference}</span>
        </div>
    </div>
);

type PriceCellProps = {
    price: number;
    currencySymbol: string;
    currencyTicker: string;
};

export const PriceCell: React.FC<PriceCellProps> = ({ currencySymbol, currencyTicker, price }) => {
    const decimals = 6 - Math.floor(price).toString().length; //number of decimals needed for 6 significant figures
    const cultureCode = useSelector(selectCultureCode);
    return (
        <div className="MarketPriceCell">
            <span className="Price">
                {currencySymbol}
                {formatPriceWithLocale(price, cultureCode ?? 'en-GB', decimals > 2 ? decimals : 2)}
            </span>
            <span className="Ticker">{currencyTicker}</span>
        </div>
    );
};

type ValueCellProps = {
    price: number;
    currencySymbol: string;
    currencyTicker: string;
    balance: number;
};

export const ValueCell: React.FC<ValueCellProps> = ({
    currencySymbol,
    price,
    balance,
    currencyTicker,
}) => {
    return (
        <div className="MarketPriceCell">
            <span className="Price">
                {currencySymbol}
                {formatPriceWithCommas(price * balance, 6)}
            </span>
            <span className="Ticker">{currencyTicker}</span>
        </div>
    );
};

type VariabilityCellProps = {
    amount: number;
};

export const VariabilityCell: React.FC<VariabilityCellProps> = ({ amount }) => {
    return (
        <span className={`MarketChangeCell `}>{formatPriceWithCommas(Math.abs(amount), 3)}%</span>
    );
};

type ChangeCellProps = {
    amount: number;
};

export const ChangeCell: React.FC<ChangeCellProps> = ({ amount }) => {
    const changeDirection: 'positive' | 'negative' | 'none' =
        amount > 0 ? 'positive' : amount === 0 ? 'none' : 'negative';
    const symbol = changeDirection === 'positive' ? '+' : changeDirection === 'negative' ? '-' : '';
    return (
        <span className={`MarketChangeCell ${changeDirection}`}>
            {symbol}
            {formatPriceWithCommas(Math.abs(amount), 3)}%
        </span>
    );
};

type HighLowCellProps = {
    high: number;
    low: number;
    price: number;
    currencySymbol: string;
};

export const HighLowCell: React.FC<HighLowCellProps> = ({ high, low, price, currencySymbol }) => {
    return (
        <ProgressBar
            percentageComplete={calculatePercentageBetween(low, high, price)}
            start={`${currencySymbol ? currencySymbol : ''}${formatPriceWithCommas(low, 6)}`}
            end={`${currencySymbol ? currencySymbol : ''}${formatPriceWithCommas(high, 6)}`}
        />
    );
};

type DateCellProps = {
    date: string;
    formatString?: string;
};

export const DateCell: React.FC<DateCellProps> = ({ date, formatString }) => {
    const cultureCode = useSelector(selectCultureCode);
    return (
        <div className="DateCell">
            <span className="Date">
                {formatString
                    ? format(new Date(date), formatString)
                    : localizedFormatDate(new Date(date), cultureCode || undefined)}
            </span>
        </div>
    );
};

type BuySellCellProps = {
    type: 'Buy' | 'Sell' | 'Deposit' | 'Withdraw';
    status?: string;
};

export const BuySellCell: React.FC<BuySellCellProps> = ({ type, status }) => {
    const theme = useTheme();
    return (
        <div className="BuySellCell">
            {type === 'Buy' || type === 'Sell' ? (
                <span
                    className={`BuyOrSell ${type}`}
                    style={{ color: type === 'Buy' ? theme.colors.first : theme.colors.second }}
                >
                    {`${type}${status ? ` (${status})` : ''}`}
                </span>
            ) : (
                <span>{`${type}${status ? ` (${status})` : ''}`}</span>
            )}
        </div>
    );
};

type NumberAndTickerCellProps = {
    number: number;
    ticker: string;
    plainColor?: boolean;
    cultureCode?: string;
};

export const NumberAndTickerCell: React.FC<NumberAndTickerCellProps> = ({
    number,
    ticker,
    plainColor = false,
    cultureCode = 'en-GB',
}) => {
    const isMinus = number < 0;

    const formattedNumber = formatPriceWithLocale(number, cultureCode, 2, 12);

    return (
        <div
            className={`NumberTickerCell ${isMinus ? 'Minus' : ''} ${
                plainColor ? 'PlainColour' : ''
            }`}
        >
            <span className="Number">{formattedNumber}</span>
            <span className="Ticker">
                <AssetCodeFormatter assetCode={ticker} />
            </span>
        </div>
    );
};

type StringCellProps = {
    value: string | number;
};

export const StringCell: React.FC<StringCellProps> = ({ value }) => (
    <span className="StringCell">{value}</span>
);

type PrecisionNumberCellProps = {
    number: number;
    precision?: number;
};

export const PrecisionNumberCell: React.FC<PrecisionNumberCellProps> = ({
    number,
    precision = 6,
}) => {
    return <div>{number.toPrecision(precision)}</div>;
};

export const EmptyCell: React.FC = () => {
    return <div className="EmptyCell" />;
};

type NameInitialsCellProps = {
    name: string;
    subName?: string;
    additionalText?: string;
};

export const NameInitialsCell: React.FC<NameInitialsCellProps> = ({
    name,
    subName,
    additionalText,
}) => {
    return (
        <div className="NameInitialsCell">
            <div className="Left">
                <InitialsIcon name={name} />
            </div>
            <div className="Right">
                <span className="Name">{name}</span>
                <span className="SubName">{subName}</span>
                {additionalText && <span className="SubName">{additionalText}</span>}
            </div>
        </div>
    );
};
