import instance, { ApiResponse } from 'api';
import { endpoints } from 'endpoints.config';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select, { ValueType } from 'react-select';
import { selectAccountSwitchInProgress, switchAccountType } from 'reducers/auth';
import { selectComponentResources } from 'reducers/componentResources';

type NumericSelectOption = { value: number; label: string };

type AccountData = {
    accountType: string;
    companyName: string | null;
    customerId: number;
    customerUsersCustomersId: number;
    fullName: string;
};

export const SwitchAccountDropdown = () => {
    //placeholder, this will probably be replaced by some redux logic
    const [accountList, setAccountList] = useState<AccountData[] | null>(null);
    const availableAccounts: NumericSelectOption[] =
        accountList?.map((account) => ({
            value: account.customerUsersCustomersId,
            label: `${account.companyName ?? account.fullName} - ${account.accountType}`,
        })) ?? [];
    const [currentlySelectedAccount, setCurrentlySelectedAccount] = useState(NaN);
    const { userInfo } = useSelector(selectComponentResources);
    const accountSwitchInProgress = useSelector(selectAccountSwitchInProgress);

    const dispatch = useDispatch();

    useEffect(() => {
        instance
            .get<ApiResponse<AccountData[]>>(endpoints.profilemodule.linkedAccountsList, {})
            .then((res) => {
                setAccountList(res.data.details);
            });
    }, []);
    useEffect(() => {
        if (!accountList || !userInfo?.customerUsersCustomersId) return;
        setCurrentlySelectedAccount(
            accountList.find(
                (account) => account.customerUsersCustomersId === userInfo.customerUsersCustomersId
            )?.customerUsersCustomersId ?? NaN
        );
    }, [accountList, userInfo]);

    const handleAccountSwitch = (e: ValueType<NumericSelectOption, false>) => {
        if (!e?.value) return;
        dispatch(
            switchAccountType({
                targetCustomerUsersCustomersId: e.value,
            })
        );
    };

    return (
        <Select
            options={availableAccounts}
            className="SwitchAccountDropdown"
            classNamePrefix={'SwitchAccount'}
            onChange={handleAccountSwitch}
            value={availableAccounts.find((option) => option.value === currentlySelectedAccount)}
        />
    );
};
