import { IPreviousButtons, INextButtons } from "./PaginationButtons";
import { IPaginationConfig } from "./PaginationConfig";
import { IPaginationInfo } from "./PaginationInfo";

export interface IPagination {
  pageNumber: number;
  pageSize: number;
}

type PaginationProps = IPaginationConfig &
  IPreviousButtons &
  INextButtons &
  IPaginationInfo;

type PaginationComponentProps = {
  previousButtonsProps: IPreviousButtons;
  nextButtonsProps: INextButtons;
  paginationConfigProps: IPaginationConfig;
  paginationInfoProps: IPaginationInfo;
};

export const buildPaginationProps: (
  props: PaginationProps
) => PaginationComponentProps = ({
  pageNumber,
  pageSize,
  lastPage,
  firstOnPage,
  lastOnPage,
  resultsCount,
  hasPrevPage,
  hasNextPage,
  changePageSize,
  goToPage,
  clearSelections,
  goToPrevPage,
  goToNextPage,
}) => {
  const paginationConfigProps: IPaginationConfig = {
    pageNumber,
    pageSize,
    lastPage,
    resultsCount,
    changePageSize,
    goToPage,
  };

  const previousButtonsProps: IPreviousButtons = {
    clearSelections,
    goToPrevPage,
    goToPage,
    pageNumber,
    hasPrevPage,
  };

  const nextButtonsProps: INextButtons = {
    clearSelections,
    goToNextPage,
    goToPage,
    lastPage,
    pageNumber,
    resultsCount,
    hasNextPage,
  };

  const paginationInfoProps: IPaginationInfo = {
    resultsCount,
    firstOnPage,
    lastOnPage,
  };

  return {
    paginationConfigProps,
    previousButtonsProps,
    nextButtonsProps,
    paginationInfoProps,
  };
};
