import { useField, useFormikContext } from 'formik';
import { BulkTransferOptions, ParsedCsv } from './models';
import { Modal } from '../../../components/modal/Modal';
import Button from '../../../components/button/Button';
import { useEffect, useMemo, useState } from 'react';
import {
    matchPayee,
    hasAddressValue,
    useNewPayeeTransferTypes,
    useGetTransferPurposeCodes,
    matchCountry,
} from './helpers';
import { WrappedFormSingleSelectField as FormSingleSelectField } from '../../../components/form/FormSingleSelectField';
import { PayeeForm } from '../Payees/EditPayee';
import { RadioButtons } from '../../../components/radiobuttons/radiobuttons';
import FormTextField from '../../../components/form/FormTextField';
import { RadioIdButtons } from '../../../components/radiobuttons/RadioIdButtons';
import { createFeeOptions, OtherPurpose } from '../Payees/MakePayment/AmountPurpose';
import { useTouchErrorFormikFields } from '../../../helpers/useTouchAllFormikFields';
import { useIsFinancialInstitution } from '../Payees/helpers';
import { paymentRefTipForFI, paymentRefTipForNonFi } from '../Payees/MakePayment/PaymentDetails';
import { ProductType } from '../../../components/sideMenu/SideMenu';

type Props = {
    accountId?: number;
    fieldname: string;
    accounts: BulkTransferOptions['accounts'];
    countries: BulkTransferOptions['countries'];
    handleCloseModal(): void;
};

export const BulkTransferEditModal: React.FC<Props> = ({
    accountId,
    fieldname,
    accounts,
    countries,
    handleCloseModal,
}) => {
    const bFinancialInstitution = useIsFinancialInstitution();

    const { setFieldValue } = useFormikContext();
    const [{ value }, { error }, { setValue }] = useField<ParsedCsv>(fieldname);

    useTouchErrorFormikFields(false, [error]);

    // Store initial state in case they want to close the form rather than save
    const [initialValues] = useState(value);

    const handleResetState = () => {
        setValue(initialValues, !value.status);
        handleCloseModal();
    };

    const account = (accounts ?? []).find(
        (acc) => acc.id.toString() === value.sourceAccountId?.toString()
    );
    const hasAccountSelected = !!account;

    const selectedExistingpayee =
        value.payeeId && hasAccountSelected
            ? matchPayee(value.payeeId, account as BulkTransferOptions['accounts'][0])
            : null;
    const hasExistingPayee = !!selectedExistingpayee;

    const payees = (account?.payees ?? []).map((payee) => ({
        label: payee.name,
        value: payee.id,
    }));
    const payeeOptions = [{ label: 'New payee', value: null }, ...payees];

    const countryMappedOptions = useMemo(
        () =>
            countries.map((country) => ({
                label: country.name,
                value: country.countryISO3,
                states: country.states
                    ? country.states.map((s) => ({ label: s.name, value: s.isoCode }))
                    : null,
            })),
        [countries]
    );
    const fn = (str: string) => `${fieldname}.${str}`;

    const bInternational = value.countryCode !== 'USA';
    const isDBS =
        account?.productDisplayName === ProductType.DBS ||
        account?.productDisplayName === ProductType.DBS_TMP;
    const isBankingCircle = account?.productDisplayName === ProductType.BC;
    const isCRB = account?.productDisplayName === ProductType.CRB;
    const isGLDB = account?.productDisplayName === ProductType.GLDB;

    useEffect(() => {
        !hasExistingPayee && setFieldValue(fn('payeeId'), null, !value.status);
        account?.productDisplayName !== value.productType &&
            setFieldValue(fn('productType'), account?.productDisplayName, false);
        setFieldValue(fn('currencyCode'), account?.currencyCode);

        if (isDBS || isBankingCircle) {
            setFieldValue(fn('routingNumber'), '');
            setFieldValue(fn('iban'), '');
        } else if (isGLDB) {
            setFieldValue(fn('routingNumber'), '');
            setFieldValue(fn('accountNumber'), '');
        } else if (isCRB && !bInternational) {
            setFieldValue(fn('swiftNumber'), '');
            setFieldValue(fn('intermediaryBic'), '');
            setFieldValue(fn('iban'), '');
        } else if (isCRB && bInternational) {
            setFieldValue(fn('routingNumber'), '');
            setFieldValue(fn('intermediaryBic'), '');
            setFieldValue(fn('accountNumber'), '');
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value.sourceAccountId]);

    const { data: transferTypes } = useNewPayeeTransferTypes(
        account?.id,
        value.countryCode,
        !!selectedExistingpayee
    );

    const country =
        value.countryCode ||
        matchCountry(selectedExistingpayee?.countryName, countries)?.countryISO3;
    const { data: purposes } = useGetTransferPurposeCodes(account?.id, country, false);
    const otherPurposeCode = purposes.find(
        (purpose) => purpose.label.toLowerCase() === 'other'
    )?.value;

    const transferTypeOptions = selectedExistingpayee
        ? selectedExistingpayee.transferTypes
        : transferTypes ?? [];
    const transferTypeRadioOptions = transferTypeOptions.map((opt) => opt.transferType);

    const selectedTransferType = transferTypeOptions.find(
        (opt) => opt.transferType === value.transferType
    );
    const feeOptions = createFeeOptions(
        selectedTransferType?.feeOptions ?? [],
        account?.accountName
    );

    const accountOptions = (accounts ?? []).map((acc) => ({
        label: `${acc.accountName} - ${acc.currencySymbol}${acc.balance}`,
        value: acc.id,
    }));

    return (
        <Modal
            title="Edit transfer"
            className="EditTransferModal"
            closeOnClickOutside={false}
            onCloseModal={handleResetState}
            customButtons={
                <div className="ModalNavigation">
                    <Button onClick={handleResetState} priority="secondary" type="button">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleCloseModal}
                        priority="primary"
                        color="third"
                        type="button"
                    >
                        Update transfer
                    </Button>
                </div>
            }
        >
            {!accountId && (
                <>
                    <h2>Account</h2>
                    <FormSingleSelectField
                        fieldName={fn('sourceAccountId')}
                        options={accountOptions}
                        label="Source account"
                    />
                </>
            )}

            <h2>Recipient</h2>
            <FormSingleSelectField label="Payee" fieldName={fn('payeeId')} options={payeeOptions} />
            {!hasExistingPayee && (
                <PayeeForm
                    fieldnamePrefix={fieldname}
                    availableCountries={countryMappedOptions}
                    defaultShowLookup={!hasAddressValue(value)}
                    productType={value.productType}
                />
            )}

            <h2>Payment Details</h2>
            {transferTypeRadioOptions && transferTypeRadioOptions.length > 0 && (
                <RadioButtons
                    options={transferTypeRadioOptions}
                    fieldname={fn('transferType')}
                    label="Payment Method"
                    showWithOnlyOneOption
                    setToNullWhenOptionDoesNotExist
                />
            )}
            <FormTextField
                field={fn('paymentReference')}
                label="Payment Reference"
                maxLength={!bFinancialInstitution ? 16 : 32}
                tooltip={!bFinancialInstitution ? paymentRefTipForNonFi : paymentRefTipForFI}
            />
            <h2>Amount & Purpose</h2>
            <RadioIdButtons
                className="FeeOptions"
                options={feeOptions}
                fieldname={fn('feeId')}
                label="Fee"
            />
            <div className="AddressLayout">
                <FormTextField
                    field={fn('amount')}
                    label="Amount"
                    type="number"
                    inputProps={{ pattern: '[0-9]*', min: 0 }}
                    inputMode="numeric"
                    onKeyDown={(evt) =>
                        ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault()
                    }
                />

                {purposes.length > 0 ? (
                    <FormSingleSelectField
                        fieldName={fn('purpose')}
                        options={purposes ?? []}
                        label={`${value.transferType ?? ''} Payment Purpose `}
                        tooltip={`Please ${
                            bInternational ? 'select' : 'provide'
                        } an option that best describes the reason for your transfer`}
                        dropdownProps={{
                            placeholder: 'Please select',
                        }}
                    />
                ) : (
                    <FormTextField
                        field={fn('purpose')}
                        label={`${value.transferType ?? ''} Payment Purpose `}
                        tooltip={`Please ${
                            bInternational ? 'select' : 'provide'
                        } an option that best describes the reason for your transfer`}
                        placeholder="E.g. Utility Bill"
                    />
                )}
            </div>
            <OtherPurpose
                purposeFieldname={fn('purpose')}
                otherPurposeCode={otherPurposeCode}
                otherPurposeFieldname={fn('purposeOther')}
            />
            <h2>More Details</h2>
            <p className="mb-4">More information related to this payment</p>
            {!bFinancialInstitution && (
                <FormTextField
                    label="Memo"
                    field={fn('memo')}
                    type="textarea"
                    placeholder="Type your text here"
                    maxLength={140}
                    inputProps={{ rows: undefined }}
                    labelExtraInfo="For further credit, for benefit or message to recipient"
                    hint="Maximum memo length is 140 characters"
                />
            )}
            <FormTextField
                label="Notes"
                field={fn('notes')}
                type="textarea"
                placeholder="Type your text here"
                maxLength={1000}
                inputProps={{ rows: undefined }}
                hint="Maximum text length is 1000 characters"
            />
        </Modal>
    );
};
