import React from "react";
import { FieldAttributes, useField } from "formik";

function RememberMeInput<T>(props: FieldAttributes<T>) {
    const [field] = useField(props);
    const { className, ...rest } = props;

    return <input {...(field as any)} {...rest} className={className} />;
  }

  export default RememberMeInput