import { useFormikContext } from 'formik';
import Button from '../../../components/button/Button';
import { TFAField } from '../../../components/form/TFAField';
import { Modal } from '../../../components/modal/Modal';
import { useTFAField } from '../../../helpers/useTFAField';
import { BulkTransferFormValues } from './BulkTransfer';

type Props = {
    open: boolean;
    handleClose: () => void;
};

export const BulkTransferMfaModal: React.FC<Props> = ({ open, handleClose }) => {
    const [tfaType, toggleTfaType] = useTFAField();
    const { values, setFieldValue } = useFormikContext<BulkTransferFormValues>();

    const createModalButtons = () => (
        <div className="ModalNavigation">
            <Button onClick={handleClose} priority="secondary">
                Close
            </Button>
            <Button type="submit" priority="primary" color="third">
                Submit Transfers
            </Button>
        </div>
    );

    if (!open) return null;

    return (
        <Modal
            className="MakePaymentMfaModal"
            title="Bulk Transfer"
            customButtons={createModalButtons()}
            onCloseModal={handleClose}
            closeOnClickOutside={false}
        >
            <TFAField
                field="tfaCode"
                label="TFA Code"
                toggleTFAType={
                    toggleTfaType
                        ? () => {
                              toggleTfaType();
                              setFieldValue(
                                  'tfaType',
                                  values.tfaType === 'SMS' ? 'AuthenticatorApp' : 'SMS'
                              );
                          }
                        : null
                }
                tfaType={tfaType}
                holdFocus
                autoFocus
            />
        </Modal>
    );
};
