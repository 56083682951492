import { useLocation } from '@reach/router';
import { useEffect, useState } from 'react';

export const useOnLocationChange = (callback: () => void) => {
    const { pathname } = useLocation();
    const [savedPathname, setSavedPathname] = useState<string | null>(null);

    useEffect(() => {
        if (pathname !== savedPathname) {
            setSavedPathname(pathname);
            callback();
        }
    }, [pathname, callback, savedPathname]);
};
